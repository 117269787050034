import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import FeedBackByCustomerId from "../../pages/dashboard/FeedBacks/feedBackByCustomerId";
import CustomerDetails from "../../pages/dashboard/customers/[customerId]";

const RoleCustomerDetailsdRedirect = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (user.id_service === 0 && user.access_level === 0) {
    return <CustomerDetails />;
  } else {
    return <FeedBackByCustomerId />;
  }
};

export default RoleCustomerDetailsdRedirect;
