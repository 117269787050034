import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../hooks/use-auth';
import { conversationApi } from '../../../api/conversation-api';
import { useMounted } from '../../../hooks/use-mounted';

function NewConversation(props) {
    const { newConversationByAdmin, setNewConversationByAdmin, auditId, setAuditId } = props
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [learners, setLearners] = useState([])
    const { user } = useAuth()
    const isMounted = useMounted();

    const getLearnerOptions = async () => {
        try {
            const learnersData = await conversationApi.getLearnerOptions(user.admin_id, auditId)
            if (isMounted()) {
                setLearners(learnersData)
                setIsLoading(false)            
            } 
        } catch(err) {
            console.error(err);
        }
      }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSelect = (selectedOption) => {
        // Faites quelque chose avec l'option sélectionnée
        setNewConversationByAdmin(selectedOption)
        handleClose()
    };

    useEffect(() => {
        if (open) {
            setIsLoading(true)
            getLearnerOptions()
        } else {
            setLearners([])
        }
    }, [auditId, open])

    return (
        <>
            <Button 
                variant="contained" 
                sx={{ ml: 1 }}
                onClick={handleOpen}
            >
                Nouveau message
            </Button>
            <Dialog 
                onClose={handleClose} 
                open={open}
                maxWidth="md"
                fullWidth={true}
            >
                {
                    !auditId ? 
                    (
                        <>
                            <DialogTitle sx={{ textAlign: 'center' }}>Pas d'apprenant disponible</DialogTitle>
                            <DialogContent sx={{ textAlign: 'center' }}>
                                <Typography>Vous devez être affecté à un audit.</Typography>
                            </DialogContent>
                        </>
                    ) : (
                        <>
                            <DialogTitle sx={{ textAlign: 'center' }}>Choisir un apprenant</DialogTitle>
                            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mb: 5 }}>
                                <Select
                                    sx={{ width: 500 }}
                                    value={auditId}
                                    // name={audit.name}
                                    onChange={(e) => {
                                        const newAuditId = e.target.value
                                        setAuditId(newAuditId)
                                    }}
                                    inputProps={{ 'aria-label': 'Audit' }}
                                >
                                    {user.epps.map((epp) => (
                                    <MenuItem value={epp.id}>{epp.name}</MenuItem>
                                    ))}
                                </Select>
                                {isLoading ? 
                                <CircularProgress sx={{ mt: 4 }} size={48} /> :
                                <Autocomplete
                                    options={learners}
                                    getOptionLabel={(learner) => learner.fullname}
                                    getOptionId={(learner) => learner.id}
                                    sx={{ width: 500, mt: 3 }}
                                    renderInput={(params) => 
                                        <TextField 
                                            {...params}
                                            label="Nom"
                                            variant="outlined" 
                                        />
                                    }
                                    onChange={(event, selectedOption) => handleSelect(selectedOption)}
                                />
                                }
                            </DialogContent> 
                        </>
                )
            }
            </Dialog>
        </>
    )
}

export default NewConversation