import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../hooks/use-auth';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { wait } from '../../../utils/wait';
import { userApi } from '../../../api/user-api';
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';

export const AdminCreateForm = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      avatar: '',
      admin_type: '',
      submit: null,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().max(255).required(),
      lastname: Yup.string().max(255).required(),
      email: Yup.string().email('Must be a valid email').max(255).required(),
      avatar: Yup.string().max(255),
      password: Yup.string().max(255).required(),
      admin_type: Yup.string().max(255).required()
    }),
    onSubmit: async (values, helpers) => {
      try {
        if(values.admin_type === "Formateur"){
            const id = await userApi.createAdmin(user.admin_id, values, 2, 1)
            await wait(500)
            if (!id.id) {
                toast.error(id.msg)
                return
            }  
        }
        if(values.admin_type === "Administrateur"){
            const id = await userApi.createAdmin(user.admin_id, values, 0, 0)
            await wait(500)
            if (!id.id) {
                toast.error(id.msg)
                return
            }  
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Admin créé');
        window.location.reload()
      } catch (err) {
        console.error(err);
        toast.error('Une erreur est survenue!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader title="Admin" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                fullWidth
                helperText={formik.touched.firstname && formik.errors.firstname}
                label="Prénom"
                name="firstname"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.firstname}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                fullWidth
                helperText={formik.touched.lastname && formik.errors.lastname}
                label="Nom"
                name="lastname"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.lastname}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.avatar && formik.errors.avatar)}
                fullWidth
                helperText={formik.touched.avatar && formik.errors.avatar}
                label="Avatar"
                name="avatar"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.avatar}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.email}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Mot de passe"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // required
                value={formik.values.password}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <InputLabel htmlFor="role">Rôle</InputLabel>
              <Select
                error={Boolean(formik.touched.admin_type && formik.errors.admin_type)}
                fullWidth
                helperText={formik.touched.admin_type && formik.errors.admin_type}
                label="Type d'admin"
                name="admin_type"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // required
                value={formik.values.admin_type}
              >
                <MenuItem value="Administrateur">Administrateur</MenuItem>
                <MenuItem value="Formateur">Formateur</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            flexWrap: 'wrap',
            m: -1
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
            // onClick={formik.handleSubmit}
          >
            Créer
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
