import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { DossierPatient } from "./dossier-patient";
import { coursesApi } from "../../../api/course-api";
import { useCallback, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
} from "chart.js";
import { useMounted } from "../../../hooks/use-mounted";
import { feedBacksApi } from "../../../api/feedback-api";
import { useAuth } from "../../../hooks/use-auth";
Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement
);

export const ResultsSecondTurn = (props) => {
  const {
    turn,
    resultData,
    setDialogOpen,
    dialogOpen,
    auditName,
    dataSecondTurn,
    courseId,
    gapSecondTurn,
    setGapSecondTurn
  } = props;
  const [selectedPatient, setSelectedPatient] = useState(0);
  const isMounted = useMounted();
  const { user } = useAuth();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenCollapse = (index) => {
    setSelectedPatient(selectedPatient === index ? selectedPatient : index);
  };

  const getCriteriasGaps = useCallback(
    async (patient_id, turn) => {
      try {
        const data = await feedBacksApi.getCriteriasGaps(patient_id, turn);
        if (isMounted()) {
          setGapSecondTurn(data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMounted]
  );

  useEffect(() => {
    if(resultData.length > 0) getCriteriasGaps(resultData[selectedPatient].patient_id, 2);
  }, [selectedPatient]);

  return (
    <>
      <Grid>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
          sx={{ mt: 2, mb: 1, ml: 2 }}
        >
          Analyse des Patients
        </Typography>
      </Grid>
      <Card sx={{ borderRadius: 5 }}>
        <CardContent>
          {resultData.length > 0 ? (
            <>
              <Grid
                container
                display="flex"
                spacing={2}
                marginTop={2}
                sx={{ justifyContent: "flex-start" }}
              >
                <Grid
                  item
                  xs={1.5}
                  sx={{
                    overflowY: "auto",
                    maxHeight: "350px",
                    border: "1px solid #ccc",
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    color={(theme) =>
                      theme.palette.mode === "dark"
                        ? "white"
                        : process.env.REACT_APP_PRIMARY_COLOR
                    }
                  >
                    Liste des Patients
                  </Typography>
                  <Box sx={{ margin: 1 }}>
                    {turn === 2 &&
                      (resultData.length > 0 ? (
                        resultData.map((criteria, index) => (
                          <Box
                            key={index}
                            sx={{
                              backgroundColor:
                                selectedPatient === index
                                  ? "#f0f0f0"
                                  : "inherit",
                            }}
                          >
                            <Button onClick={() => handleOpenCollapse(index)}>
                              Patient {index + 1}
                            </Button>
                            <Divider />
                          </Box>
                        ))
                      ) : (
                        <Typography>
                          Pas de données pour le premier tour
                        </Typography>
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={10.5}>
                  {resultData.map((result, index) => (
                    <Collapse
                      in={selectedPatient === index}
                      timeout="auto"
                      unmountOnExit
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={4} sx={{ paddingLeft: 4 }}>
                          <Typography variant="h6">
                            Informations du Patient :
                          </Typography>
                          <List>
                            <Box>
                              <Typography fontWeight="bold" color={"grey"}>
                                Age :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography marginTop={1}>
                                {result.age ? result.age : "Non Renseigné"}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                marginTop={2}
                                fontWeight="bold"
                                color={"grey"}
                              >
                                Sexe :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography marginTop={1}>
                                {result.sexe ? result.sexe : "Non Renseigné"}
                              </Typography>
                            </Box>
                            <ListItem display="flex">
                              <Button
                                variant={"outlined"}
                                onClick={handleOpenDialog}
                                sx={{
                                  borderRadius: 999,
                                  marginRight: 1,
                                  marginTop: 2,
                                  textTransform: "none",
                                  width: "80%",
                                  color: "black",
                                  boxShadow: "0 0 0 1px grey",
                                }}
                              >
                                Consulter le dossier patient
                              </Button>
                            </ListItem>
                            {dialogOpen && (
                              <DossierPatient
                                open={dialogOpen}
                                onClose={handleCloseDialog}
                                auditName={auditName}
                                number={result.patient_number}
                                patient_id={result.patient_id}
                              />
                            )}
                          </List>
                        </Grid>
                        <Grid xs={8}>
                          <Typography variant="h6">Réponses :</Typography>
                          <Card
                            sx={{
                              maxWidth: "100%",
                              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                              mb: 3,
                              ml: 1,
                              mt: 1,
                              mr: 1,
                            }}
                          >
                            <CardActionArea
                              sx={{
                                overflowX: "auto",
                                flexGrow: 1,
                                maxHeight: 300,
                                scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`,
                              }}
                            >
                              {result.answer.map((criteria, i) => (
                                <Accordion 
                                  key={i}
                                  sx={{
                                    pointerEvents: criteria.answer === "Oui" && "none",
                                    opacity: criteria.answer === "Oui" && 1,
                                    backgroundColor: '#F2F1F1'
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      criteria.answer !== "Oui" && (
                                        <ExpandMoreIcon />
                                      )
                                    }
                                    aria-controls={`panel-${i}-content`}
                                    id={`panel-${i}-header`}
                                  >
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Grid item xs={1}>
                                        <Typography
                                          sx={{
                                            color:
                                              criteria.answer === "Oui"
                                                ? process.env
                                                    .REACT_APP_YES_COLOR
                                                : criteria.answer === "Non"
                                                ? process.env.REACT_APP_NO_COLOR
                                                : criteria.answer === "NA"
                                                ? process.env
                                                    .REACT_APP_PNC_COLOR
                                                : "inherit",
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                            marginRight: 2,
                                          }}
                                        >
                                          {criteria.answer.toUpperCase()}
                                        </Typography>
                                      </Grid>
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                          backgroundColor:
                                            criteria.answer === "Oui"
                                              ? process.env.REACT_APP_YES_COLOR
                                              : criteria.answer === "Non"
                                              ? process.env.REACT_APP_NO_COLOR
                                              : criteria.answer === "NA"
                                              ? process.env.REACT_APP_PNC_COLOR
                                              : "inherit",
                                          width: 5,
                                          height: 80,
                                          marginRight: 2,
                                        }}
                                      />
                                      <Grid
                                        item
                                        xs={10}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          flex: 1,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color:
                                              criteria.answer === "Oui"
                                                ? process.env
                                                    .REACT_APP_YES_COLOR
                                                : criteria.answer === "Non"
                                                ? process.env.REACT_APP_NO_COLOR
                                                : criteria.answer === "NA"
                                                ? process.env
                                                    .REACT_APP_PNC_COLOR
                                                : "inherit",
                                            ml: 1,
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            width: "70%",
                                          }}
                                        >
                                          Critère {i + 1} :
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: "black",
                                            fontSize: 18,
                                            ml: 1,
                                          }}
                                        >
                                          {
                                            dataSecondTurn && dataSecondTurn.criterias && dataSecondTurn.criterias.length> 0 && dataSecondTurn.criterias[i]
                                              .personal.criteria
                                          }
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  {criteria.answer !== "Oui" &&
                                      gapSecondTurn &&
                                      gapSecondTurn.length > 0 && (
                                        <>
                                          <Typography
                                            sx={{ ml: "12%", mt: "2%" }}
                                            variant="h6"
                                          >
                                            Causes de l'écart pour le critère{" "}
                                            {i + 1} :
                                          </Typography>
                                          <ul>
                                            {gapSecondTurn[i].criteria_gap
                                              .length > 0 ? (
                                              <ul>
                                                {gapSecondTurn[
                                                  i
                                                ].criteria_gap.map((gap, c) => (
                                                  <li
                                                    key={c}
                                                    style={{
                                                      marginLeft: "10%",
                                                      marginTop: "2%",
                                                    }}
                                                  >
                                                    Facteur {gap.lack_factor} :
                                                    Manque de {gap.lack}
                                                  </li>
                                                ))}
                                              </ul>
                                            ) : (
                                              <Typography
                                                sx={{ ml: "12%", mt: "2%" }}
                                                variant="body1"
                                              >
                                                Aucune donnée disponible
                                              </Typography>
                                            )}
                                          </ul>
                                        </>
                                      )}
                                </Accordion>
                              ))}
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </Grid>
                    </Collapse>
                  ))}
                </Grid>
              </Grid>{" "}
            </>
          ) : (
            <Grid item xs={12} sx={{ width: "100%", textAlign: "left" }}>
              <Typography>Pas de Résultats</Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};

{
  /* <Table sx={{ border: "1px solid black" }}>
  <TableBody>
    <TableRow>
      <TableCell
        sx={{
          border: "1px solid black",
          bgcolor: "lightgray",
        }}
      >
        Critère
      </TableCell>
      {result.answer &&
        result.answer.map((criteria, indexResult) => (
          <TableCell
            key={indexResult}
            sx={{
              fontWeight: "bold",
              border: "1px solid black",
            }}
          >
            {indexResult + 1}
          </TableCell>
        ))}
    </TableRow>
    <TableRow>
      <TableCell
        sx={{
          border: "1px solid black",
          bgcolor: "lightgray",
        }}
      >
        Réponse
      </TableCell>
      {result.answer &&
        result.answer.map((criteria, indexResult) => (
          <TableCell
            key={indexResult}
            sx={{
              border: "1px solid black",
            }}
          >
            <Typography
              fontWeight="bold"
              color={
                criteria.answer === "Oui"
                  ? process.env.REACT_APP_YES_COLOR 
                  : criteria.answer === "Non"
                  ? process.env.REACT_APP_NO_COLOR 
                  : process.env.REACT_APP_PNC_COLOR 
              }
            >
              {criteria.answer}
            </Typography>
          </TableCell>
        ))}
    </TableRow>
  </TableBody>
</Table> */
}
