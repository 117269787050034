import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { max } from "date-fns";

const series = [
  {
    color: "#4CAF50",
    data: [],
    name: "Score du formateur",
  },
  {
    color: "#FF9800",
    data: [],
    name: "Score de fiabilité",
  },
  {
    color: "#F44336",
    data: [],
    name: "Score de perplexité",
  },
];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const ScoresProgressions = () => {
  const theme = useTheme();
  const [selectedSeries, setSelectedSeries] = useState([
    "Score du formateur",
    "Score de fiabilité",
    "Score de perplexité",
  ]);
  const [timelinesData, setTimelinesData] = useState({
    series: series,
    xaxis: {
      dataPoints: months,
    },
  });

  const handleChange = (event, name) => {
    if (!event.target.checked) {
      setSelectedSeries(selectedSeries.filter((item) => item !== name));
    } else {
      setSelectedSeries([...selectedSeries, name]);
    }
  };

  const chartSeries =
    timelinesData &&
    timelinesData.series.filter((item) => selectedSeries.includes(item.name));

  const chartOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: chartSeries.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: "circle",
      size: 2,
      strokeWidth: 0,
    },
    stroke: {
      curve: "straight",
      lineCap: "butt",
      width: 1,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: timelinesData.xaxis.dataPoints,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        min: 0,
        max: 100,
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
    ],
  };

  useEffect(() => {
    const _timelinesData = {
      series: [
        {
          color: "#4CAF50",
          data: [54, 91, 56, 57, 0, 72, 53, 81, 90, 86, 60, 93],
          name: "Score du formateur",
        },
        {
          color: "#FF9800",
          data: [50, 56, 0, 74, 56, 83, 55, 81, 56, 73, 78, 93],
          name: "Score de fiabilité",
        },
        {
          color: "#F44336",
          data: [75, 66, 86, 54, 83, 59, 94, 75, 87, 0, 72, 56],
          name: "Score de perplexité",
        },
      ],
      xaxis: {
        dataPoints: months,
      },
    };
    setTimelinesData(_timelinesData);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Card>
        <CardHeader
          title="Variation mensuelle des scores"
          style={{ textAlign: "left" }}
        />

        <Divider />

        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {timelinesData.series.map((item) => (
              <Box
                key={item.name}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  mr: 2,
                }}
              >
                <Checkbox
                  checked={selectedSeries.some(
                    (visibleItem) => visibleItem === item.name
                  )}
                  color="primary"
                  onChange={(event) => handleChange(event, item.name)}
                />
                <Box
                  sx={{
                    backgroundColor: item.color,
                    borderRadius: "50%",
                    height: 8,
                    ml: 1,
                    mr: 2,
                    width: 8,
                  }}
                />
                <Typography color="textPrimary" variant="subtitle2">
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Chart
            height="400"
            options={chartOptions}
            series={chartSeries}
            type="area"
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ScoresProgressions;
