import moment from "moment";

class UserApi {
  async searchUsers(admin_id,search_text,page_number,limit_per_page) {
    const url = `${process.env.REACT_APP_BACK}/api/users_all/search/`
    const values = {
      "admin_id": admin_id,
      "search_text" : search_text,
      "page_number": page_number, 
      "limit_per_page": limit_per_page
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  // Fonction servant à filtrer les utilisateurs selon différents critères
  async searchUsersTrainer(admin_id, search_text, page_number, limit_per_page, audit_id, start_session = null, end_session = null, feedback_filter = null) {
    const url = `${process.env.REACT_APP_BACK}/api/users_all/learners_feedbacks/`

    let formattedStartDate = null
    let formattedEndDate = null

    if (start_session) {
      const parsedDate = moment(start_session, "DD/MM/YYYY")
      formattedStartDate = parsedDate.format('YYYY-MM-DD')
    }
    if (end_session) {
      const parsedDate = moment(end_session, "DD/MM/YYYY")
      formattedEndDate = parsedDate.format('YYYY-MM-DD')

    }

    const values = {
      "admin_id": admin_id,
      "search_text" : search_text,
      "page_number": page_number, 
      "limit_per_page": limit_per_page,
      'audit_id': audit_id,
      "start_session": formattedStartDate,
      "end_session": formattedEndDate,
      "feedback_filter": feedback_filter
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  // Fonction servant à récupérer les dates de session d'un audit
  async getSessionDates(admin_id, audit_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_all/session_dates/`
    const values = {
      "admin_id": admin_id,
      "audit_id": audit_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  async getUsers(admin_id,page_number,limit_per_page) {
    const url = `${process.env.REACT_APP_BACK}/api/users_all/get/`
    const values = {
      "admin_id": admin_id,
      "page_number": page_number, 
      "limit_per_page": limit_per_page
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  
  async getUsersStats(admin_id, customer_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_stats/get/`
    const values = {
      "admin_id": admin_id,
      "customer_id": customer_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  async getUsersStatsFormateur(admin_id, customer_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_stats_formateur/get/`
    const values = {
      "admin_id": admin_id,
      "customer_id": customer_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  async getUsersActivities(admin_id, customer_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_activities/get/`
    const values = {
      "admin_id": admin_id,
      "customer_id": customer_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  async getLastActivities(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/api/activities/getLast/`
    const values = {
      "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  
  async getAllActivities(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/api/activities/getAll/`
    const values = {
      "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  
  async getEppByType(admin_id, type) {
    const url = `${process.env.REACT_APP_BACK}/api/audits_all/get/`
    const values = {
      "admin_id": admin_id,
      "type": type
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  async createUser(admin_id, data) {
    const url = `${process.env.REACT_APP_BACK}/api/create_user/`
    const values = {
      "admin_id": admin_id,
      "firstname": data.firstname,
      "lastname": data.lastname,
      "email": data.email,
      "tlms_id": data.tlms_id,
      "syna_id": data.syna_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  async sendIds(admin_id, users_id) {
    const url = `${process.env.REACT_APP_BACK}/api/credentials/crm/sent/`
    const values = {
      "admin_id": admin_id,
      "users_id": users_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async modifyUser(admin_id, user) {
    const url = `${process.env.REACT_APP_BACK}/api/user/modify/`
    const values = {
      "admin_id": admin_id,
      "user_id": user.details.id,
      "firstname": user.details.firstname,
      "lastname": user.details.lastname,
      "email": user.details.email,
      "tlms_id": user.details.tlms_id,
      "syna_id": user.details.syna_id,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async createAdmin(admin_id, data, access_level, id_service) {
    const url = `${process.env.REACT_APP_BACK}/api/create_admin/`
    const values = {
      "admin_id": admin_id,
      "firstname": data.firstname,
      "lastname": data.lastname,
      "email": data.email,
      "avatar": data.avatar,
      "password": data.password,
      "access_level": access_level,
      "id_service": id_service,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
}

export const userApi = new UserApi();