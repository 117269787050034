import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { DossierPatient } from "./dossier-patient";
import { useCallback, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
} from "chart.js";
import { feedBacksApi } from "../../../api/feedback-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useAuth } from "../../../hooks/use-auth";
Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement
);

export const ResultsFirstTurn = (props) => {
  const {
    turn,
    resultData,
    setDialogOpen,
    dialogOpen,
    auditName,
    dataFirstTurn,
    courseId,
    gapFirstTurn,
    setGapFirstTurn,
  } = props;
  const [selectedPatient, setSelectedPatient] = useState(0);
  const isMounted = useMounted();
  const { user } = useAuth();

  const getCriteriasGaps = useCallback(
    async (patient_id, turn) => {
      try {
        const data = await feedBacksApi.getCriteriasGaps(patient_id, turn);
        if (isMounted()) {
          setGapFirstTurn(data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMounted]
  );

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenCollapse = (index) => {
    setSelectedPatient(selectedPatient === index ? selectedPatient : index);
  };

  useEffect(() => {
    if (resultData.length > 0) getCriteriasGaps(resultData[selectedPatient].patient_id, 1);
  }, [selectedPatient]);

  useEffect(() => {
    console.log(gapFirstTurn);
  }, [gapFirstTurn]);

  console.log(resultData)

  return (
    <>
      <Card sx={{ borderRadius: 1 }}>
        <CardContent>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
          sx={{ mt: 1, mb: 1, ml: 2 }}
        >
          Analyse des Patients
        </Typography>
          <Divider sx={{ my: 2 }} />

          {resultData.length > 0 ? (
            <>
              {" "}
              <Grid
                container
                display="flex"
                spacing={2}
                marginTop={1}
                sx={{ justifyContent: "flex-start" }}
              >
                <Grid
                  item
                  xs={12} md={3} lg={3}
                  sx={{
                    // overflowY: "auto",
                    // maxHeight: "350px",
                    // border: "1px solid #ccc",
                    // borderRadius: 2,
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={(theme) =>
                      theme.palette.mode === "dark"
                        ? "white"
                        : process.env.REACT_APP_PRIMARY_COLOR
                    }
                  >
                    Liste des Patients
                  </Typography>
                  <Box sx={{ margin: 1 }}>
                    {turn === 1 &&
                      (resultData.length > 0 ? (
                        <Grid
                          container
                          display="flex"
                          spacing={2}
                          marginTop={2}
                          sx={{ justifyContent: "flex-start" }}
                        >
                          {resultData.map((criteria, index) => (
                            <Grid
                              item
                              xs={6} md={6} lg={6}
                            >
                              <Box
                                key={index}
                                sx={{
                                  backgroundColor:
                                    selectedPatient === index
                                      ? "#f0f0f0"
                                      : "inherit",
                                }}
                              >
                                <Button onClick={() => handleOpenCollapse(index)}>
                                  Patient {index + 1}
                                </Button>
                                <Divider />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography>
                          Pas de données pour le premier tour
                        </Typography>
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={9} lg={9} >
                  {resultData.map((result, index) => (
                    <Collapse
                      in={selectedPatient === index}
                      timeout="auto"
                      unmountOnExit
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={4} sx={{ paddingLeft: 4 }}>
                          <Typography variant="h6">
                            Informations du Patient :
                          </Typography>
                          <List>
                            <Box>
                              <Typography fontWeight="bold" color={"grey"}>
                                Age :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography marginTop={1}>
                                {result.age ? result.age : "Non Renseigné"}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                marginTop={2}
                                fontWeight="bold"
                                color={"grey"}
                              >
                                Sexe :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography marginTop={1}>
                                {result.sexe ? result.sexe : "Non Renseigné"}
                              </Typography>
                            </Box>
                            <ListItem display="flex">
                              <Button
                                variant={"outlined"}
                                onClick={handleOpenDialog}
                                sx={{
                                  borderRadius: 999,
                                  marginRight: 1,
                                  marginTop: 2,
                                  textTransform: "none",
                                  width: "80%",
                                  color: "black",
                                  boxShadow: "0 0 0 1px grey",
                                }}
                              >
                                Consulter le dossier patient
                              </Button>
                            </ListItem>
                            {dialogOpen && (
                              <DossierPatient
                                open={dialogOpen}
                                onClose={handleCloseDialog}
                                auditName={auditName}
                                number={result.patient_number}
                                patient_id={result.patient_id}
                              />
                            )}
                          </List>
                        </Grid>
                        <Grid xs={8}>
                          <Typography variant="h6">Réponses :</Typography>
                          <Box
                          // >
                          //   <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                                mb: 3,
                                ml: 1,
                                mt: 1,
                                mr: 1,
                              // }}
                            // >
                            //   <CardActionArea
                            //     sx={{
                                  overflowX: "auto",
                                  flexGrow: 1,
                                  height: 320,
                                  scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`,
                                }}
                              >
                                {result.answer.map((criteria, i) => (
                                  <Accordion
                                    key={i}
                                    sx={{
                                      pointerEvents:
                                        criteria.answer === "Oui" && "none",
                                      opacity: criteria.answer === "Oui" && 1,
                                      backgroundColor: "#F2F1F1",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        criteria.answer !== "Oui" && (
                                          <ExpandMoreIcon />
                                        )
                                      }
                                      aria-controls={`panel-${i}-content`}
                                      id={`panel-${i}-header`}
                                    >
                                      <Grid container>
                                        <Grid
                                          container
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <Grid item xs={1}>
                                            <Typography
                                              sx={{
                                                color:
                                                  criteria.answer === "Oui"
                                                    ? process.env
                                                        .REACT_APP_YES_COLOR
                                                    : criteria.answer === "Non"
                                                    ? process.env
                                                        .REACT_APP_NO_COLOR
                                                    : criteria.answer === "NA"
                                                    ? process.env
                                                        .REACT_APP_PNC_COLOR
                                                    : "inherit",
                                                fontSize: 18,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                                marginRight: 2,
                                                flex: "0 0 auto",
                                              }}
                                            >
                                              {criteria.answer.toUpperCase()}
                                            </Typography>
                                          </Grid>
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                              backgroundColor:
                                                criteria.answer === "Oui"
                                                  ? process.env
                                                      .REACT_APP_YES_COLOR
                                                  : criteria.answer === "Non"
                                                  ? process.env
                                                      .REACT_APP_NO_COLOR
                                                  : criteria.answer === "NA"
                                                  ? process.env
                                                      .REACT_APP_PNC_COLOR
                                                  : "inherit",
                                              width: 5,
                                              height: 80,
                                              marginRight: 2,
                                            }}
                                          />
                                          <Grid
                                            item
                                            xs={10}
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              flex: 1,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                color:
                                                  criteria.answer === "Oui"
                                                    ? process.env
                                                        .REACT_APP_YES_COLOR
                                                    : criteria.answer === "Non"
                                                    ? process.env
                                                        .REACT_APP_NO_COLOR
                                                    : criteria.answer === "NA"
                                                    ? process.env
                                                        .REACT_APP_PNC_COLOR
                                                    : "inherit",
                                                ml: 1,
                                                fontSize: 18,
                                                fontWeight: "bold",
                                                width: "70%",
                                              }}
                                            >
                                              Critère {i + 1} :
                                            </Typography>
                                            <Typography
                                              sx={{
                                                color: "black",
                                                fontSize: 18,
                                                ml: 1,
                                              }}
                                            >
                                              {dataFirstTurn &&
                                                dataFirstTurn.criterias &&
                                                dataFirstTurn.criterias.length >
                                                  0 &&
                                                dataFirstTurn.criterias[i]
                                                  .personal.criteria}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </AccordionSummary>
                                    {criteria.answer !== "Oui" &&
                                      gapFirstTurn &&
                                      gapFirstTurn.length > 0 && (
                                        <>
                                          <Typography
                                            sx={{ ml: "12%", mt: "2%" }}
                                            variant="h6"
                                          >
                                            Causes de l'écart pour le critère{" "}
                                            {i + 1} :
                                          </Typography>
                                          <ul>
                                            {gapFirstTurn[i].criteria_gap
                                              .length > 0 ? (
                                              <ul>
                                                {gapFirstTurn[
                                                  i
                                                ].criteria_gap.map((gap, c) => (
                                                  <li
                                                    key={c}
                                                    style={{
                                                      marginLeft: "10%",
                                                      marginTop: "2%",
                                                    }}
                                                  >
                                                    Facteur {gap.lack_factor} :
                                                    Manque de {gap.lack}
                                                  </li>
                                                ))}
                                              </ul>
                                            ) : (
                                              <Typography
                                                sx={{ ml: "12%", mt: "2%" }}
                                                variant="body1"
                                              >
                                                Aucune donnée disponible
                                              </Typography>
                                            )}
                                          </ul>
                                        </>
                                      )}
                                  </Accordion>
                                ))}
                              {/* </CardActionArea> */}
                            {/* </Card> */}
                          </Box>
                        </Grid>
                      </Grid>
                    </Collapse>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sx={{ width: "100%", textAlign: "left" }}>
              <Typography>Pas de Résultats</Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};
