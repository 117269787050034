class CriteriasApi {
    async createCriteria(admin_id, criteria_name, criteria_number, audits_id) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/create/`
        const values = {
            "admin_id": admin_id,
            "criteria_name": criteria_name,
            "criteria_number": criteria_number,
            "audits_id": audits_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async deleteCriteria(admin_id, criteria_id) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/delete/`
        const values = {
            "admin_id": admin_id,
            "criteria_id": criteria_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async modifyCriteria(admin_id, data) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/modify/`
        const values = {
            "admin_id": admin_id,
            "data": data
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyNameCriteria(admin_id, criteria_id, criteria_name) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/modifyName/`
        const values = {
            "admin_id": admin_id,
            "criteria_id": criteria_id,
            "criteria_name": criteria_name
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyPageNumber(admin_id, criteria_id, criteria_page_number) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/modifyPageNumber/`
        const values = {
            "admin_id": admin_id,
            "criteria_id": criteria_id,
            "criteria_page_number": criteria_page_number
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyInclude(admin_id, criteria_id, include) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/modifyInclude/`
        const values = {
            "admin_id": admin_id,
            "criteria_id": criteria_id,
            "include": include
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifySkill(admin_id, criteria_id, skill_id) {
        const url = `${process.env.REACT_APP_BACK}/api/criteria/modifySkill/`
        const values = {
            "admin_id": admin_id,
            "criteria_id": criteria_id,
            "skills_id": skill_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const criteriasApi = new CriteriasApi();