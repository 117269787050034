import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCallback, useEffect } from "react";
import "./customCalendarStyles.css";
import React, { useState } from "react";
import "moment/locale/fr";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { mt } from "date-fns/locale";
import { useAuth } from "../../../hooks/use-auth.js";
import { Modal, Box, Typography, Button } from "@mui/material";
import { topicsApi } from "../../../api/topic-api.js";
import { useMounted } from "../../../hooks/use-mounted.js";

moment.locale("fr");
const localizer = momentLocalizer(moment);

// const dummyDataPresence = [
//   {
//     audit_name: "Bilan des soins infirmiers",
//     meeting_start_date: "2024-05-25 09:00:00",
//     meeting_end_date: "2024-05-25 12:00:00",
//     meeting_place: "Radisson Hotel Nice Airport, Nice 06000",
//     number_session: 5,
//     type: "présentielle",
//   },
//   {
//     audit_name: "Bilan des soins infirmiers",
//     meeting_start_date: "2024-05-31 15:00:00",
//     meeting_end_date: "2024-05-31 17:00:00",
//     meeting_place: "Radisson Hotel Nice Airport, Nice 06000",
//     number_session: 5,
//     type: "présentielle",
//   },
// ];

// const dummyDataVirtual = [
//   {
//     audit_name: "Bilan des soins infirmiers",
//     meeting_start_date: "2024-05-27 18:00:00",
//     meeting_end_date: "2024-05-27 20:00:00",
//     number_session: 5,
//     type: "virtuelle",
//   },
// ];

const eventStyleGetter = (event, start, end, isSelected) => {
  var style = {
    height: "40px",
    width: "90%",
    backgroundColor: "#E39393",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "10px",
    borderRadius: "10px",
    color: "white",
    wordWrap: "break-word",
  };
  return {
    style: style,
  };
};
const EventModal = ({ open, onClose, event }) => {
  if (!event) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          height: 350,
          bgcolor: "background.paper",
          outline: "unset",
          border: "0",
          borderRadius: "13%",
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              height: "45px",
              width: "40%",
              backgroundColor:
                event.type === "présentielle" ? "#E39393" : "#9ac657",
              borderRadius: "10px",
              border: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(() => {
              const options = {
                weekday: "long",
                // year: "numeric",
                month: "long",
                day: "numeric",
              };
              const startDate = new Date(event.start);
              const endDate = new Date(event.end);

              // Formatage de la date de début
              const startDateString = startDate.toLocaleDateString(
                "fr-FR",
                options
              );

              // Récupération de l'heure de début
              const startHour = startDate.toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit",
              });

              // Récupération de l'heure de fin
              const endHour = endDate.toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit",
              });

              return (
                <Typography
                  variant="body1"
                  color="black"
                  sx={{
                    margin: 0,
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "13px",
                  }}
                >
                  {startDateString || "Non Renseigné"}
                  <br />
                  {startHour || "Non Renseigné"} - {endHour || "Non Renseigné"}
                </Typography>
              );
            })()}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            height: "20px",
            width: "30%",
            backgroundColor: "#9DA4C8",
            borderRadius: "20px",
            border: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              margin: 0,
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "13px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Session N°{event.number_session}
          </Typography>
        </Box>

        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          style={{
            color: "#A9A9A9",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "15px",
            fontWeight: "bold",
          }}
        >
          EPP:
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "15px",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          {" "}
          {event.audit_name}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          style={{
            color: "#A9A9A9",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "15px",
            fontWeight: "bold",
            marginTop: "25px",
          }}
        >
          Lieu:
        </Typography>
        {event.type === "présentielle" ? (
          <Typography
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "15px",
              fontWeight: "bold",
              margin: 0,
            }}
          >
            {event.meeting_place}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "15px",
              fontWeight: "bold",
              margin: 0,
            }}
          >
            Réunion Zoom
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

const CustomEvent = ({ event }) => {
  return (
    <Box sx={{ fontSize: "11px", fontWeight: "bold" }}>
      <Box>
        {(() => {
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          const startDate = new Date(event.start);
          const endDate = new Date(event.end);

          // Récupération de l'heure de début
          const startHour = startDate.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          });

          // Récupération de l'heure de fin
          const endHour = endDate.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          });

          return (
            <Typography
              variant="body1"
              color="black"
              sx={{
                margin: 0,
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "bold",
                color: "white",
                fontSize: "13px",
              }}
            >
              {startHour || "Non Renseigné"} - {endHour || "Non Renseigné"}
            </Typography>
          );
        })()}
      </Box>
      <Box>Session N°{event.number_session}</Box>
    </Box>
  );
};

const dayStyleGetter = (date, resourceId) => {
  const style = {
    color: "#9DA4C8",
    margin: 5,
    fontFamily: "Montserrat",
  };
  return {
    style: style,
  };
};

const CustomToolbar = ({ onNavigate, label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "10px",
      }}
    >
      <Box />
      <Box />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          onClick={() => onNavigate("PREV")}
          sx={{
            border: "none",
            background: "transparent",
            cursor: "pointer",
            color: "#9DA4C8",
          }}
        >
          <NavigateBeforeIcon fontSize="small" />
        </Button>
        <Typography
          sx={{
            fontSize: " 15px",
            fontWeight: "650",
            textTransform: "uppercase",
          }}
        >
          {label}
        </Typography>

        <Button
          onClick={() => onNavigate("NEXT")}
          sx={{
            border: "none",
            background: "transparent",
            cursor: "pointer",
            color: "#9DA4C8",
          }}
        >
          <NavigateNextIcon fontSize="small" />
        </Button>
      </Box>
      <Box />
      <Box />
    </Box>
  );
};

const Agenda = () => {
  const { user } = useAuth();
  const isMounted = useMounted();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [events, setEvents] = useState({});
  const [virtualEvents, setVirtualEvents] = useState();
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [formattedVirtualEvents, setFormattedVirtualEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const getComingMeetings = useCallback(async () => {
    try {
      const res = await topicsApi.getComingDates(user.admin_id);
      if (isMounted()) {
        setEvents(res.matching_sessions);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getVirtualComingMeetings = useCallback(async () => {
    try {
      const res = await topicsApi.getVirtualComingDates(user.admin_id);
      if (isMounted()) {
        setVirtualEvents(res.matching_sessions);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getComingMeetings();
  }, [getComingMeetings]);

  useEffect(() => {
    getVirtualComingMeetings();
  }, [getVirtualComingMeetings]);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    if (events && events.length > 0) {
      const formattedEvents = events.map((event) => ({
        audit_name: event.audit_name,
        start: new Date(event.meeting_start_date),
        end: new Date(event.meeting_end_date),
        meeting_place: event.meeting_place,
        number_session: event.number_session,
        type: event.type,
      }));
      setFormattedEvents(formattedEvents);
    }
  }, [events]);

  useEffect(() => {
    if (virtualEvents && virtualEvents.length > 0) {
      const formattedEvents = virtualEvents.map((event) => ({
        audit_name: event.audit_name,
        start: new Date(event.meeting_start_date),
        end: new Date(event.meeting_end_date),
        number_session: event.number_session,
        type: event.type,
      }));
      setFormattedVirtualEvents(formattedEvents);
    }
  }, [virtualEvents]);

  useEffect(() => {
    if (
      formattedEvents &&
      formattedEvents.length > 0 &&
      formattedVirtualEvents &&
      formattedVirtualEvents.length > 0
    ) {
      // Concatène les listes d'événements avec les types
      setAllEvents(formattedEvents.concat(formattedVirtualEvents));
    }
  }, [formattedEvents, formattedVirtualEvents]);

  // useEffect(()=>{
  //   console.log(events)
  //   console.log(virtualEvents)
  //   console.log(formattedEvents)
  //   console.log(formattedVirtualEvents)
  //   console.log(allEvents)
  // },[allEvents, events, virtualEvents,formattedEvents, formattedVirtualEvents])

  return (
    <Box sx={{ height: "100%", padding: 4, background: "#A2A0C14A" }}>
      {/* <Box> */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Montserrat",
          padding: "40px 0 0 20px",
          color: (theme) => (theme.palette.mode === "dark" ? "white" : "black"),
          mt: 2,
        }}
      >
        Votre Agenda
      </Typography>
      {/* </Box> */}
      <Box
        sx={{
          fontFamily: "Montserrat",
          padding: 2,
          background: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.3)",
          mt: 2,
        }}
      >
        <center>
          <Calendar
            localizer={localizer}
            events={allEvents ?? []}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 750, width: "90%" }}
            // eventPropGetter={eventStyleGetter}
            eventPropGetter={(event) => {
              return {
                style: {
                  backgroundColor:
                    event.type === "présentielle"
                      ? process.env.REACT_APP_PRESENTIEL_COLOR
                      : process.env.REACT_APP_VIRTUEL_COLOR,
                },
              };
            }}
            dayPropGetter={dayStyleGetter}
            components={{
              toolbar: CustomToolbar,
              event: CustomEvent,
            }}
            onSelectEvent={handleEventSelect}
          />
          <EventModal
            open={modalOpen}
            onClose={handleCloseModal}
            event={selectedEvent}
          />
        </center>
      </Box>
    </Box>
  );
};

export default Agenda;
