import ReactDOM from 'react-dom'
import { StrictMode } from 'react'
import App from './pages/_app'
import { BrowserRouter } from 'react-router-dom'
import { SettingsProvider } from './contexts/settings-context'
import './index.css'; 

ReactDOM.render(
  <StrictMode>
    <SettingsProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </SettingsProvider>
  </StrictMode>, document.getElementById('root')
)