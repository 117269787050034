import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { SeverityPill } from "../../../components/severity-pill";
import { gtm } from "../../../lib/gtm";

import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Scrollbar } from "../../../components/scrollbar";
import { useMounted } from "../../../hooks/use-mounted";
import SearchIcon from "@mui/icons-material/Search";

import {
  Link as RouterLink,
  useLocation,
  useParams,
} from "react-router-dom";
import { FormersApi } from "../../../api/formateur-api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormerBasicDetails } from "./FormerBasicDetails";

const sortOptions = [
  {
    label: "Fin de session plus proche",
    value: "end_session_order|asc",
  },
  {
    label: "Fin de session plus éloignée",
    value: "end_session_order|desc",
  },
];

const FormateurProfile = () => {
  const isMounted = useMounted();
  const { formerId } = useParams();
  const [formerData, setFormerData] = useState({});

  const getFormerData = useCallback(async () => {
    try {
      const data = await FormersApi.getFormerById(user.admin_id, formerId);
    console.log(data)
      if (isMounted()) {
        setFormerData(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    if (formerId) {
      getFormerData();
      gtm.push({ event: "page_view" });
    }
  }, [formerId]);

  const { user } = useAuth();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 10,
        mx: 3,
      }}
    >
      <Box sx={{ mb: 1 }}>
        <RouterLink
          to="/dashboard/formateur"
          style={{ textDecoration: "none" }}
        >
          <Link
            color="textPrimary"
            component="a"
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="subtitle2" component="span">
              {" "}
              Liste des formateurs
            </Typography>
          </Link>
        </RouterLink>
      </Box>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid
          item
          sx={{
            alignItems: "center",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div>
            <Typography variant="h4">{formerData.lastname} {formerData.firstname}</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2
              }}
            >
              <Typography variant="subtitle2">Id du formateur :</Typography>
              <Chip label={formerId} size="small" sx={{ ml: 1 }} />
            </Box>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FormerBasicDetails formerData={formerData} setFormerData={setFormerData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormateurProfile;
