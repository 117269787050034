import { useEffect, useState } from 'react';
import { Box, Card, Container, Typography, Grid, Avatar  } from '@mui/material';
import { GuestGuard } from '../../components/authentication/guest-guard';
import { JWTLogin } from '../../components/authentication/jwt-login';
import { useAuth } from '../../hooks/use-auth';
import { gtm } from '../../lib/gtm';
import { JWTForgotPassword } from '../../components/authentication/jwt-forgot-password';

const Login = () => {
  const { platform } = useAuth();
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false)
  const [loginError, setLoginError] = useState(false)

  const handleForgottenPassword = () => {
    setIsPasswordForgotten(true)
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  
  // Objet de style afin de personnaliser le logo de l'entreprise
  const imageStyle = {
    position: 'absolute',
    width: '100px', 
    height: '100px', 
    marginTop: '-50px'
  };

  return (
    <>
      <Grid
        component="main"
        container
        sx={{
          height: '100vh',
          overflow: 'hidden !important',
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url('/static/waves_web.svg'), url('/static/fond_web.jpg')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Grid
          item
          xs={6}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {process.env.REACT_APP_HAS_LOGIN_UP == 'true' && (
              <Box
                component="img"
                sx={{
                  height: 80,
                  m: 2,
                  mx: 46
                }}
                alt="logo"
                src={process.env.REACT_APP_LOGIN_UP}
              />
            )}
            <Typography
              variant="h1"
              sx={{
                mb: 8,
                color: "#3B2755",
            }}>
              BACK-OFFICE
            </Typography>
            <Card
              elevation={20}
              sx={{
                // Code du gradient en fond pour la carte recevant les identifiants de connexion
                background: 'linear-gradient(to bottom right, #7979AD, #8D8CAF)',
                width: '40%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  mb: 6
                }}
              >
                <Avatar
                  alt="Image"
                  src={process.env.REACT_APP_TAB_LOGO}
                  sx={{
                    width: 100, // Ajustez la taille du cadre circulaire selon vos besoins
                    height: 100, // Ajustez la taille du cadre circulaire selon vos besoins
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    background: "linear-gradient(to bottom, #5959b4, #cbcbee)"
                  }}
                  style={imageStyle}
                />
              </Box>
              <Box
                sx={{
                  p: 3,
                  mt: -3,
                }}
              >
                {platform === 'JWT' && !isPasswordForgotten ?
                  <JWTLogin
                    handleForgottenPassword={handleForgottenPassword}
                    setLoginError={setLoginError}
                  /> :
                  <JWTForgotPassword
                    setIsPasswordForgotten={setIsPasswordForgotten}
                    setLoginError={setLoginError}
                  />
                }
              </Box>
            </Card>
            { loginError && 
            <Typography variant='h6'
              sx={{
              color: "#6b0c01", fontWeight: "bold", mt: 3
                }}>{ loginError }</Typography>}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

Login.getLayout = (page) => (
  <GuestGuard>
    {page}
  </GuestGuard>
);

export default Login;
