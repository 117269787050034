/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable prefer-destructuring */
import { Box, Card, CircularProgress, Grid, Rating, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useRef, useState } from 'react';
import PeopleIcon from '@mui/icons-material/People';

import { Divider, Tabs } from '@mui/material';
import QuestionSatisfactionRow from './question-satisfaction-row';
import { satisfactionApi } from '../../../api/satisfaction-api';
import { useSettings } from '../../../hooks/use-settings';


// Donner une couleur à la barre de pourcentage selon sa valeur (rouge, orange ou vert)
const interpretColorLevel = (theme, done) => {
  const level = typeof done === 'string' ? parseFloat(done) : done;
  if (level <= 25) {
    return theme.palette.error.main;
  }
  if (level > 25 && level <= 50) {
    return theme.palette.warning.main;
  }
  return theme.palette.success.main;
};

const SatisfactionSection = (props) => {
  const { auditId, adminId } = props
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [auditSatisfaction, setAuditSatisfaction] = useState(null);
  const [surveyDetails, setSurveyDetails] = useState(null)

  const { settings } = useSettings()
  
  // Définir les couleurs en fonction du thème
  const chartColors = {
    light: {
      globalPercentage: process.env.REACT_APP_PRIMARY_COLOR, //theme.palette.primary.main,
      // Ajoutez d'autres couleurs si nécessaire
    },
    dark: {
      globalPercentage: process.env.REACT_APP_SECONDARY_COLOR, // theme.palette.primary.dark,
      // Ajoutez d'autres couleurs si nécessaire
    },
  };

// Variable définissant les options du graphique de satisfaction globale
  const chartOptions = {
    chart: {
      background: 'transparent',
      id: `basic-bar${Math.random()}`,
      toolbar: {
        show: true
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      toolbar: {
          tools: {
            download: false // Désactiver la mini-fenêtre de téléchargement
          }
        },
    },
    colors: [
      interpretColorLevel(theme, parseFloat(auditSatisfaction?.satisfactionPercentage))
    ],
    height: 200,
    type: 'radialBar',
    plotOptions: {
      radialBar: {
        hollow: {
          size: '55%',
        },
        dataLabels: {
          name: {
            fontSize: '14',
            offsetY: 30,
            color: chartColors[settings.theme].globalPercentage
          },
          value: {
            fontSize: '32',
            offsetY: -20,
            fontWeight: 'bold',
            color: chartColors[settings.theme].globalPercentage
          },
          total: {
            show: false,
            label: 'Moyenne',
            color: theme.palette.mode === 'light' ? 'black' : 'white',
            formatter: () => (`${(auditSatisfaction?.satisfactionPercentage % 1) === 0 ? parseInt(auditSatisfaction?.satisfactionPercentage, 10) : auditSatisfaction?.satisfactionPercentage}%`)
          }
        },
      },
    },
    labels: ['Satisfaction globale']
  };

  // Appel API pour récupérer les résultats de satisfaction (globaux et détaillés)
  const getSurveySatisfactionResults = async () => {
    try {
      const surveySatisfactionResults = await satisfactionApi.getSurveySatisfactionResults(adminId, auditId);
      setIsLoading(false)
      setAuditSatisfaction(surveySatisfactionResults?.auditSatisfaction);
      setSurveyDetails(surveySatisfactionResults?.surveyDetails)
    } catch (err) { setIsLoading(false); console.log(err); }
  };

  useEffect(() => {
    setIsLoading(true)
    getSurveySatisfactionResults()
  }, [])

  return (
    <>
      {isLoading
            ? 
        (<CircularProgress />)
      : !surveyDetails && !auditSatisfaction ? 
      (<>
        <Typography>
          Pas de données de satisfaction pour cet audit 
        </Typography>
      </>
      ) : ( 
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          xl={9}
          xs={12}
          minHeight={400}
        >
              <Card elevation={20} sx={{ height: '100%', position: 'relative' }}>
                <Box
                  sx={{
                    height: '100%',
                    padding: 1,
                    paddingBottom: 4,
                    '@media (max-width: 530px)': {
                      padding: 0
                    }
                  }}
                >
                  <Table aria-label="collapsible table" sx={{ height: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: theme.palette.text.secondary }}>{'Questions'.toUpperCase()}</TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            paddingRight: 16,
                            color: theme.palette.text.secondary,
                            '@media (max-width: 530px)': {
                              padding: 0
                            }
                          }}
                        >
                          {'Taux de satisfaction'
                            .toUpperCase()}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {surveyDetails?.questions.map((row) => (
                        <QuestionSatisfactionRow key={row.id} questionRow={row} courseId={auditSatisfaction.id} />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
            </Card>
        </Grid>
        <Grid
          item
          lg={4}
          xl={3}
          xs={12}
        >
              <Card
                elevation={20}
                style={{
                  maxWidth: 750,
                  margin: 'auto',
                  position: 'relative'
                }}
              >
                {
                  !(auditSatisfaction.id === null)
                  ? (
                    <Box sx={{ pt: 1, pr: 1 }}>
                      <ReactApexChart
                        options={chartOptions}
                        series={[auditSatisfaction.satisfactionPercentage]}
                        type="radialBar"
                        height={350}
                      />
                      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: '-2rem', mb: '1rem'}}>
                        <Rating name="read-only" precision={0.1} value={(Number(auditSatisfaction.satisfactionPercentage) / 20).toFixed(1)} readOnly sx={{ color: process.env.REACT_APP_SECONDARY_COLOR}} />
                        <Typography sx={{ fontWeight: 'medium' }}>{`${(Number(auditSatisfaction.satisfactionPercentage) / 20).toFixed(1)} / 5`}</Typography>
                        </Box>
                    </Box>
                  )
                  : (
                    <Box sx={{ height: '300px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant="span">Pas de résultat</Typography>
                    </Box>
                  )
                }
                <Divider />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 1, mt: 1.5 }}>
                  <PeopleIcon />
                  <Typography variant="subtitle2" padding={0}>Nombre de personnes participant au questionnaire :</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1.5 }}>
                  <Typography variant="h6">{auditSatisfaction.surveyParticipants}  </Typography>
                </Box>
              </Card>
            
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default SatisfactionSection;
