import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useAuth } from "../../../hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import ScoresProgressions from "./ScoresProgressions";
import MeanScores from "./MeanScores";

const data = {
  series: [
    {
      color: "#688eff",
      data: 38,
      label: "On route",
    },
    {
      color: "#4CAF50",
      data: 50,
      label: "Available",
    },
    {
      color: "#FF9800",
      data: 12,
      label: "Out of service",
    },
  ],
};

const Score = () => {
  const theme = useTheme();
  const { user } = useAuth();

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 2 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
                  Scores de feedbacks
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={4}>
            <Grid item xl={12} xs={12}>
              <MeanScores />
            </Grid>
            <Grid item xl={12} xs={12}>
              <ScoresProgressions />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Score;
