import { useCallback, useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useNavigate} from 'react-router-dom'
import { Box, Button, Card, Chip, Divider, Fab, InputLabel, Link, Grid, InputAdornment, Tab, Tabs, Typography, TextField, Select, MenuItem, CircularProgress, CardHeader } from '@mui/material';
import { AuditsGraphics } from '../../../components/dashboard/audits/audits-graphics';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AuditsDetails } from '../../../components/dashboard/audits/audits-details';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { gtm } from '../../../lib/gtm';
import { statsApi } from '../../../api/stat-api';
import { criteriasApi } from '../../../api/criteria-api';
import { objectivesApi } from '../../../api/objective-api';
import { auditsApi } from '../../../api/audit-api';
import { skillsApi } from '../../../api/skill-api';
import SatisfactionSection from '../../../components/dashboard/satisfactions/satisfaction-section';


const tabs = [
  { label: 'Détails', value: 'details' },
  { label: 'Statistiques', value: 'statistiques' },
  { label: 'Satisfaction', value: 'satisfaction' }
];

const AuditsGraphicsDetails = () => {
  const {auditId} = useParams();
  const isMounted = useMounted();
  const [dataGraphics, setDataGraphics] = useState([])
  const [auditsDetails, setAuditsDetails] = useState([])
  const [currentTab, setCurrentTab] = useState('details');
  const [modifyAudit, setModifyAudit] = useState(false);
  const [criterias, updateCriterias] = useState([]);
  const [objectives, updateObjectives] = useState([]);
  const [skills, updateSkills] = useState([]);
  const [showSkillCriterias, setShowSkillCriterias] = useState([])
  const [modifyCriteria, setModifyCriteria] = useState('');
  const [modifySkills, setModifySkills] = useState('');
  const [modifyObjective, setModifyObjective] = useState('');
  const [loadingStats, setLoadingStats] = useState(true);
  const { user } = useAuth(); 

  const navigate = useNavigate();

  const [modify, setModify] = useState({
    "syna_formation_id": null,
    "pdf_link": null,
    "image_link": null,
    "audit_name": null
  });

  const [modifyText, setModifyText] = useState("")

  const choiceOptions = [{ label: 'Non inclus', value: 1}, {label: 'Neutre', value: 2}, {label: 'Inclus', value: 3}];

  async function handleModify(field) {
    setModify(modify => ({
      ...modify,
      [field]: false})
    );
    let changeDataGraphics = { ...dataGraphics };
    changeDataGraphics[field] = modifyText;
    setDataGraphics(changeDataGraphics);
    await auditsApi.modifyAudit(user.admin_id, changeDataGraphics)
  }

  const getGraphics = useCallback(async () => {
    try {
      setLoadingStats(true)
      const data = await statsApi.getGraphics(user.admin_id, auditId)
      if (data.msg) {
        console.log(data)
        navigate('/dashboard/audits/not-available')
      }
      if (isMounted()) {
        setDataGraphics(data);
        setLoadingStats(false)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getAuditsDetails = useCallback(async () => {
    try {
      const data = await statsApi.getAuditsDetails(user.admin_id, auditId) 
      if (isMounted()) {
       
        data['criteria'].forEach((el) => {
          el.edited = false
        })
        data['objectives'].forEach((el) => {
          el.edited = false
        })
        data['skills'].forEach((el) => {
          el.edited = false
        })
        setShowSkillCriterias(data['skills'].map(() => false))
        updateSkills(data['skills'])
        updateCriterias(data['criteria'])
        updateObjectives(data['objectives'].sort(function(a,b) {
          return a.place - b.place
        }))
        setAuditsDetails(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  async function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(criterias);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    await criteriasApi.modifyCriteria(user.admin_id, items)
    updateCriterias(items);
  }

  async function handleAddCriteria() {
    let items = Array.from(criterias);
    let new_number;
    if (items.length === 0) {
      new_number = 1;
    }
    else {
      new_number = Number(items[items.length-1]["number"]) + 1;
    }

    const id = await criteriasApi.createCriteria(user.admin_id, "", new_number, auditId)
    items.push({ id: id.id.toString(), audits: auditId, criteria: "", number: new_number, edited: true, include: 2 });

    updateCriterias(items);
  }

  async function handleAddSkill() {
    let items = Array.from(skills);
    let new_number;
    if (items.length === 0) {
      new_number = 1;
    }
    else {
      new_number = Number(items[items.length-1]["number"]) + 1;
    }

    const response = await skillsApi.createSkill(user.admin_id, "", new_number, auditId)
    items.push({ id: response.id, eppId: auditId, skill: "", criterias: [], edited: true });

    updateSkills(items);
  }

  async function handleAddObjective() {
    let items = Array.from(objectives);
    let new_place;
    if (items.length === 0) {
      new_place = 1;
    }
    else {
      new_place = Number(items[items.length-1]["place"]) + 1;
    }

    const id = await objectivesApi.createObjective(user.admin_id, "", new_place, auditId)
    items.push({ id: id.id.toString(), audits: auditId, description: "", place: new_place, edited: true});

    updateObjectives(items);
  }

  async function handleDoubleClick(id, state) {
    let items = Array.from(criterias);
    if (state === false) {
      items[id]["criteria"] = modifyCriteria
      await criteriasApi.modifyNameCriteria(user.admin_id, items[id]["id"], modifyCriteria)
    }
    items[id]["edited"] = state
    updateCriterias(items);
  }

  async function handleDoubleClickSkill(id, state) {
    
    let items = Array.from(skills);
    if (state === false) {
      items[id]["skill"] = modifySkills
      const data = {
        skill: modifySkills,
        skill_id: items[id]["id"]
      }
      await skillsApi.modifySkill(user.admin_id, data)
    }
    items[id]["edited"] = state
    updateSkills(items);
  }

  async function handleModifyPageNumber(id, number) {
    let items = Array.from(criterias)
    items[id]["more_infos_page_number"] = number
    await criteriasApi.modifyPageNumber(user.admin_id, items[id]["id"], number)
    updateCriterias(items);
  }

  async function handleModifyInclude(id, include) {
    let items = Array.from(criterias)
    items[id]["include"] = include
    await criteriasApi.modifyInclude(user.admin_id, items[id]["id"], include)
    updateCriterias(items);
  }

  async function handleModifyCriteriaSkill(id, skillId) {
    let items = Array.from(criterias)
    if (skillId === 'Aucune') skillId = null
    items[id]["skills_id"] = skillId
    await criteriasApi.modifySkill(user.admin_id, items[id]["id"], skillId)
    updateCriterias(items);
  }

  async function handleDoubleClickObjectives(id, state) {
    let items = Array.from(objectives);
    if (state === false) {
      items[id]["description"] = modifyObjective
      await objectivesApi.modifyNameObjective(user.admin_id, modifyObjective, items[id]["id"])
    }
    items[id]["edited"] = state
    updateObjectives(items);
  }

  async function handleDeleteButton(id) {
    let items = Array.from(criterias);
    let id_bdd = items.splice(id, 1)[0]["id"];
    await criteriasApi.deleteCriteria(user.admin_id, id_bdd)
    updateCriterias(items);
  }

  async function handleDeleteButtonSkill(id) {
    let items = Array.from(skills);
    let skill_id = items.splice(id, 1)[0]["id"];
    await skillsApi.deleteSkill(user.admin_id, skill_id)
    updateSkills(items);
  }

  async function handleDeleteButtonObjective(id) {
    let items = Array.from(objectives);
    let id_bdd = items.splice(id, 1)[0]["id"];
    await objectivesApi.deleteObjective(user.admin_id, id_bdd)
    updateObjectives(items);
  }

  async function handleChangePlace(event, id, index) {
    let items = Array.from(objectives)
    items[index]["place"] = event.target.value
    items.sort(function(a,b) {
      return a.place - b.place
    })
    await objectivesApi.changePlace(user.admin_id, id, event.target.value)
    updateObjectives(items)
  }

  useEffect(() => {
    getGraphics();
    getAuditsDetails();
    gtm.push({ event: 'page_view' });
  }, []);


  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          mx: 3
        }}
      >
        <Box sx={{ mb: 4 }}>
            <RouterLink
              to="/dashboard/audits"
              style={{ textDecoration: 'none' }}
            >
              <Link
                color="textPrimary"
                component="a"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mt: 2
                }}
              >
                <ArrowBackIcon
                  fontSize="small"
                  sx={{ mr: 1 }}
                />
                <Typography variant="subtitle2">
                  Audits
                </Typography>
              </Link>
            </RouterLink>
          </Box>
        {loadingStats ? 
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            height={500}
          >
            <CircularProgress size={150}/>
          </Grid> : 
          (<>
        <div>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid
              item
            >
              <div>
              {modify.audit_name ?
                  <TextField
                  label='Nom de la formation'
                  focused
                  fullWidth
                  autoFocus
                  defaultValue={dataGraphics.audit_name}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleModify("audit_name");
                    }
                  }}
                  onBlur={() => {
                    handleModify("audit_name");
                  }}
                  onChange={(e) => setModifyText(e.target.value)}
                  />
                  :
                  <Typography
                  variant='h4'
                  onDoubleClick={() => {setModify(modify => ({
                  ...modify,
                  audit_name: true}));
                  setModifyText(dataGraphics.audit_name)
                }}
                >
                  {dataGraphics.audit_name}
                </Typography>
                }
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="subtitle2">
                    Id de l&apos;audit:
                  </Typography>
                  <Chip
                    label={auditId}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
          <Card 
            sx={{
              m:2,
              p:2
            }}
          >
            {modify.syna_formation_id ?
            <TextField
            label='Id de la formation Syna'
            focused
            fullWidth
            autoFocus
            defaultValue={dataGraphics.syna_formation_id}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleModify("syna_formation_id");
              }
            }}
            onBlur={() => {
              handleModify("syna_formation_id");
            }}
            onChange={(e) => setModifyText(e.target.value)}
            />
            : // ELSE
            <Typography
              onDoubleClick={() => {setModify(modify => ({
              ...modify,
              syna_formation_id: true}));
              setModifyText(dataGraphics.syna_formation_id)
            }}
            >
              <span style={{marginRight: '3.5em'}}> Id(s) de la formation Syna :</span>
              {dataGraphics.syna_formation_id}
            </Typography>
            }
          </Card>
          <Card 
            sx={{
              m:2,
              p:2
            }}
          >
            {modify.pdf_link ?
            <TextField
            label='Lien de la formation pdf'
            focused
            fullWidth
            autoFocus
            defaultValue={dataGraphics.pdf_link}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleModify("pdf_link");
              }
            }}
            onBlur={() => {
              handleModify("pdf_link");
            }}
            onChange={(e) => setModifyText(e.target.value)}
            />
            : // ELSE
            <Typography
              onDoubleClick={() => {setModify(modify => ({
              ...modify,
              pdf_link: true}));
              setModifyText(dataGraphics.pdf_link)
            }}
            >
              <span style={{marginRight: '4.1em'}}>Lien de la formation pdf :</span>
              {dataGraphics.pdf_link}
            </Typography>
            }
          </Card>
          <Card 
            sx={{
              m:2,
              p:2
            }}
          >
            {modify.image_link ?
            <TextField
            label="Lien de l'image de la formation"
            focused
            fullWidth
            autoFocus
            defaultValue={dataGraphics.image_link}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleModify("image_link");
              }
            }}
            onBlur={() => {
              handleModify("image_link");
            }}
            onChange={(e) => setModifyText(e.target.value)}
            />
            : // ELSE
            <Typography
              onDoubleClick={() => {setModify(modify => ({
              ...modify,
              image_link: true}));
              setModifyText(dataGraphics.image_link)
            }}
            >
              <span style={{marginRight: '1em'}}>Lien de l'image de la formation :</span>
              {dataGraphics.image_link}
            </Typography>
            }
          </Card>
        </div>
        {(modifyAudit === false?
          <div>
            {<Grid
              item
              sx={{ mt: 2, mr: 4}}
              display= 'flex'
              justifyContent="flex-end"
            > 
              <Button
                variant="contained"
                onClick={() => {
                  setModifyAudit(true)
                }}
              >
                Modifier
              </Button>
            </Grid>}
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
              >
              {tabs.map((tab) => (
                <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                />
                ))}
            </Tabs>
            <Divider />
            <Box sx={{ mt: 3 }}>
              {currentTab === 'details' && (
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <AuditsDetails
                      result = {auditsDetails}
                      showSkillCriterias = {showSkillCriterias}
                      setShowSkillCriterias = {setShowSkillCriterias}
                    />
                  </Grid>
                </Grid>
              )}
              {currentTab === 'statistiques' && (
                loadingStats ?
                <CircularProgress
                  color="inherit"
                  size={50}
                />
                : // ELSE
                <>
                {dataGraphics.result.length > 0 ? (dataGraphics.result.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    spacing={5}
                  >
                    <Grid
                      item
                      xs={12}
                      marginBottom={3}
                    >
                    <AuditsGraphics
                      result={item}
                    />
                    
                    </Grid>
                  </Grid>
                ))) : (
                  <>
                    <Typography>
                      Pas de statistiques pour cet audit 
                    </Typography>
                  </>
                )}
                </>
              )}
               {currentTab === 'satisfaction' && (
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <SatisfactionSection
                      auditId={auditId}
                      adminId={user.admin_id} 
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </div>
        : // else PAGE MODIFIER
        <div>
        <Grid
          item
          sx={{ mt: 2, mr: 4}}
          display= 'flex'
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            onClick={() => {
              getAuditsDetails()
              setModifyAudit(false)
            }}
          >
            Valider
          </Button>
        </Grid>
        <Grid>
          <Typography variant="h4">
            Critères
          </Typography>
          <Divider/>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="criterias">
              {(provided) => (
                <ul 
                  className="criterias"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {criterias.map(({id, criteria, edited, more_infos_page_number, include, skills : skill_id}, index) => {
                    return (
                      <Draggable 
                        key={id} 
                        draggableId={id.toString()} 
                        index={index}
                        isDragDisabled={edited === true}
                      >
                        {(provided) => (
                          (edited === true ?
                            <TextField 
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                m: 1,
                                mr: 0,
                                border: 2,
                                borderColor: 'primary.main',
                                borderRadius: '10px',
                                backgroundColor: 'white',
                                width: "93%"
                              }}
                              focused
                              autoFocus
                              defaultValue={criteria}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Numéro {index + 1} </InputAdornment>,
                              }}
                              onFocus={() => {
                                setModifyCriteria(criteria)
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleDoubleClick(index, false)
                                }
                              }}
                              onBlur={(e) => {
                                handleDoubleClick(index, false)
                              }}
                              onChange={(event) => {
                                setModifyCriteria(event.target.value)
                              }}
                            />
                            : //else DRAG AND DROP NORMAL
                            <Grid
                              sx={{
                                width:"95%",
                              }}
                            >
                              <Card
                                ref={provided.innerRef} 
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  m: 1,
                                  p: 1,
                                  width:"100%",
                                }}
                              >
                                <Grid container
                                  sx={{
                                    display:'flex',
                                  }}
                                >
                                  <Grid item
sm={7}
                                    sx={{
                                      width:'100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                      px: 2
                                    }}
                                    onDoubleClick={() => {
                                      handleDoubleClick(index, true)
                                    }}
                                  >
                                    <Typography>
                                      { "Numéro : " + (index + 1) + " " + criteria}
                                    </Typography>
                                  </Grid>
                                  <Grid container sm={4} spacing={1} sx={{ my: 2 }}>
                                    <Grid 
                                      item 
                                      sm={3}
                                    >
                                      <TextField
                                      label="Page n°"
                                      textAlign={"center"}
                                      type="number"
                                      value={more_infos_page_number}
                                      onChange={(event) => {
                                        handleModifyPageNumber(index, event.target.value)
                                      }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={9}
                                      // style={{ marginLeft: '10px' }}
                                    >
                                      <TextField
                                        label="Inclusion"
                                        name="choice"
                                        onChange={(event) => {handleModifyInclude(index, event.target.value)}}
                                        select
                                        SelectProps={{ native: true }}
                                        value={include}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      >
                                        {choiceOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={12}
                                      sx={{ mt: 1 }}
                                      // style={{ marginLeft: '10px' }}
                                    >
                                      <TextField
                                        label="Compétence"
                                        name="choice"
                                        onChange={(event) => {handleModifyCriteriaSkill(index, event.target.value)}}
                                        select
                                        SelectProps={{ native: true }}
                                        defaultValue={skill_id}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      >
                                        <option 
                                          key='default' 
                                          value={null}
                                        >
                                          Aucune
                                        </option>
                                        {skills.map((skill) => (
                                          <option
                                            key={skill.id}
                                            value={skill.id}
                                          >
                                            {skill.skill}
                                          </option>
                                        ))}
                                      </TextField>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item 
                                    sm={1}
                                  >
                                    <Box textAlign={"right"}>
                                      <Button sx={{
                                        textAlign:"right"
                                      }}
                                      onClick={() => {
                                        handleDeleteButton(index)
                                      }}
                                      >
                                        <DeleteOutlineIcon/>
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          )
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
        </DragDropContext>
        </Grid>
        <Grid>
          <Typography 
            variant="h4" 
            sx={{ mt: 4 }}
          >
            Objectifs
          </Typography>
          <Divider
            sx={{
              mb: 2,
            }}
          />
          {objectives.map((objective, index) => (
            (objective.edited === true ?
              <TextField
                key={index}
                sx={{
                  m: 1,
                  mr: 0,
                  ml: 6,
                  border: 2,
                  borderColor: 'primary.main',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  width: "93%"
                }}
                focused
                autoFocus
                defaultValue={objective.description}
                onFocus={() => {
                  setModifyObjective(objective.description)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleDoubleClickObjectives(index, false)
                  }
                }}
                onBlur={(e) => {
                  handleDoubleClickObjectives(index, false)
                }}
                onChange={(event) => {
                  setModifyObjective(event.target.value)
                }}
              />
            : // ELSE 
            <Grid
              key={index}
              sx={{
                width:"93%",
                ml: 5
              }}
            >
              <Card
                sx={{
                  m: 1,
                  p: 1,
                  width:"100%",
                }}
                onDoubleClick={() => {
                  handleDoubleClickObjectives(index, true)
                }}
              >
                <Grid
                  sx={{
                    display:'flex',
                  }}
                >
                  <Grid item
                    sx={{
                      width:'100%',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <InputLabel id="place">Place</InputLabel>
                    <Select
                      id="place"
                      label="Place"
                      defaultValue={objective.place}
                      value={objective.place}
                      onChange={(event) => handleChangePlace(event, objective.id, index)}
                    >
                      {criterias.map((item, index) => (
                        <MenuItem
                          value={item.number}
                            ey={index}
                            disabled={objectives.map((el) => el.place).indexOf(item.number) !== -1}>
                          {item.number}
                        </MenuItem>
                        // )
                      ))}
                    </Select>
                    <Typography>
                      {objective.description}
                    </Typography>
                   </Grid>
                   <Grid item>
                      <Button
                        onClick={() => {
                          handleDeleteButtonObjective(index)
                        }}
                      >
                      <DeleteOutlineIcon/>
                     </Button>
                   </Grid>
                 </Grid>
              </Card>
            </Grid>
          )))}
        </Grid>
        <Grid sx={{ mb: 4 }}>
          <Typography 
            variant="h4"
            sx={{ mt: 4 }}
          >
            Compétences
          </Typography>
          <Divider/>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="skills">
              {(provided) => (
                <ul 
                  className="skills"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {skills?.map(({id, skill, edited}, index) => {
                    return (
                      <Draggable 
                        key={id} 
                        draggableId={id.toString()} 
                        index={index}
                        isDragDisabled={true}
                      >
                        {(provided) => (
                          (edited === true ?
                            <TextField 
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                m: 1,
                                mr: 0,
                                border: 2,
                                borderColor: 'primary.main',
                                borderRadius: '10px',
                                backgroundColor: 'white',
                                width: "93%"
                              }}
                              focused
                              autoFocus
                              defaultValue={skill}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Numéro {index + 1} </InputAdornment>,
                              }}
                              onFocus={() => {
                                setModifySkills(skill)
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleDoubleClickSkill(index, false)
                                }
                              }}
                              onBlur={(e) => {
                                handleDoubleClickSkill(index, false)
                              }}
                              onChange={(event) => {
                                setModifySkills(event.target.value)
                              }}
                            />
                            : //else DRAG AND DROP NORMAL
                            <Grid
                              sx={{
                                width:"95%",
                              }}
                            >
                              <Card
                                ref={provided.innerRef} 
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  m: 1,
                                  p: 1,
                                  width:"100%",
                                }}
                              >
                                <Grid container
                                  sx={{
                                    display:'flex',
                                  }}
                                >
                                  <Grid 
                                    item 
                                    sm={7}
                                    sx={{
                                      width:'100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    onDoubleClick={() => {
                                      handleDoubleClickSkill(index, true)
                                    }}
                                  >
                                    <Typography>
                                      { "Numéro : " + (index + 1) + " " + skill}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item 
                                    sm={1}
                                  >
                                    <Box textAlign={"right"}>
                                      <Button sx={{
                                        textAlign:"right"
                                      }}
                                      onClick={() => {
                                        handleDeleteButtonSkill(index)
                                      }}
                                      >
                                        <DeleteOutlineIcon/>
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          )
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
        </DragDropContext>
        </Grid>
        <Box 
          sx={{ 
            position: 'fixed', 
            bottom: 30, 
            top: 'auto', 
            width: '80%',
            display: 'flex',
            gap: 2,
            justifyContent: 'end',
            pr: '1rem',
            zIndex: 1
          }}
        >
          <Fab
          sx={{
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 6px 7px -3px",
          }}
          size="large"
          variant="extended"
          color="primary"
          aria-label="add"
          onClick={() => handleAddCriteria()}
          >
            <AddIcon />
            Critère
          </Fab>
        
          <Fab
            sx={{
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 6px 7px -3px",
            }}
            size="large"
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={() => handleAddObjective()}
          >
            <AddIcon />
            Objectif
          </Fab>
          <Fab
            sx={{
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 6px 7px -3px",
            }}
            size="large"
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={() => handleAddSkill()}
          >
            <AddIcon />
            Compétence
          </Fab>
        </Box>
        
        </div>
        )}
        </>)
        }
      </Box>
    </>
  );
};

export default AuditsGraphicsDetails;
