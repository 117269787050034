import React, { useEffect, useState } from "react";
import { Card, CardHeader, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chart from "react-apexcharts";
import { scoresApi } from "../../../api/scores-api";
import { useAuth } from "../../../hooks/use-auth";
import { ca } from "date-fns/locale";

const items = [
  {
    color: "#4CAF50",
    label: "Score du formateur",
    subtitle: "Excellent",
    value: 85,
  },
  {
    color: "#FF9800",
    label: "Score de similarité",
    subtitle: "Bonne condition",
    value: 10,
  },
  {
    color: "#F44336",
    label: "Score de perplexité ",
    subtitle: "Mauvaise condition",
    value: 5,
  },
  {
    color: "#B22222",
    label: "Moyenne des scores ",
    subtitle: "............",
    value: 5,
  },
];

const MeanScores = (props) => {
  const theme = useTheme();
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const comment = (score) => {
    if (parseInt(score) >= 85) return "Excellent"
    else if (parseInt(score) >= 70) return "Très Fiable"
    else if (parseInt(score) >= 50) return "Fiable"
    else if (parseInt(score) >= 30) return "Assez Fiable"
    else return "Peu Fiable";
  };

  const avg = (obj) => {
    let sum = 0;
    let count = 0;
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            sum += parseFloat(obj[key]);
            count++;
        }
    }
    return count === 0 ? 0 : sum / count;
  };

  const percentage = (score) => (score / 5) * 100;

  const chartOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: ["......"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            color: theme.palette.text.secondary,
            fontSize: "12px",
            fontWeight: 400,
            offsetY: 20,
          },
          value: {
            color: theme.palette.text.primary,
            fontSize: "18px",
            fontWeight: 600,
            offsetY: -20,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          background: theme.palette.background.default,
        },
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await scoresApi.getScoresMeans(auth.user.admin_id);
      console.log(comment(response.scores_means.avg_score_formateur));
      const _data = [
        {
          color: "#4CAF50",
          label: "Score du formateur",
          subtitle: comment(percentage(response.scores_means.avg_score_formateur)),
          value: percentage(response.scores_means.avg_score_formateur).toFixed(2),
        },
        {
          color: "#FF9800",
          label: "Score de similarité",
          subtitle: comment(percentage(response.scores_means.avg_score_fiability)),
          value: percentage(response.scores_means.avg_score_fiability).toFixed(2),
        },
        {
          color: "#F44336",
          label: "Score de perplexité ",
          subtitle: comment(percentage(response.scores_means.avg_score_perplexity)),
          value: percentage(response.scores_means.avg_score_perplexity).toFixed(2),
        },
        {
          color: "#B22222",
          label: "Moyenne des scores ",
          subtitle: comment(percentage(avg(response.scores_means).toFixed(2))),
          value: percentage(avg(response.scores_means)).toFixed(2),
        },
      ];
      setData(_data);
    };

    fetchData();
  }, [])

  useEffect(() => {
    console.log(data)
  }, [data])

  return (
    <Card {...props}>
      <CardHeader title="Moyennes des scores" />
      <Divider />
      <Grid container spacing={2} sx={{ p: 3 }}>
        {/* {data.length === 0 && (
          <>
            <Grid item md={3} xs={12}>
              <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: 1 }} />
            </Grid>
          </>
        )} */}
        {data && data.map((item) => (
          <Grid item key={item.label} md={3} xs={12}>
            <Card
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                p: 2,
              }}
              variant="outlined"
            >
              <Typography sx={{ color: item.color }} variant="h6">
                {item.label}
              </Typography>
              <Chart
                height={200}
                options={{
                  ...chartOptions,
                  colors: [item.color],
                }}
                series={[item.value]}
                type="radialBar"
              />
              {/* <Typography variant="h6">{item.value}</Typography> */}
              <Typography color="textSecondary" variant="h6">
                {item.subtitle}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default MeanScores;
