import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { SeverityPill } from "../../../components/severity-pill";
import { gtm } from "../../../lib/gtm";

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Scrollbar } from "../../../components/scrollbar";
import { useMounted } from "../../../hooks/use-mounted";
import SearchIcon from "@mui/icons-material/Search";

import { Link as RouterLink } from "react-router-dom";
import { FormersApi } from "../../../api/formateur-api";

const sortOptions = [
  {
    label: "Fin de session plus proche",
    value: "end_session_order|asc",
  },
  {
    label: "Fin de session plus éloignée",
    value: "end_session_order|desc",
  },
];

const FormateurHome = () => {
  const isMounted = useMounted();
  const [formers, setFormers] = useState([]);
  const [filteredFormers, setFilteredFormers] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const [sort, setSort] = useState("end_session_order|desc");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const { user } = useAuth();

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
    setFilteredFormers(
      formers.filter((el) =>
        `${el.firstname}`
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    );
    setPage(0);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getFormers = useCallback(async () => {
    try {
      const data = await FormersApi.getAllFormers(user.admin_id);
      if (isMounted()) {
        setFormers(data);
        setFilteredFormers(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const applySort = (customers, sort) => {
    const [orderBy, order] = sort.split("|");
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = customers.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) {
        return newOrder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const applyPagination = (formers, page, limit) =>
    formers.slice(page * limit, page * limit + limit);

  const sortedFormers = applySort(filteredFormers, sort);
  const paginatedFormers = applyPagination(sortedFormers, page, limit);

  useEffect(() => {
    getFormers();
    gtm.push({ event: "page_view" });
  }, [getFormers]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
        }}
        // style={{ backgroundImage: `linear-gradient(to right, rgba(0, 224, 255, 1), rgba(0, 133, 255, 1))`, }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#F5F6FC" : "#1c2531",
                    fontFamily: "Montserrat",
                  }}
                  variant="h4"
                >
                  Liste des formateurs
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: -1,
                p: 3,
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: "100%",
                  width: 450,
                }}
              >
                <TextField
                  value={searchName}
                  onChange={handleChangeSearch}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Chercher un formateur"
                />
              </Box>
            </Box>
            <Scrollbar>
              <Table sx={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ p: 2 }}>
                      <Typography
                        sx={{ mx: 3 }}
                        //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                        fontWeight="Bold"
                        fontSize={13}
                      >
                        Nom du formateur
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                        fontWeight="Bold"
                        fontSize={13}
                      >
                        email
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedFormers ? (
                    paginatedFormers.map((item, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box sx={{ ml: 2 }}>
                              <RouterLink
                                to={`/dashboard/formateur/${item.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  color={(theme) =>
                                    theme.palette.mode === "dark"
                                      ? process.env.REACT_APP_SECONDARY_COLOR
                                      : process.env.REACT_APP_PRIMARY_COLOR
                                  }
                                  fontWeight="Bold"
                                  fontSize={15}
                                  // variant="body1"
                                >
                                  {item.lastname} {item.firstname}
                                </Typography>
                              </RouterLink>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box sx={{ ml: 2 }}>
                              <Typography
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#F5F6FC"
                                    : process.env.REACT_APP_PRIMARY_COLOR
                                }
                                fontSize={18}
                                // variant="body1"
                              >
                                {item.email}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography> Pas de formateurs</Typography>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
            <TablePagination
              component="div"
              count={filteredFormers.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              labelRowsPerPage="Lignes par page :"
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default FormateurHome;
