import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  CardContent,
} from "@mui/material";
import { useEffect } from "react";

export const RecapFirstTurn = (props) => {
  const {
    dataFirstTurn,
    sessionAnalyseFirstTurn,
    globalAnalyseFirstTurn,
    turn,
    resultData,
    selectedCriteria,
    setSelectedCriteria,
  } = props;

  const handleSelectCriteria = (criteria) => {
    setSelectedCriteria(criteria);
  };

  return (
    <>
      <Card sx={{ borderRadius: 1, mt: 2 }}>
        <CardContent>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
          sx={{ mt: 1, mb: 1, ml: 2 }}
        >
          Analyse Récapitulative
        </Typography>
          <Divider sx={{ my: 2 }} />

          {resultData ? (
            <Box>
              <Grid
                container
                display="flex"
                spacing={2}
                marginTop={2}
                sx={{ justifyContent: "flex-start" }}
              >
                {/* Affichage des critères */}
                <Grid
                  item
                  xs={12} md={3} lg={3} 
                  sx={{
                    // overflowY: "auto",
                    // maxHeight: "300px",
                    // border: "1px solid #ccc",
                    // borderRadius: 2,
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={(theme) =>
                      theme.palette.mode === "dark"
                        ? "white"
                        : process.env.REACT_APP_PRIMARY_COLOR
                    }
                  >
                    Liste des Critères
                  </Typography>
                  <Box sx={{ margin: 1 }}>
                    {turn === 1 &&
                      (dataFirstTurn.length !== 0 && dataFirstTurn.criterias ? (
                        <Grid
                          container
                          display="flex"
                          spacing={2}
                          marginTop={2}
                          sx={{ justifyContent: "flex-start" }}
                        >
                        {/* Affichage des critères */}
                          { dataFirstTurn.criterias.map((criteria, index) => (
                          <Grid
                            item
                            xs={6} md={6} lg={6}
                          >
                            <Box
                              key={index}
                              sx={{
                                backgroundColor:
                                  selectedCriteria === criteria
                                    ? "rgba(0, 0, 0, 0.1)"
                                    : "inherit",
                              }}
                            >
                              <Button
                                onClick={() => handleSelectCriteria(criteria)}
                              >
                                Critère {index + 1}
                              </Button>
                              <Divider />
                            </Box>
                            </Grid>
                          ))}
                          </Grid>
                        ) : (
                        <Typography>
                          Pas de données pour le premier tour
                        </Typography>
                      ))}
                  </Box>
                </Grid>

                {/* Affichage des détails du critère sélectionné Tour 1 */}
                {selectedCriteria && turn === 1 && (
                  <Grid item xs={12} md={9} lg={9} >
                    <Box sx={{ margin: 1 }}>
                      <Typography
                        variant="h6"
                        color={(theme) =>
                          theme.palette.mode === "dark"
                            ? "white"
                            : process.env.REACT_APP_PRIMARY_COLOR
                        }
                      >
                        Nom du critère : 
                      </Typography>
                      <Typography>
                        {selectedCriteria.personal.criteria}
                      </Typography>
                      <Typography variant="h6">Réponses :</Typography>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          margin: 1,
                          marginTop: 2,
                        }}
                      >
                        {/* Affichage des barres de progression par apprenant */}
                        <Box
                          sx={{
                            backgroundColor: "#f0f0f0",
                            width: "30%",
                          }}
                          borderRadius="1px"
                          p={2}
                        >
                          <Typography variant="subtitle1">
                            Apprenant :
                          </Typography>
                          <Typography
                            sx={{ color: process.env.REACT_APP_YES_COLOR }}
                          >
                            OUI : {selectedCriteria.personal.oui} %
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={selectedCriteria.personal.oui}
                            sx={{
                              height: "20px",
                              borderRadius: "3px",
                              "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor:
                                  process.env.REACT_APP_YES_COLOR,
                              },
                            }}
                          />
                          <Typography
                            sx={{ color: process.env.REACT_APP_NO_COLOR }}
                          >
                            NON : {selectedCriteria.personal.non} %
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={selectedCriteria.personal.non}
                            sx={{
                              height: "20px",
                              borderRadius: "3px",
                              "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor:
                                  process.env.REACT_APP_NO_COLOR,
                              },
                            }}
                          />
                          <Typography
                            sx={{ color: process.env.REACT_APP_PNC_COLOR }}
                          >
                            NA : {selectedCriteria.personal.na} %
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={selectedCriteria.personal.na}
                            sx={{
                              height: "20px",
                              borderRadius: "3px",
                              "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor:
                                  process.env.REACT_APP_PNC_COLOR,
                              },
                            }}
                          />
                        </Box>
                        {/* Affichage des barres de progression par session */}
                        {sessionAnalyseFirstTurn.results &&
                          sessionAnalyseFirstTurn.results.map(
                            (result) =>
                              result.number ===
                                selectedCriteria?.number_criteria && (
                                <Box
                                  key={result.id}
                                  sx={{
                                    backgroundColor: "#f0f0f0",
                                    width: "30%",
                                  }}
                                  borderRadius="1px"
                                  p={2}
                                >
                                  <Typography variant="subtitle1">
                                    Session :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: process.env.REACT_APP_YES_COLOR,
                                    }}
                                  >
                                    OUI : {result.oui} %
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={result.oui}
                                    sx={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      "& .MuiLinearProgress-barColorPrimary":
                                        {
                                          backgroundColor:
                                            process.env.REACT_APP_YES_COLOR,
                                        },
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: process.env.REACT_APP_NO_COLOR,
                                    }}
                                  >
                                    NON : {result.non} %
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={result.non}
                                    sx={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      "& .MuiLinearProgress-barColorPrimary":
                                        {
                                          backgroundColor:
                                            process.env.REACT_APP_NO_COLOR,
                                        },
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: process.env.REACT_APP_PNC_COLOR,
                                    }}
                                  >
                                    NA : {result.na} %
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={result.na}
                                    sx={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      "& .MuiLinearProgress-barColorPrimary":
                                        {
                                          backgroundColor:
                                            process.env.REACT_APP_PNC_COLOR,
                                        },
                                    }}
                                  />
                                </Box>
                              )
                          )}
                        {/* Affichage des barres de progression pour toutes les sessions */}
                        {globalAnalyseFirstTurn.results &&
                          globalAnalyseFirstTurn.results.map(
                            (result) =>
                              result.number ===
                                selectedCriteria?.number_criteria && (
                                <Box
                                  key={result.id}
                                  sx={{
                                    backgroundColor: "#f0f0f0",
                                    width: "30%",
                                  }}
                                  borderRadius="1px"
                                  p={2}
                                >
                                  <Typography variant="subtitle1">
                                    Total :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: process.env.REACT_APP_YES_COLOR,
                                    }}
                                  >
                                    OUI : {result.oui} %
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={result.oui}
                                    sx={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      "& .MuiLinearProgress-barColorPrimary":
                                        {
                                          backgroundColor:
                                            process.env.REACT_APP_YES_COLOR,
                                        },
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: process.env.REACT_APP_NO_COLOR,
                                    }}
                                  >
                                    NON : {result.non} %
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={result.non}
                                    sx={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      "& .MuiLinearProgress-barColorPrimary":
                                        {
                                          backgroundColor:
                                            process.env.REACT_APP_NO_COLOR,
                                        },
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: process.env.REACT_APP_PNC_COLOR,
                                    }}
                                  >
                                    NA : {result.na} %
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={result.na}
                                    sx={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      "& .MuiLinearProgress-barColorPrimary":
                                        {
                                          backgroundColor:
                                            process.env.REACT_APP_PNC_COLOR,
                                        },
                                    }}
                                  />
                                </Box>
                              )
                          )}
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : (
            <Typography>
              {" "}
              Récapitulatif pas disponible pour le moment{" "}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};
