import { useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import { AuditsList } from '../../../components/dashboard/audits/audits-list';

import { gtm } from '../../../lib/gtm';
import { patientsFolderApi } from '../../../api/patients_folder-api';
import { PatientsFolderList } from '../../../components/dashboard/patients-folder/patients-folder-list';


const PatientsFolder = () => {
  const isMounted = useMounted();
  const [patientsFolder, setPatientsFolder] = useState([])


  const { user } = useAuth(); 
  const getAllPatientsFolder = useCallback(async () => {
    try {
      const data = await patientsFolderApi.getAllPatientsFolder(user.admin_id)
      if (isMounted()) {
        setPatientsFolder(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getAllPatientsFolder();
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4" sx={{fontFamily: "Montserrat"}}>
                  Dossiers Patients
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                <PatientsFolderList
                  patientsFolder = {patientsFolder}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};


export default PatientsFolder;
