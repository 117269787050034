class SessionsApi {
  async createSession(admin_id, start_date, end_date, data) {
    const url = `${process.env.REACT_APP_BACK}/api/session/create/`;
    const values = {
      admin_id: admin_id,
      session_number: data["session_number"],
      start_date: start_date,
      end_date: end_date,
      formations_id: data["formation"],
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getSessionsByAuditId(admin_id, audits_id) {
    const url = `${process.env.REACT_APP_BACK}/api/session/getAllById/`;
    const values = {
      admin_id: admin_id,
      audits_id: audits_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getAllSessions(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/api/session/getAll/`;
    const values = {
      admin_id: admin_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getResultatsBySessionEtApprenant(
    admin_id,
    auditId,
    turn,
    start_session,
    end_session
  ) {
    const url = `${process.env.REACT_APP_BACK}/api/resutsByApprenants/`;
    const values = {
      admin_id: admin_id,
      audit_id: auditId,
      turn: turn,
      start_session: start_session,
      end_session: end_session,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getCriteresResultsMoyenneBySession(
    admin_id,
    auditId,
    turn,
    start_session,
    end_session
  ) {
    const url = `${process.env.REACT_APP_BACK}/api/moyennesSessionByCriteres/`;
    const values = {
      admin_id: admin_id,
      audit_id: auditId,
      turn: turn,
      start_session: start_session,
      end_session: end_session,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getCriteresResultsMoyenneByAllSession(admin_id, auditId, turn) {
    const url = `${process.env.REACT_APP_BACK}/api/moyennesAllSessionByCriteres/`;
    const values = {
      admin_id: admin_id,
      audit_id: auditId,
      turn: turn,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getPointsAmeliorationssBySession(
    admin_id,
    auditId,
    turn,
    start_session,
    end_session
  ) {
    const url = `${process.env.REACT_APP_BACK}/api/pointsAmeliorationsByApprenants/`;
    const values = {
      admin_id: admin_id,
      audit_id: auditId,
      turn: turn,
      start_session: start_session,
      end_session: end_session,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getAllAvailableSessions(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/api/sessions/getAll/`;
    const values = {
      admin_id: admin_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getSessionByStartDate(admin_id, start_date) {
    const url = `${process.env.REACT_APP_BACK}/api/sessions/get-sessions-by-start-date/`;
    const values = {
      admin_id: admin_id,
      start_date : start_date
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getTrainerBySession(admin_id, session_id) {
    const url = `${process.env.REACT_APP_BACK}/api/session/get-trainer/`;
    const values = {
      admin_id: admin_id,
      session_id : session_id
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
}

export const sessionsApi = new SessionsApi();
