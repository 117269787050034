import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Card, CardHeader, Divider, Link, List, ListItem, ListItemText, Skeleton, TextField, Typography } from '@mui/material';

import { useAuth } from '../../../hooks/use-auth';

import { userApi } from '../../../api/user-api';

export const CustomerBasicDetails = (props) => {
  let { customer, setCustomer } = props;
  
  const { user } = useAuth(); 

  const [modify, setModify] = useState({
    "firstname": null,
    "lastname": null,
    "email": null,
    "tlms_id": null,
    "syna_id": null
  });
  const [modifyText, setModifyText] = useState("")

  function handleClick(field, value) {
    setModify(modify => ({
      ...modify,
      [field]: true})
    );
    setModifyText(value);
  }
  async function handleModify(field) {
    setModify(modify => ({
      ...modify,
      [field]: false})
    );
    let changeCustomer = { ...customer };
    changeCustomer.details[field] = modifyText;
    setCustomer(changeCustomer);
    await userApi.modifyUser(user.admin_id, changeCustomer);
  }

  return (
    <>
      {customer === null 
      ? (
        <Box sx={{ mb: 3 }}>
          <Skeleton  sx={{ height: 150, mb: -2 }}/>
          <Divider sx={{ mt: 2 }}/>
          <Skeleton  sx={{ height: 60, mb: -2.5 }}/>
          <Skeleton  sx={{ height: 60, mb: -2.5 }}/>
          <Skeleton  sx={{ height: 60, mb: -2.5 }}/>
          <Skeleton  sx={{ height: 60, mb: -2.5 }}/>
        </Box>
      ) : (
        <Card>
          <CardHeader title="Informations"/>
          <Divider/>
          <List disablePadding>
            <ListItem
              sx={{
                px: 3,
                py: 1.5
              }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  my: 0
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                  >
                    Prénom
                  </Typography>
                }
                secondary={(
                  <Box
                    sx={{
                      flex: 1,
                      mt: 0,
                      display: "flex",
                      maxWidth: 1000
                    }}
                  >
                    {modify.firstname ?
                    <TextField
                      size='small'
                      focused
                      autoFocus
                      defaultValue={customer.details.firstname}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleModify("firstname");
                        }
                      }}
                      onBlur={() => {
                        handleModify("firstname");
                      }}
                      onChange={(e) => setModifyText(e.target.value)}
                    />
                    : // ELSE
                    <Typography
                      onDoubleClick={() => handleClick('firstname', customer.details.firstname)}
                      color="textSecondary"
                      variant="body2"
                    >
                      {customer.details.firstname}
                    </Typography>
                    }
                  </Box>
                )}
              />
            </ListItem>
            <Divider/>
            <ListItem
              sx={{
                px: 3,
                py: 1.5
              }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  my: 0
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                  >
                    Nom
                  </Typography>
                }
                secondary={(
                  <Box
                    sx={{
                      flex: 1,
                      mt: 0,
                      display: "flex",
                      maxWidth: 1000
                    }}
                  >
                    {modify.lastname ?
                    <TextField
                      size='small'
                      focused
                      autoFocus
                      defaultValue={customer.details.lastname}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleModify("lastname");
                        }
                      }}
                      onBlur={() => {
                        handleModify("lastname");
                      }}
                      onChange={(e) => setModifyText(e.target.value)}
                    />
                    : // ELSE
                    <Typography
                      onDoubleClick={() => handleClick('lastname', customer.details.lastname)}
                      color="textSecondary"
                      variant="body2"
                    >
                      {customer.details.lastname}
                    </Typography>
                    }
                  </Box>
                )}
              />
            </ListItem>
            <Divider/>
            <ListItem
              sx={{
                px: 3,
                py: 1.5
              }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  my: 0
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                  >
                    Email
                  </Typography>
                }
                secondary={(
                  <Box
                    sx={{
                      flex: 1,
                      mt: 0,
                      display: "flex",
                      maxWidth: 1000
                    }}
                  >
                    {modify.email ?
                    <TextField
                      size='small'
                      focused
                      autoFocus
                      defaultValue={customer.details.email}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleModify("email");
                        }
                      }}
                      onBlur={() => {
                        handleModify("email");
                      }}
                      onChange={(e) => setModifyText(e.target.value)}
                    />
                    : // ELSE
                    <Typography
                      onDoubleClick={() => handleClick('email', customer.details.email)}
                      color="textSecondary"
                      variant="body2"
                    >
                      {customer.details.email}
                    </Typography>
                    }
                  </Box>
                )}
              />
            </ListItem>
            <Divider/>
            {user.id_service === 0 && <><ListItem
              sx={{
                px: 3,
                py: 1.5
              }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  my: 0
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                  >
                    Tlms
                  </Typography>
                }
                secondary={(
                  <Box
                    sx={{
                      flex: 1,
                      mt: 0,
                      display: "flex",
                      maxWidth: 1000
                    }}
                    onDoubleClick={() => handleClick('tlms_id', customer.details.tlms_id)}
                  >
                    {modify.tlms_id ?
                    <TextField
                      size='small'
                      focused
                      autoFocus
                      defaultValue={customer.details.tlms_id}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleModify("tlms_id");
                        }
                      }}
                      onBlur={() => {
                        handleModify("tlms_id");
                      }}
                      onChange={(e) => setModifyText(e.target.value)}
                    />
                    : // ELSE
                    <Link
                      href={`${process.env.REACT_APP_TLMS_URL}/user/info/id:${customer.details.tlms_id}`}
                      target='_blank'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        >
                        {customer.details.tlms_id === 0 ? "Pas de compte TLMS" : customer.details.tlms_id}
                      </Typography>
                    </Link>
                    }
                  </Box>
                )}
              />
            </ListItem>
            <Divider/>
            <ListItem
              sx={{
                px: 3,
                py: 1.5
              }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  my: 0
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                  >
                    Syna
                  </Typography>
                }
                secondary={(
                  <Box
                    sx={{
                      flex: 1,
                      mt: 0,
                      display: "flex",
                      maxWidth: 1000
                    }}
                    onDoubleClick={() => handleClick('syna_id', customer.details.syna_id)}
                  >
                    {modify.syna_id ?
                    <TextField
                      size='small'
                      focused
                      autoFocus
                      defaultValue={customer.details.syna_id}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleModify("syna_id");
                        }
                      }}
                      onBlur={() => {
                        handleModify("syna_id");
                      }}
                      onChange={(e) => setModifyText(e.target.value)}
                    />
                    : // ELSE
                    <Link
                      href={`${process.env.REACT_APP_SYNA_URL}/dashboard/customers/${customer.details.syna_id}`}
                      target='_blank'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        >
                        {customer.details.syna_id === 0 ? "Pas de compte SYNA" : customer.details.syna_id}
                      </Typography>
                    </Link>
                    }
                  </Box>
                )}
              />
            </ListItem></>}
            <Divider/>
            <ListItem
              sx={{
                px: 3,
                py: 1.5
              }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  my: 0
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                  >
                    Dernière activité
                  </Typography>
                }
                secondary={(
                  <Box
                    sx={{
                      flex: 1,
                      mt: 0,
                      display: "flex",
                      maxWidth: 1000
                    }}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {customer.details.last_activity_type ? customer.details.last_activity_type + " le " + customer.details.last_activity_date : "Aucune activité"}
                    </Typography>
                  </Box>
                )}
              />
            </ListItem>
          </List>
        </Card>
      )}
    </>
  );
};

CustomerBasicDetails.propTypes = {
  name: PropTypes.string,
  user_id: PropTypes.number,
  email: PropTypes.string,
  tlms: PropTypes.number,
  syna: PropTypes.number
};
