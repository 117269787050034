import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { Box } from '@mui/material';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 19,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

const ContentContainer = styled('div')(({ theme }) => ({
  flex: '1', // Pour que le conteneur de contenu prenne tout l'espace disponible
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0, // Ajuster l'espacement à gauche pour les tailles d'écran plus petites
  },
}));

export const DashboardLayout = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <DashboardLayoutRoot>
      <DashboardSidebar
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <DashboardNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
    </DashboardLayoutRoot>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};
