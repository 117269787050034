// import { decode } from '../utils/jwt';
import jwt_decode from 'jwt-decode';

class AuthApi {
    async login(email, password) {
      const url = `${process.env.REACT_APP_BACK}/api/login_back_office/`
      const values = {
        "email": email,
        "password": password
      };
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json.jwt)
    }
    
    async me(accessToken) {
      try {
        // Decode access token
        var decoded = jwt_decode(accessToken);
        const url = `${process.env.REACT_APP_BACK}/api/admins_informations/get/`
        const resp = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({"admin_id": decoded.id}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        })
        const user = await resp.json();
        return new Promise((resolve, reject) => {
          if (!user.id) {
            reject(new Error('Invalid authorization token'));
            return;
          }
          resolve({
            admin_id: user.id,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            avatar: user.avatar,
            access_level: user.access_level,
            id_service: user.id_service,
            // Tableau avec la liste des EPP où l'utilisateur est formateur
            epps: user.epps
          });
        })
      } catch (err) {
        console.error('[Auth Api]: ', err);
        // reject(new Error('Internal server error'));
      };
  }
}
  
  export const authApi = new AuthApi();