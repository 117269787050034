import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { coursesApi } from "../../../api/course-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useCallback, useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { DeleteOutline } from "@mui/icons-material";
import Swal from "sweetalert2";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const CustomerResponseAudit = (props) => {
  const {
    open,
    handleClose,
    audit_id,
    customer_id,
    start_session,
    end_session,
  } = props;
  const isMounted = useMounted();
  const [answer, setAnswer] = useState([]);
  const { user } = useAuth();

  function handleDeletePatient(turn, index) {
    Swal.fire({
      title: "Voulez-vous vraiment supprimer le patient?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Annuler",
      denyButtonText: `Supprimer`,
      didOpen: (elem) => {
        const container = document.querySelector(".swal2-container");
        container.style.zIndex = "99999";
      },
    }).then(async (result) => {
      if (result.isDenied) {
        let item = [...answer];
        let patient_id = item[turn - 1]["result"].splice(index, 1)[0][
          "patient_id"
        ];
        setAnswer(item);
        await coursesApi.deletePatient(user.admin_id, patient_id);
        Swal.fire({
          title: "Patient supprimé",
          icon: "success",
          didOpen: (elem) => {
            const container = document.querySelector(".swal2-container");
            container.style.zIndex = "99999";
          },
        });
      }
    });
  }

  function handleModifyAnswer(turn, index, indexResult) {
    let item = [...answer];
    item[turn - 1]["result"][index]["answer"][indexResult]["modify"] =
      !item[turn - 1]["result"][index]["answer"][indexResult]["modify"];
    setAnswer(item);
  }

  async function changeAnswer(turn, index, indexResult, answerChange, id) {
    let item = [...answer];
    item[turn - 1]["result"][index]["answer"][indexResult]["answer"] =
      answerChange;
    setAnswer(item);
    await coursesApi.modifyAnswer(user.admin_id, id, answerChange);
  }

  function handleOpenCollapse(turn, index) {
    let item = Array.from(answer);
    item[turn - 1]["result"][index]["open"] =
      !item[turn - 1]["result"][index]["open"];
    setAnswer(item);
  }

  const getAnswer = useCallback(async () => {
    try {
      const data = await coursesApi.getAllAnswer(
        user.admin_id,
        audit_id,
        customer_id,
        start_session,
        end_session
      );
      if (isMounted()) {
        setAnswer(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isValidDate(date)) {
      return format(date, "dd/MM/yyyy", { locale: fr });
    } else {
      return "Date invalide";
    }
  };
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  useEffect(() => {
    getAnswer();
  }, []);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
        <DialogTitle variant="h2" align="center">
          Liste des réponses
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {answer &&
              answer.map((data, index) => (
                <Card key={index}>
                  <Grid marginBottom={2}>
                    <Typography variant="h3" color="secondary">
                      Tour : {data.turn}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h5">Résultats</Typography>
                  </Grid>
                  <Grid container display="flex">
                    {data.result[0] ? (
                      data.result.map((result, index) => (
                        <Grid item xs={4} key={index}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    handleOpenCollapse(data.turn, index)
                                  }
                                >
                                  {result.open ? (
                                    <KeyboardArrowDownIcon fontSize="large" />
                                  ) : (
                                    <KeyboardArrowRightIcon fontSize="large" />
                                  )}
                                  <Typography variant="h5">
                                    Patient numéro {result.patient_number}
                                  </Typography>
                                </Button>
                                {user.access_level < 2 ? (
                                  <IconButton
                                    sx={{ ml: 2 }}
                                    onClick={() =>
                                      handleDeletePatient(data.turn, index)
                                    }
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                }}
                              >
                                <Collapse
                                  in={result.open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box
                                    sx={{
                                      margin: 1,
                                    }}
                                  >
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              fontWeight: "bold",
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            {" "}
                                            Age{" "}
                                          </TableCell>
                                          <TableCell> {result.age} </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              fontWeight: "bold",
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            {" "}
                                            Sexe{" "}
                                          </TableCell>
                                          <TableCell> {result.sexe} </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              fontWeight: "bold",
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            {" "}
                                            Description{" "}
                                          </TableCell>
                                          <TableCell>
                                            {" "}
                                            {result.description}{" "}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                  <Box
                                    sx={{
                                      margin: 1,
                                    }}
                                  >
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell> Critère </TableCell>
                                          <TableCell> Réponse </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {result.answer.map(
                                          (criteria, indexResult) => (
                                            <TableRow key={indexResult}>
                                              <TableCell
                                                sx={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {indexResult + 1}
                                              </TableCell>
                                              <TableCell>
                                                {criteria.modify ? (
                                                  <Select
                                                    labelId="test"
                                                    label="Résultat"
                                                    value={criteria.answer}
                                                    onClose={() =>
                                                      handleModifyAnswer(
                                                        data.turn,
                                                        index,
                                                        indexResult
                                                      )
                                                    }
                                                    onChange={(e) =>
                                                      changeAnswer(
                                                        data.turn,
                                                        index,
                                                        indexResult,
                                                        e.target.value,
                                                        criteria.id
                                                      )
                                                    }
                                                  >
                                                    <MenuItem value={"Oui"}>
                                                      {" "}
                                                      Oui{" "}
                                                    </MenuItem>
                                                    <MenuItem value={"Non"}>
                                                      {" "}
                                                      Non{" "}
                                                    </MenuItem>
                                                    <MenuItem value={"PNC"}>
                                                      {" "}
                                                      NA{" "}
                                                    </MenuItem>
                                                  </Select>
                                                ) : (
                                                  <Typography
                                                    fontWeight={"bold"}
                                                    color={
                                                      criteria.answer === "Oui"
                                                        ? "green"
                                                        : criteria.answer ===
                                                          "Non"
                                                        ? "red"
                                                        : "black"
                                                    }
                                                    onDoubleClick={() =>
                                                      handleModifyAnswer(
                                                        data.turn,
                                                        index,
                                                        indexResult
                                                      )
                                                    }
                                                  >
                                                    {criteria.answer}
                                                  </Typography>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Grid marginTop={1} marginLeft={4} marginBottom={4}>
                        <Typography>Aucun résultat</Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid marginBottom={1}>
                    <Typography variant="h5">
                      Interprétation des résultats
                    </Typography>
                  </Grid>
                  <Grid marginLeft={4}>
                    {data.note.improvement_text ||
                    data.note.positive ||
                    data.note.why ? (
                      <Grid>
                        <Grid container justify="space-between">
                          <Typography inline fontWeight={"bold"} align="left">
                            Points forts :&nbsp;
                          </Typography>
                          <Typography inline variant="body1" align="right">
                            {data.note.positive}
                          </Typography>
                        </Grid>
                        <Grid container justify="space-between">
                          <Typography inline fontWeight={"bold"} align="left">
                            Points à améliorer :&nbsp;
                          </Typography>
                          <Typography inline variant="body1" align="right">
                            {data.note.improvement_text}
                          </Typography>
                        </Grid>
                        <Grid container justify="space-between">
                          <Typography inline fontWeight={"bold"} align="left">
                            Pourquoi :&nbsp;
                          </Typography>
                          <Typography inline variant="body1" align="right">
                            {data.note.why}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      "Aucune interprétation des résultats"
                    )}
                  </Grid>
                  {data.turn === 1 && (
                    <>
                      <Grid marginTop={4} marginBottom={1}>
                        <Typography variant="h5">
                          Objectifs d'améliorations
                        </Typography>
                      </Grid>
                      <Grid marginLeft={4} marginBottom={4}>
                        {data.improvements ? (
                          data.improvements.map((improvement, index) => (
                            <Grid key={index}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  marginTop={1}
                                  marginBottom={0.5}
                                  color="#1155EE"
                                >
                                  Objectif {index + 1} :
                                </Typography>
                              </Grid>
                              <Grid marginLeft={4}>
                                <Grid container justify="space-between">
                                  <Typography
                                    inline
                                    fontWeight={"bold"}
                                    align="left"
                                  >
                                    Commentaire :&nbsp;
                                  </Typography>
                                  <Typography
                                    inline
                                    variant="body1"
                                    align="right"
                                  >
                                    {improvement.comment}
                                  </Typography>
                                </Grid>
                                <Grid container justify="space-between">
                                  <Typography
                                    inline
                                    fontWeight={"bold"}
                                    align="left"
                                  >
                                    Objectif :&nbsp;
                                  </Typography>
                                  <Typography
                                    inline
                                    variant="body1"
                                    align="right"
                                  >
                                    {improvement.objective}
                                  </Typography>
                                </Grid>
                                <Grid container justify="space-between">
                                  <Typography
                                    inline
                                    fontWeight={"bold"}
                                    align="left"
                                  >
                                    Point à améliorer :&nbsp;
                                  </Typography>
                                  <Typography
                                    inline
                                    variant="body1"
                                    align="right"
                                  >
                                    {improvement.point}
                                  </Typography>
                                </Grid>
                                <Grid container justify="space-between">
                                  <Typography
                                    inline
                                    fontWeight={"bold"}
                                    align="left"
                                  >
                                    Stratégie :&nbsp;
                                  </Typography>
                                  <Typography
                                    inline
                                    variant="body1"
                                    align="right"
                                  >
                                    {improvement.strategy}
                                  </Typography>
                                </Grid>
                                <Grid container justify="space-between">
                                  <Typography
                                    inline
                                    fontWeight={"bold"}
                                    align="left"
                                  >
                                    Date limite :&nbsp;
                                  </Typography>
                                  <Typography
                                    inline
                                    variant="body1"
                                    align="right"
                                  >
                                    {formatDate(improvement.limit_date)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <Typography>Aucune amélioration</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Card>
              ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
