import { Box, Button, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export default function NotFound() {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const navigate = useNavigate()
    const auth = useAuth();

    const handleClick = () => {
        if (auth.isAuthenticated) {
          navigate("/dashboard")  
        } else {
          navigate("/")
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: windowWidth, height: windowHeight }}>
            <Box sx={{ overflow: 'hidden', display: 'inline-block', height: 250, mt: -20 }}>
            <Box
              component="img"
              sx={{
                  mt: -18,
                  width : '778px',
                  height: '500px',
              }}
              alt="Kalanke"
              src={process.env.REACT_APP_LOGO_SPLASH_SCREEN}
            />
          </Box>
            <Typography variant="h1" sx={{ mt: 5 }}>Page non trouvée</Typography>
            <Typography variant="h4" sx={{ fontWeight: 400 }}>La page que vous recherchez n'existe pas ou a été déplacée.</Typography>
            <Button size="large" sx={{ fontSize: 18, mt: 5 }} onClick={handleClick}>Revenir à la page d'accueil</Button>
        </Box>
    )
}
