import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chart from "react-apexcharts";

export const MeanAnswer = (props) => {
  const { answer, tour } = props;
  const theme = useTheme();
  const chartOptions = {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
    },
    colors: ["#13affe", "#fbab49"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      labels: {
        colors: theme.palette.text.secondary,
      },
      show: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        horizontal: true,
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: true,
        color: theme.palette.divider,
      },
      axisTicks: {
        show: true,
        color: theme.palette.divider,
      },
      categories: ["Oui", "Non", "Pnc"],
      labels: {
        style: {
          fontSize: "12px",
          colors: theme.palette.text.secondary,
        },
      },
      title: {
        text: "% de réponse",
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };

  const chartSeries = [
    {
      data: [answer["oui"], answer["non"], answer["na"]],
      name: "Pourcentage de réponse",
    },
  ];
  return (
    <Card>
      <Grid marginLeft={20}>
        <Typography display={"inline"} fontSize={18}>
          Moyenne de réponse du
        </Typography>
        <Typography display={"inline"} fontWeight={"bold"} fontSize={18}>
          &nbsp;{tour}&nbsp;
        </Typography>
        <Typography display={"inline"} fontSize={18}>
          tour
        </Typography>
      </Grid>
      <CardContent>
        <Chart
          height={300}
          options={chartOptions}
          series={chartSeries}
          type="bar"
        />
      </CardContent>
    </Card>
  );
};
