import { useState } from 'react';
import { Box, Card, CardHeader, Checkbox, Divider, Grid, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';


export const AvancementSessions = (props) => {
  const { progression } = props;
  const theme = useTheme();
  const [selectedSeries, setSelectedSeries] = useState([
    'Non commencé',
    'En cours',
    'Terminé'
  ]);
  
  const handleChange = (event, name) => {
    if (!event.target.checked) {
      setSelectedSeries(selectedSeries.filter((item) => item !== name));
    } else {
      setSelectedSeries([...selectedSeries, name]);
    }
  };

  const data = {
    series: [
      {
        color: '#B22222',
        data: progression.map((el) => el.notBegun),
        name: 'Non commencé'
      },
      {
        color: '#FF9800',
        data: progression.map((el) => el.running),
        name: 'En cours'
      },
      {
        color: '#4CAF50',
        data: progression.map((el) => el.finished),
        name: 'Terminé'
      },
    ],
    xaxis: {
      dataPoints: progression.map((el) => el.month)
    }
  };

  const chartSeries = data.series.filter((item) => selectedSeries.includes(item.name));

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: chartSeries.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      show: false
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2
      },
      radius: 2,
      shape: 'circle',
      size: 4,
      strokeWidth: 0
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt',
      width: 3
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: data.xaxis.dataPoints,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: [
      {
        min: 0,
        max: 100,
        axisBorder: {
          color: theme.palette.divider,
          show: true
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary
          }
        }
      },
      // {
      //   min: 0,
      //   max: 100,
      //   axisBorder: {
      //     color: theme.palette.divider,
      //     show: true
      //   },
      //   axisTicks: {
      //     color: theme.palette.divider,
      //     show: true
      //   },
      //   labels: {
      //     style: {
      //       colors: theme.palette.text.secondary
      //     }
      //   },
      //   opposite: true
      // }
    ]
  };

  return (
    <Card {...props}>
      <CardHeader
        title={
          <Grid>
            <Typography
              variant="h4"
            >
              Avancement des sessions
            </Typography>
            {/* <Typography>
              au cours des 12 derniers mois
            </Typography> */}
          </Grid>
        }
      />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          mt: 4,
          px: 2
        }}
      >
        {data.series.map((item) => (
          <Box
            key={item.name}
            sx={{
              alignItems: 'center',
              display: 'flex',
              mr: 2
            }}
          >
            <Checkbox
              checked={selectedSeries.some((visibleItem) => visibleItem === item.name)}
              onChange={(event) => handleChange(event, item.name)}
            />
            <Box
              sx={{
                border: 3,
                borderColor: selectedSeries.some((visibleItem) => visibleItem === item.name)
                  ? item.color
                  : alpha(item.color, 0.4),
                borderRadius: '50%',
                height: 16,
                mr: 1,
                width: 16
              }}
            />
            <Typography
              sx={{
                color: selectedSeries.some((visibleItem) => visibleItem === item.name)
                  ? 'textPrimary'
                  : alpha(theme.palette.text.primary, 0.4)
              }}
              variant="subtitle2"
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Chart
        height={300}
        options={chartOptions}
        series={chartSeries}
        type="line"
      />
    </Card>
  );
};
