import { useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { CustomerEditForm } from '../../../components/dashboard/create/create-user';
import { gtm } from '../../../lib/gtm';

const CreateUser = () => {

  useEffect(() => {
      gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
        flexGrow: 1,
        py: 11
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid
            container
            justifyContent="space-between"
            spacing={3}
            >
              <Grid item>
                <Typography variant="h4" sx={{fontFamily: "Montserrat"}}>
                Créer un utilisateur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <CustomerEditForm/>
        </Container>
      </Box>
    </>
  );
};

export default CreateUser;
