class SatisfactionApi {
    async getSurveySatisfactionResults(adminId, auditId) {
        const url = `${process.env.REACT_APP_BACK}/api/surveySatisfactionResults/get/`
        const values = {
            "admin_id": adminId,
            'course_id': auditId
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
}

export const satisfactionApi = new SatisfactionApi();