import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

// Objet de style servant à customiser les champs d'identifiants
const useTextFieldStyles = makeStyles({
    root: {
        '& label': {
            color: 'white', // Couleur lorsque le label est en focus
            paddingLeft: '1.2rem',
            fontFamily: '"Montserrat", sans-serif'
        },
        '& label.Mui-focused': {
            color: 'white', // Couleur lorsque le label est en focus
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white', // Couleur de la bordure
            },
            '&:hover fieldset': {
                borderColor: 'white', // Couleur de la bordure en survol
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white', // Couleur de la bordure lorsque le TextField est en focus
            },
        },
        '& input:-webkit-autofill': {
            'transition': 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
            'background-color': 'initial !important', // Conservez la couleur de fond initiale
            'color': 'initial !important' 
        },
    }
  });

// Formulaire de connexion avec la bibliothèque Formik (le scénario en cas de mot de passe oublié est encore à gérer)
export const JWTForgotPassword = (props) => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth();
  const classes = useTextFieldStyles();
  const { handleForgottenPassword, setIsPasswordForgotten, setLoginError } = props

  const formik = useFormik({
    initialValues: {
      email: "",
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('L\'adresse mail doit être valide')
        .max(255)
        .required('Adresse mail requise'),
    }),
    onSubmit: async (values, helpers) => {
    //   try {
    //     const res = await login(values.email, values.password);

    //     if (isMounted() && res == true) {
    //       navigate('/dashboard', { replace: true});
    //     } else {
    //       toast.error('Email ou mot de passe incorrect(s)');
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     if (err instanceof TypeError && err.message === 'Failed to fetch') setLoginError("Connexion impossible : problème au niveau du serveur")
    //     if (isMounted()) {
    //       helpers.setStatus({ success: false });
    //       helpers.setErrors({ submit: err.message });
    //       helpers.setSubmitting(false);
    //     }
    //   }
    }
  });

    return (
        <>
            <Box sx={{display: 'flex', gap: 2}}>
                <IconButton
                    size="large"
                    onClick={() => setIsPasswordForgotten(false)}
                >
                    <ArrowCircleLeftOutlinedIcon sx={{ color: 'white', width: '3rem', height: '3rem', mt: 2 }}
                         />
                </IconButton>
                <Typography
                    variant="h4"
                    sx={{
                        color: 'white',
                        mt: 3,
                        px: 2
                    }}
                >
                    Mot de passe oublié ?
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ color: 'white', px: 2, mt: 2 }}>Entrez  et envoyez votre adresse e-mail pour recevoir un nouveau mot de passe de connexion.</Typography>
            </Box>
            <form
                noValidate
                onSubmit={formik.handleSubmit}
                {...props}>
                <TextField
                    variant="outlined"
                    // autofocus
                    // error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    // helperText={formik.touched.email && formik.errors.email}
                    label="Email"
                    // margin="normal"
                    name="email"
                    // onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                    className={classes.root}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailOutlinedIcon sx={{ color: 'white' }} />
                            </InputAdornment>
                          ),
                        style: {
                            color: 'white', // Couleur de l'input
                        },
                    }}
                    sx={{ mt: 5, px: 2 }}
                />
                {formik.errors.submit && (
                    <Box>
                    <FormHelperText error>
                        {formik.errors.submit}
                    </FormHelperText>
                    </Box>
                )}
                <Box sx={{ mt: 5, p: 2 }}>
                    <Button
                        disabled={formik.isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        sx={{
                            p: 2,
                            fontFamily: '"Montserrat", sans-serif',
                            color: '#1B2445',
                            background: '#FFFFFF',
                            '&:hover': {
                            color: 'white',
                            backgroundColor: "#101d42"
                            }
                        }}
                    >
                        ENVOYER
                    </Button>
                </Box>
            </form>
        </>
  );
};
