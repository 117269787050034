import { CircularProgress, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../../hooks/use-auth";
import { sessionsApi } from "../../../../api/session-api";

const tableStyles = {
  padding: "3rem 2rem 0px 3rem",
};
const theadStyles = {
  paddingRight: "2rem",
  display: "table",
  width: "100%",
  tableLayout: "fixed",
};
const tBodyStyles = {
  display: "block",
  // height: '80%',
  overflow: "auto",
  marginTop: "10px",
  paddingRight: "2rem",
};
const tdStyles = {
  paddingLeft: "12px",
};
const trStyles = {
  height: "55px",
  display: "table",
  width: "100%",
  tableLayout: "fixed",
};

const Statistiques = ({
  matches,
  auditId,
  selectedTour,
  start_session,
  end_session,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { user } = useAuth();

  const [detailsLoading, setDetailsLoading] = useState(true);
  const [moyenneSessionLoading, setMoyenneSessionLoading] = useState(true);
  const [moyenneAllSessionLoading, setMoyenneAllSessionLoading] =
    useState(true);

  let [resultatsByApprenants, setResultatsByApprenants] = useState([]);
  let [moyenneSessionResults, setMoyenneSessionResults] = useState([]);
  let [moyenneSessionResultsTotaux, setMoyenneSessionResultsTotaux] = useState({
    oui: 0,
    non: 0,
    na: 0,
  });

  let [moyenneAllSessionResults, setAllMoyenneSessionResults] = useState([]);
  let [moyenneAllSessionResultsTotaux, setAllMoyenneSessionResultsTotaux] =
    useState({
      oui: 0,
      non: 0,
      na: 0,
    });

  useEffect(async () => {
    setMoyenneSessionResultsTotaux({
      oui: 0,
      non: 0,
      na: 0,
    });
    setAllMoyenneSessionResultsTotaux({
      oui: 0,
      non: 0,
      na: 0,
    });
    setDetailsLoading(true);
    setMoyenneSessionLoading(true);
    setMoyenneAllSessionLoading(true);
    setResultatsByApprenants([]);
    setMoyenneSessionResults([]);
    setAllMoyenneSessionResults([]);
    let turn = selectedTour === 0 ? 1 : 2;
    // setTimeout(async ()=>{
    let responseResultatsBySessionEtApprenant = await sessionsApi
      .getResultatsBySessionEtApprenant(
        user.admin_id,
        auditId,
        turn,
        start_session,
        end_session
      )
      .catch(() => {
        setDetailsLoading(false);
      });

    if (responseResultatsBySessionEtApprenant?.results?.length > 0) {
      setResultatsByApprenants(responseResultatsBySessionEtApprenant?.results);
    }
    setDetailsLoading(false);
    // }, 2000);

    // setTimeout(async ()=>{
    let responseCriteresResultsMoyenneBySession = await sessionsApi
      .getCriteresResultsMoyenneBySession(
        user.admin_id,
        auditId,
        turn,
        start_session,
        end_session
      )
      .catch(() => {
        setMoyenneSessionLoading(false);
      });
    if (responseCriteresResultsMoyenneBySession?.results?.length > 0) {
      setMoyenneSessionResults(
        responseCriteresResultsMoyenneBySession?.results
      );
      responseCriteresResultsMoyenneBySession?.results.map((item) => {
        setMoyenneSessionResultsTotaux((prevState) => {
          return {
            oui: prevState["oui"] + item["number_oui"],
            non: prevState["non"] + item["number_non"],
            na: prevState["na"] + item["number_na"],
          };
        });
      });
    }
    setMoyenneSessionLoading(false);
    // }, 2000);

    // setTimeout(async ()=>{
    let responseCriteresResultsMoyenneByAllSession = await sessionsApi
      .getCriteresResultsMoyenneByAllSession(user.admin_id, auditId, turn)
      .catch(() => {
        setMoyenneAllSessionLoading(false);
      });
    if (responseCriteresResultsMoyenneByAllSession?.results?.length > 0) {
      setAllMoyenneSessionResults(
        responseCriteresResultsMoyenneByAllSession?.results
      );
      responseCriteresResultsMoyenneByAllSession?.results.map((item) => {
        setAllMoyenneSessionResultsTotaux((prevState) => {
          return {
            oui: prevState["oui"] + item["number_oui"],
            non: prevState["non"] + item["number_non"],
            na: prevState["na"] + item["number_na"],
          };
        });
      });
    }
    setMoyenneAllSessionLoading(false);
    // }, 2000);
  }, [selectedTour]);

  return (
    <div style={{ marginBottom: 100 }}>
      <h3 style={{ color: "#484848" }}>{"statistiques".toUpperCase()}</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: matches ? "row" : "column",
          alignItems: "center",
          height: matches ? "400px" : "",
        }}
      >
        <div style={{ width: matches ? "30%" : "80%" }}>
          <div
            style={{
              width: "100%",
              height: "121px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
              boxShadow: selectedTab == 0 ? "" : "0px 0px 4px #6F78A7",
              borderRadius: "20px",
              backgroundColor: selectedTab == 0 ? "#9DA4C8" : null,
              color: selectedTab == 0 ? "#fff" : "#484848",
              cursor: "pointer",
            }}
            onClick={() => setSelectedTab(0)}
          >
            <p
              style={{ fontSize: 18, textAlign: "center", fontWeight: "bold" }}
            >
              {"Détails".toUpperCase()}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              height: "121px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
              boxShadow: selectedTab == 1 ? "" : "0px 0px 4px #6F78A7",
              borderRadius: "20px",
              backgroundColor: selectedTab == 1 ? "#9DA4C8" : null,
              color: selectedTab == 1 ? "#fff" : "#484848",
              cursor: "pointer",
            }}
            onClick={() => setSelectedTab(1)}
          >
            <p
              style={{ fontSize: 18, textAlign: "center", fontWeight: "bold" }}
            >
              {"Moyenne de la session".toUpperCase()}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              height: "121px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: selectedTab == 2 ? "" : "0px 0px 4px #6F78A7",
              borderRadius: "20px",
              backgroundColor: selectedTab == 2 ? "#9DA4C8" : null,
              color: selectedTab == 2 ? "#fff" : "#484848",
              cursor: "pointer",
            }}
            onClick={() => setSelectedTab(2)}
          >
            <p
              style={{ fontSize: 18, textAlign: "center", fontWeight: "bold" }}
            >
              {"Moyenne de toutes ".toUpperCase()}
              <br />
              {"les sessions".toUpperCase()}
            </p>
          </div>
        </div>
        {selectedTab == 0 && (
          <Paper
            style={{
              backgroundColor: "white",
              width: matches ? "65%" : "80%",
              height: "100%",
              borderRadius: "40px",
              marginTop: matches ? "" : "2rem",
            }}
            elevation={18}
          >
            <table
              style={{
                width: "100%",
                height: "100%",
                textAlign: "left",
                ...tableStyles,
              }}
            >
              <thead style={{ ...theadStyles }}>
                <tr style={{ ...trStyles }}>
                  <th colSpan={3} style={{ color: "#484848" }}>
                    Nom de l'apprenant
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ color: "#7EB627" }}>OUI</th>
                  <th style={{ color: "#DF855F" }}>NON</th>
                  <th style={{ color: "#929292" }}>NA</th>
                </tr>
              </thead>
              {detailsLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#9DA4C8" }} />
                </div>
              )}
              {resultatsByApprenants.length == 0 && !detailsLoading && (
                <p>Pas de données trouvées ...</p>
              )}
              {resultatsByApprenants.length > 0 && !detailsLoading && (
                <tbody
                  style={{ ...tBodyStyles, height: matches ? "80%" : "200px" }}
                >
                  {resultatsByApprenants.map((item) => {
                    return (
                      <tr key={item?.user_id} style={{ ...trStyles }}>
                        <td
                          colSpan={3}
                          style={{
                            color: "#484848",
                            fontWeight: "bold",
                            fontSize: 18,
                            ...tdStyles,
                          }}
                        >
                          {item?.user_name}
                        </td>
                        <td style={{ ...tdStyles }}></td>
                        <td style={{ ...tdStyles }}></td>
                        <td style={{ ...tdStyles }}></td>
                        <td
                          style={{
                            color: "#7EB627",
                            fontWeight: "bold",
                            ...tdStyles,
                          }}
                        >
                          {item?.oui}
                        </td>
                        <td
                          style={{
                            color: "#DF855F",
                            fontWeight: "bold",
                            ...tdStyles,
                          }}
                        >
                          {item?.non}
                        </td>
                        <td
                          style={{
                            color: "#929292",
                            fontWeight: "bold",
                            ...tdStyles,
                          }}
                        >
                          {item?.na}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </Paper>
        )}
        {selectedTab == 1 && (
          <div
            style={{
              width: "65%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: matches ? "row" : "column",
              alignItems: "center",
              marginTop: matches ? "" : "3rem",
            }}
          >
            <Paper
              style={{
                width: matches ? "35%" : "100%",
                height: "100%",
                backgroundColor: "white",
                position: "relative",
                borderRadius: "40px",
              }}
              elevation={18}
            >
              <div
                style={{
                  width: "150px",
                  position: "absolute",
                  top: -28,
                  left: "calc(100% - (50% + 75px))",
                  zIndex: 2,
                  backgroundColor: "#9da4c8",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "40px",
                }}
              >
                <p>{"générale".toUpperCase()}</p>
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: matches ? "" : "2rem",
                }}
              >
                <div style={{ margin: "0 1.5rem" }}>
                  <p
                    style={{
                      color: "#7EB627",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    OUI
                  </p>
                  <p
                    style={{
                      color: "#7EB627",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {moyenneSessionLoading ? (
                      <CircularProgress
                        size="1.4rem"
                        sx={{ color: "#9DA4C8" }}
                      />
                    ) : (
                      moyenneSessionResultsTotaux["oui"]
                    )}
                  </p>
                </div>
                <div style={{ margin: "0 1.5rem" }}>
                  <p
                    style={{
                      color: "#DF855F",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    NON
                  </p>
                  <p
                    style={{
                      color: "#DF855F",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {moyenneSessionLoading ? (
                      <CircularProgress
                        size="1.4rem"
                        sx={{ color: "#9DA4C8" }}
                      />
                    ) : (
                      moyenneSessionResultsTotaux["non"]
                    )}
                  </p>
                </div>
                <div style={{ margin: "0 1.5rem" }}>
                  <p
                    style={{
                      color: "#929292",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    NA
                  </p>
                  <p
                    style={{
                      color: "#929292",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {moyenneSessionLoading ? (
                      <CircularProgress
                        size="1.4rem"
                        sx={{ color: "#9DA4C8" }}
                      />
                    ) : (
                      moyenneSessionResultsTotaux["na"]
                    )}
                  </p>
                </div>
              </div>
            </Paper>
            <Paper
              style={{
                backgroundColor: "white",
                width: matches ? "60%" : "100%",
                height: "100%",
                position: "relative",
                borderRadius: "40px",
                marginTop: matches ? "" : "3rem",
              }}
              elevation={18}
            >
              <div
                style={{
                  width: "150px",
                  position: "absolute",
                  top: -28,
                  left: "calc(100% - (50% + 75px))",
                  zIndex: 2,
                  backgroundColor: "#9da4c8",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "40px",
                }}
              >
                <p>{"Par critères".toUpperCase()}</p>
              </div>
              <table
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  ...tableStyles,
                }}
              >
                <thead style={{ ...theadStyles }}>
                  <tr style={{ ...trStyles }}>
                    <th style={{ color: "#484848" }}>N° Critère</th>
                    <th style={{ color: "#7EB627" }}>OUI</th>
                    <th style={{ color: "#DF855F" }}>NON</th>
                    <th style={{ color: "#929292" }}>NA</th>
                  </tr>
                </thead>
                {moyenneSessionLoading && (
                  <div
                    style={{
                      width: "100%",
                      height: "80%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress sx={{ color: "#9DA4C8" }} />
                  </div>
                )}
                {moyenneSessionResults.length == 0 &&
                  !moyenneSessionLoading && <p>Pas de données trouvées ...</p>}
                {moyenneSessionResults.length > 0 && !moyenneSessionLoading && (
                  <tbody
                    style={{
                      ...tBodyStyles,
                      height: matches ? "80%" : "200px",
                    }}
                  >
                    {moyenneSessionResults.map((item) => {
                      return (
                        <tr key={item?.criteria_id} style={{ ...trStyles }}>
                          <td
                            style={{
                              color: "#484848",
                              fontWeight: "bold",
                              fontSize: 18,
                              ...tdStyles,
                            }}
                          >
                            {item["criteria__number"]}
                          </td>
                          <td
                            style={{
                              color: "#7EB627",
                              fontWeight: "bold",
                              ...tdStyles,
                            }}
                          >
                            {item["number_oui"]}
                          </td>
                          <td
                            style={{
                              color: "#DF855F",
                              fontWeight: "bold",
                              ...tdStyles,
                            }}
                          >
                            {item["number_non"]}
                          </td>
                          <td
                            style={{
                              color: "#929292",
                              fontWeight: "bold",
                              ...tdStyles,
                            }}
                          >
                            {item["number_na"]}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </Paper>
          </div>
        )}
        {selectedTab == 2 && (
          <div
            style={{
              width: "65%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: matches ? "row" : "column",
              alignItems: "center",
              marginTop: matches ? "" : "3rem",
            }}
          >
            <Paper
              style={{
                width: matches ? "35%" : "100%",
                height: "100%",
                backgroundColor: "white",
                position: "relative",
                borderRadius: "40px",
              }}
              elevation={18}
            >
              <div
                style={{
                  width: "150px",
                  position: "absolute",
                  top: -28,
                  left: "calc(100% - (50% + 75px))",
                  zIndex: 2,
                  backgroundColor: "#9da4c8",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "40px",
                }}
              >
                <p>{"générale".toUpperCase()}</p>
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: matches ? "" : "2rem",
                }}
              >
                <div style={{ margin: "0 1.5rem" }}>
                  <p
                    style={{
                      color: "#7EB627",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    OUI
                  </p>
                  <p
                    style={{
                      color: "#7EB627",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {moyenneAllSessionLoading ? (
                      <CircularProgress
                        size="1.4rem"
                        sx={{ color: "#9DA4C8" }}
                      />
                    ) : (
                      moyenneAllSessionResultsTotaux["oui"]
                    )}
                  </p>
                </div>
                <div style={{ margin: "0 1.5rem" }}>
                  <p
                    style={{
                      color: "#DF855F",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    NON
                  </p>
                  <p
                    style={{
                      color: "#DF855F",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {moyenneAllSessionLoading ? (
                      <CircularProgress
                        size="1.4rem"
                        sx={{ color: "#9DA4C8" }}
                      />
                    ) : (
                      moyenneAllSessionResultsTotaux["non"]
                    )}
                  </p>
                </div>
                <div style={{ margin: "0 1.5rem" }}>
                  <p
                    style={{
                      color: "#929292",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    NA
                  </p>
                  <p
                    style={{
                      color: "#929292",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {moyenneAllSessionLoading ? (
                      <CircularProgress
                        size="1.4rem"
                        sx={{ color: "#9DA4C8" }}
                      />
                    ) : (
                      moyenneAllSessionResultsTotaux["na"]
                    )}
                  </p>
                </div>
              </div>
            </Paper>
            <Paper
              style={{
                backgroundColor: "white",
                width: matches ? "60%" : "100%",
                height: "100%",
                position: "relative",
                borderRadius: "40px",
                marginTop: matches ? "" : "3rem",
              }}
              elevation={18}
            >
              <div
                style={{
                  width: "150px",
                  position: "absolute",
                  top: -28,
                  left: "calc(100% - (50% + 75px))",
                  zIndex: 2,
                  backgroundColor: "#9da4c8",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "40px",
                }}
              >
                <p>{"Par critères".toUpperCase()}</p>
              </div>
              <table
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  ...tableStyles,
                }}
              >
                <thead style={{ ...theadStyles }}>
                  <tr style={{ ...trStyles }}>
                    <th style={{ color: "#484848" }}>N° Critère</th>
                    <th style={{ color: "#7EB627" }}>OUI</th>
                    <th style={{ color: "#DF855F" }}>NON</th>
                    <th style={{ color: "#929292" }}>NA</th>
                  </tr>
                </thead>
                {moyenneAllSessionLoading && (
                  <div
                    style={{
                      width: "100%",
                      height: "80%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress sx={{ color: "#9DA4C8" }} />
                  </div>
                )}
                {moyenneAllSessionResults.length == 0 &&
                  !moyenneAllSessionLoading && (
                    <p>Pas de données trouvées ...</p>
                  )}
                {moyenneAllSessionResults.length > 0 &&
                  !moyenneAllSessionLoading && (
                    <tbody
                      style={{
                        ...tBodyStyles,
                        height: matches ? "80%" : "200px",
                      }}
                    >
                      {moyenneAllSessionResults.map((item) => {
                        return (
                          <tr key={item?.criteria_id} style={{ ...trStyles }}>
                            <td
                              style={{
                                color: "#484848",
                                fontWeight: "bold",
                                fontSize: 18,
                                ...tdStyles,
                              }}
                            >
                              {item["criteria__number"]}
                            </td>
                            <td
                              style={{
                                color: "#7EB627",
                                fontWeight: "bold",
                                ...tdStyles,
                              }}
                            >
                              {item["number_oui"]}
                            </td>
                            <td
                              style={{
                                color: "#DF855F",
                                fontWeight: "bold",
                                ...tdStyles,
                              }}
                            >
                              {item["number_non"]}
                            </td>
                            <td
                              style={{
                                color: "#929292",
                                fontWeight: "bold",
                                ...tdStyles,
                              }}
                            >
                              {item["number_na"]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
              </table>
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistiques;
