import {
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
} from "@mui/material";
import { useMounted } from "../../../hooks/use-mounted";
import { feedBacksApi } from "../../../api/feedback-api";
import { useAuth } from "../../../hooks/use-auth";
import { useCallback, useEffect, useState } from "react";

export const DossierPatient = (props) => {
  const { open, onClose, auditName, number, patient_id } = props;
  const [answersList, setAnswersList] = useState({});
  const isMounted = useMounted();
  const user = useAuth();

  const getAnswersList = useCallback(async () => {
    try {
      const data = await feedBacksApi.getPatientFolderAnswers(
        user.user.admin_id,
        patient_id
      );
      if (isMounted()) {
        setAnswersList(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getAnswersList();
  }, []);

  // Style pour le titre du patient
  const patientTitleStyle = {
    fontWeight: "bold",
    textDecoration: "underline",
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle variant="h4" align="center">
        Dossiers Patients : {auditName}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={patientTitleStyle}>
          Patient {number} :
        </Typography>
        <List>
          {answersList.list && answersList.list.length > 0 ? (
            answersList.list.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ whiteSpace: "nowrap", textAlign: "left" }}
                >
                  {item.patient_folder_title}&nbsp;:
                </Typography>
                <Typography variant="body1">{item.answer}</Typography>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1">
              Pas de données pour ce patient
            </Typography>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};
