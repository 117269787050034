import { Box, Card, Divider, Grid, IconButton, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { DataGrid, frFR, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import ProgressBar from '../session/progress-bar';
import { KeyboardArrowDown, KeyboardArrowUp, ToggleOffOutlined, ToggleOn, Visibility, ViewStream, ViewList, AutoFixHigh, FolderDelete } from '@mui/icons-material';
import { coursesApi } from '../../../api/course-api';
import { useAuth } from '../../../hooks/use-auth';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom'
import LockClockIcon from '@mui/icons-material/LockClock';
import Swal from 'sweetalert2'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import toast from 'react-hot-toast';
import moment from 'moment';
import { formationsApi } from '../../../api/formation-api';
import { facturationApi } from '../../../api/facturation-api';
import * as XLSX from 'xlsx';
import LinearProgress from '@mui/material/LinearProgress';
import { parse, format } from 'date-fns'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { CustomerCoursesGrid } from '../customer/customer-courses-grid';
import { CustomerResponseAudit } from '../customer/customer-response-audit';
import { CustomerResponseTCS } from '../customer/customer-reponse-tcs';
import { CustomerFeedBackTCS } from './customer-feedback-tcs';
import { CustomerFeedbackAudit } from './customer-feedback-audit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { feedBacksApi } from '../../../api/feedback-api';
import { useMounted } from '../../../hooks/use-mounted';

export const CustomerCoursesToFeedBack = (props) => {
  const { customer, setCustomer, customerId, setUpdateFeedbackState} = props;
  const isMounted = useMounted();
  const [rows, setRows] = useState([]);
  const [openAuditId, setOpenAuditId] = useState(null)
  const [startSession, setStartSession] = useState(null)
  const [endSession, setEndSession] = useState(null)
  const [auditType, setAuditType] = useState(null)
  const [courseId, setCourseId] = useState(null);
  const [auditName, setAuditName] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [feedbackFirstTurnSent, setFeedbackFirstTurnSent] = useState(false);
  const [feedbackSecondTurnSent, setFeedbackSecondTurnSent] = useState(false);
  const [feedbackImprovementSent, setFeedbackImprovementSent] = useState(false);

  const { user } = useAuth(); 
  const useStyles = makeStyles({
    disableRow: {
      backgroundColor: '#CBCBCB',
      "&.MuiDataGrid-row:hover": {
        backgroundColor: '#CBCBCB',
      },
    },
    normalRow: {
      backgroundColor: 'white',
      "&.MuiDataGrid-row:hover": {
        backgroundColor: 'white',
      },
    },
  });

  const classes = useStyles();

  useEffect(async () => {
    try {
      const data = await feedBacksApi.getGeneratedFeedbacks(user.admin_id, courseId);
      console.log(data)
      if (isMounted()) {
        setGeneratedFeedbacks(data)
        data.message.map((i)=>{
          if (i.turn === 1 && i.type_generated === 1 && (i.isvalid === 1 && correction !== null)){
            setFeedbackFirstTurnSent(true);
          }
          if (i.turn === 2 && i.type_generated === 1 && (i.isvalid === 1 && correction !== null)){
            setFeedbackSecondTurnSent(true);
          }
          if (i.turn === 1 && i.type_generated === 2 && (i.isvalid === 1 && correction !== null)){
            setFeedbackImprovementSent(true);
          }
        })
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  function transformDate(dateString) {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    const transformedDate = format(parsedDate, 'yyyy-MM-dd');
    return transformedDate;
  }

  const handleRowUpdate = useCallback(
    async (newRow) => {
      const start_session = moment(newRow.start_session).format("DD/MM/YYYY");
      if (start_session != "Invalid date") {
        newRow.start_session = start_session;
      }
      const end_session = moment(newRow.end_session).format("DD/MM/YYYY");
      if (end_session != "Invalid date") {
        newRow.end_session = end_session
      }
      await coursesApi.modifyCourse(user.admin_id, newRow);
      return newRow
    }
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.error(error);
  });

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const handleClose = () => {
    setOpenAuditId(null);
  };

  const handleClickOpen = (auditId, start_session, end_session, audit_type, course_id, audit_name) => {
    setOpenAuditId(auditId);
    setStartSession(start_session);
    setEndSession(end_session);
    setAuditType(audit_type);
    setCourseId(course_id);
    setAuditName(audit_name);
  };


  const setLoading = (course_id, state) => {
    const updateRowData = rows.map((row) => {
      if (row.course_id === course_id) {
        return {
          ...row,
          loading: state
        }
      }
      return row
    })
    setRows(updateRowData)
  }

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );

  useEffect(() => {
    setRows((prevRows) => customer.audits.map((item, index) => {
      const courseDetails = customer.courses_details[index];
      return {
      id: index,
      action_type: item.action_type,
      activated: item.activated,
      audit_id: item.audit_id,
      audit_name: item.audit_name,
      audit_type: item.audit_type,
      course_id: item.course_id,
      end_session: item.end_session,
      loading: false,
      format: item.format,
      prediction: item.prediction,
      program_number: item.program_number,
      progression: item.progression,
      spent_time: item.spent_time,
      session_number: item.session_number,
      sessions_id: item.sessions_id,
      start_session: moment(transformDate(item.start_session)).format('DD/MM/YYYY'),
      state: item.state,
      step: item.step,
      ...(courseDetails && {
        is_feedback_first_turn_sent:
          courseDetails.is_feedback_first_turn_sent,
        is_feedback_second_turn_sent:
          courseDetails.is_feedback_second_turn_sent,
        is_feedback_improvement_sent:
          courseDetails.is_feedback_improvement_sent,
      }),
      }
    }));
  }, [customer]);

  const columns = [
    {
      field: 'audit_name',
      headerName: 'Nom du cours',
      renderCell: (cellValues) => (
        <Tooltip  
          title={(
            <span>
              {cellValues.row.audit_name}
              <br />
              Id du cours : {cellValues.row.course_id}
            </span>
          )}
        >
          <RouterLink
            to={`/dashboard/session/1`}
            state={{
              data_from: {
                auditId: cellValues.row.audit_id,
                startSession: cellValues.row.start_session,
                endSession: cellValues.row.end_session,
                sessions_id: cellValues.row.sessions_id,
                from: "/dashboard/customers/" + customerId
              }
            }}
            style={{ textDecoration: 'none', color:"black" , backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
            >
            {cellValues.row.audit_name}
          </RouterLink>
        </Tooltip>
      ),
      width: 450,
    },
    {
      field: 'action_type',
      headerName: 'Type de formation',
      type: 'singleSelect',
      valueOptions: ["EPP", "Programme intégré"],
      editable: true,
      width: 170,
    },
    {
      field: 'format',
      headerName: 'Format',
      type: 'singleSelect',
      valueOptions: ["Non présentiel", "Mixte"],
      editable: true,
      width: 130,
    },
    {
      field: 'start_session',
      headerName: 'Début session',
      type: 'date',
      valueGetter: (params) => {
        return parse(params.value, 'dd/MM/yyyy', new Date());
      },
      editable: true,
      width: 120,
    },
    {
      field: 'end_session',
      headerName: 'Fin session',
      width: 110,
    },
    {
      field: 'progression',
      headerName: 'Progression',
      renderCell: (cellValues) => (
        <ProgressBar
          value={cellValues.row.progression}
        />
      ),
      width: 160,
    },
    {
      field: 'state',
      headerName: 'Etat',
      width: 130,
    },
    //Affichage des feedbacks 
    {
      field: 'feedbacks',
      headerName: 'Feedbacks',
      renderCell: (cellValues) => (
        <div style={{ display: 'flex', alignItems: 'left' }}>
          <Tooltip title="Tour 1">
            {cellValues.row.is_feedback_first_turn_sent ? 
            <IconButton>
              <TaskAltIcon sx={{color: 'green'}}/>
            </IconButton> : 
            <IconButton>
              <CancelIcon sx={{color: 'red'}}/>
            </IconButton>
            }
          </Tooltip>
          <Tooltip title="Objectifs d'améliorations">
          {cellValues.row.is_feedback_improvement_sent ? 
            <IconButton>
              <TaskAltIcon sx={{color: 'green'}}/>
            </IconButton> : 
            <IconButton>
              <CancelIcon sx={{color: 'red'}}/>
            </IconButton>
            }
          </Tooltip>
          <Tooltip title="Tour 2">
          {cellValues.row.is_feedback_second_turn_sent ? 
            <IconButton>
              <TaskAltIcon sx={{color: 'green'}}/>
            </IconButton> : 
            <IconButton>
              <CancelIcon sx={{color: 'red'}}/>
            </IconButton>
            }
          </Tooltip>
        </div>
      ),
      width: 180,
    },
    {
      field: 'answer',
      headerName: 'Action',
      renderCell: (cellValues) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Voir les réponses">
            <IconButton onClick={() => handleClickOpen(cellValues.row.audit_id, cellValues.row.start_session, cellValues.row.end_session, cellValues.row.audit_type, cellValues.row.course_id, cellValues.row.audit_name)}>
              <Visibility/>
            </IconButton>
          </Tooltip>
        </div>
      ),
      width: 100
    }
  ]

  return (
    <Grid>
      {viewMode === "list" && (
        <Card>
          <Grid container>
            <Grid item 
            sm={12} 
            sx={{textAlign:'right', p:2, pb: 0}}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
              >
                <ToggleButton value="list">
                  <ViewList /> Liste
                </ToggleButton>
                {/* <ToggleButton value="grid">
                  <ViewStream /> Grille
                </ToggleButton> */}
              </ToggleButtonGroup>
            </Grid>
            <Grid item 
            sm={12}>
              <DataGrid
                density="standard"
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                rows={rows}
                disableRowSelectionOnClick
                getRowClassName={(params) => {
                  if (params.row.activated === false) {
                    return classes.disableRow
                  }
                  return classes.normalRow;
                }}
                disableHoverListener
                columns={columns}
                pageSize={20}
                pageSizeOptions={[5]}
                components={{ Toolbar: CustomToolbar }}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                sx={{
                  color : "#1E2022" , 
                  backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
              />
             {openAuditId !== null && (
               auditType === "TCS" ?
               <CustomerFeedBackTCS
                 open={true}
                 handleClose={handleClose}
                 course_id={courseId}
                 auditName={auditName}
                 customerDetails={customer.details}
               />
               :
               <CustomerFeedbackAudit
                 open={true}
                 handleClose={handleClose}
                 audit_id={openAuditId}
                 customer_id={customerId}
                 start_session={startSession}
                 end_session={endSession}
                 courseId={courseId}
                 auditName={auditName}
                 customerDetails={customer.details}
                 feedbackFirstTurnSent={feedbackFirstTurnSent}
                 feedbackSecondTurnSent={feedbackSecondTurnSent}
                 feedbackImprovementSent={feedbackImprovementSent}
                 setFeedbackFirstTurnSent={setFeedbackFirstTurnSent}
                 setFeedbackSecondTurnSent={setFeedbackSecondTurnSent}
                 setFeedbackImprovementSent={setFeedbackImprovementSent}
                 setUpdateFeedbackState={setUpdateFeedbackState}
               />
             )}
           </Grid>
          </Grid>
        </Card>
      )}
      {/* {viewMode === 'grid' && (
        customer.audits.map((option, index) => (
          <Card sx={{'mb': 3}}>
            <Grid container>
              {index === 0?
                 <Grid item sm={12} sx={{textAlign:'right', p:2, pb: 0}}>
                 <ToggleButtonGroup
                   value={viewMode}
                   exclusive
                   onChange={handleViewModeChange}
                 >
                   <ToggleButton value="list">
                     <ViewList /> Liste
                   </ToggleButton>
                   <ToggleButton value="grid">
                     <ViewStream /> Grille
                   </ToggleButton>
                 </ToggleButtonGroup>
               </Grid>
              : ""
              }
              <Grid
                item
                xs={12}
                key={option.audit_id}
                padding={2}
                >
                <CustomerCoursesGrid
                  customer={customer}
                  setCustomer={setCustomer}
                  customer_id={customerId}
                  index={index}
                  />
              </Grid>
            </Grid>
          </Card>
        ))
      )} */}
    </Grid>
  );
};