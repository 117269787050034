class CoursesApi {
    async createCourse(admin_id, sessions_id, users_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/create/`
        const values = {
            "admin_id": admin_id,
            "sessions_id": sessions_id,
            "users_id": users_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async desactivateCourse(admin_id, course_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/desactivate/`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async activateCourse(admin_id, course_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/activate/`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getAllAnswer(admin_id, audit_id, users_id, start_session, end_session) {
        const url = `${process.env.REACT_APP_BACK}/api/course/getAnswer/`
        const values = {
            "admin_id": admin_id,
            "audit_id": audit_id,
            "users_id": users_id,
            "start_session": start_session,
            "end_session": end_session
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async modifyAnswer(admin_id, result_id, answer) {
        const url = `${process.env.REACT_APP_BACK}/api/course/modifyAnswer/`
        const values ={
            "admin_id": admin_id,
            "result_id": result_id,
            "answer": answer
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async changeDate(admin_id, course_id, start_session, end_session) {
        const url = `${process.env.REACT_APP_BACK}/api/course/changeDate/`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id,
            "start_session": start_session,
            "end_session": end_session
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async deletePatient(admin_id, patient_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/deletePatient/`
        const values = {
            "admin_id": admin_id,
            "patient_id": patient_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async getTcsResult(admin_id, courses_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/getResultAdmin/`
        const values = {
            "admin_id": admin_id,
            "courses_id": courses_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async unlockSecondTurn(admin_id, courses_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/unlockSecondTurn/`
        const values = {
            "admin_id": admin_id,
            "courses_id": courses_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async generateLogs(admin_id, course_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/generateLogs/`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
    
    async deleteFacturation(admin_id, course_id) {
        const url = `${process.env.REACT_APP_BACK}/api/facturation/delete/`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyCourse(admin_id, newRow) {
        const url = `${process.env.REACT_APP_BACK}/api/course/modify/`
        const values = {
            "admin_id": admin_id,
            "course_data": newRow
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async reinitializeCourse(admin_id, courses_id) {
        const url = `${process.env.REACT_APP_BACK}/api/course/reinitialize/`
        const values = {
            "admin_id": admin_id,
            "courses_id": courses_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getGlobalAnalyse(origin, user_id, courses_id, turn) {
        const url = `${process.env.REACT_APP_BACK}/api/analyse_global/get/`
        const values = {
            "origin": origin,
            "admin_id": user_id,
            "course_id": courses_id,
            "turn": turn
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getSessionAnalyse(admin_id, courses_id, turn) {
        const url = `${process.env.REACT_APP_BACK}/api/analyse_session/get/`
        const values = {
            "admin_id": admin_id,
            "course_id": courses_id,
            "turn": turn
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const coursesApi = new CoursesApi();