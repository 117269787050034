import { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { conversationApi } from "../../../api/conversation-api";

import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { useMounted } from "../../../hooks/use-mounted";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import { set } from "date-fns";
import CircularProgress from '@mui/material/CircularProgress';

function formatDateTime(inputDateTime) {
  const inputDate = new Date(inputDateTime);
  const currentDate = new Date();

  const diffDays = Math.floor(
    (currentDate - inputDate) / (1000 * 60 * 60 * 24)
  );

  if (diffDays === 0)
    return (
      inputDate.getHours() +
      ":" +
      String(inputDate.getMinutes()).padStart(2, "0")
    );
  if (diffDays === 1) return "Hier";
  if (diffDays === 2) return "Avant-hier";
  return diffDays < 7
    ? `${String(inputDate.getDate()).padStart(2, "0")}/${String(
        inputDate.getMonth() + 1
      ).padStart(2, "0")}`
    : `${String(inputDate.getDate()).padStart(2, "0")}/${String(
        inputDate.getMonth() + 1
      ).padStart(2, "0")}/${String(inputDate.getFullYear()).slice(-2)}`;
}


function truncateLongString (string) {
    return `${string.slice(0, 56)}...`
}


const badgeStyles = makeStyles((theme) => ({
  badge: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    backgroundColor: "white",
    color: process.env.REACT_APP_NEW_PRIMARY_COLOR,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
  },
}));

function CircularBadge({ number }) {
  const classes = badgeStyles();

  return (
    <Badge
      badgeContent={number}
      overlap="circle"
      classes={{ badge: classes.badge }}
    />
  );
}

export const LastMessages = () => {
  const { user } = useAuth();
  const isMounted = useMounted();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [showChecked, setShowChecked] = useState(false)
  // const [selectedMessageId, setSelectedMessageId] = useState(null)
  const navigate = useNavigate();

  const handleClick = (id) => {
    // Naviguer vers la destination avec les données passées comme paramètres d'URL
    navigate(`/dashboard/discussion?to_conversation=${id}`);
  };

  const getLastMessages = async () => {
    const messagesData = await conversationApi.getLastMessages(user.admin_id).catch(() => setIsLoading(false));
    if (isMounted()) {
      setMessages(messagesData);
      setIsLoading(false)
      setShowChecked(true)
    }
  };

  useEffect(() => {
    setIsLoading(true)
    getLastMessages();
  }, []);

  useEffect(() => {
    const url =  process.env.REACT_APP_WS_URL + `chat-server/`;
    // const ws = new WebSocket(`ws://127.0.0.1:7000/ws/chat-server/`);
    const ws = new WebSocket(url);
    ws.onopen = () => {
      console.log("Connected to WebSocket");
    };

    ws.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.trainer_id === user.admin_id) {
        if (
          eventData.type === "newConversationAdmin" ||
          eventData.type === "newMessage"
        ) {
          if (
            messages.some(
              (message) => message.conversation_id === eventData.conversation_id
            )
          ) {
            const messageToUpdate = messages.find(
              (message) => message.conversation_id === eventData.conversation_id
            );
            const updateMessages = messages.filter(
              (message) => message.conversation_id !== eventData.conversation_id
            );
            messageToUpdate.number_unread_messages += 1;
            messageToUpdate.sent_at = eventData.sent_at;
            messageToUpdate.content = eventData.content;
            updateMessages.unshift(messageToUpdate);
            setMessages(updateMessages);
          } else {
            const newMessage = {
              id: eventData.id,
              conversation_id: eventData.conversation_id,
              learner_details: eventData.learner_details,
              content: eventData.content,
              sent_at: eventData.sent_at,
              number_unread_messages: 1,
            };

            const updateMessages = [...messages];
            updateMessages.unshift(newMessage);
            if (updateMessages.length > 3) updateMessages.pop();
            setMessages(updateMessages);
          }
        }
      }
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket");
    };

    return () => {
      ws.close();
    };
  }, [messages]);

  // useEffect(() => {

  // })

  return (
    <Card
      sx={{
        backgroundColor: process.env.REACT_APP_NEW_PRIMARY_COLOR,
        borderRadius: 3,
        minHeight: 500,
        maxHeight: 500,
        overflowY: "auto",
        scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`,
      }}
    >
      <CardHeader
        title={
          <Grid>
            <Typography variant="h6" sx={{ color: (theme) => theme.palette.mode === "dark" ? "black" : "white" }}>
              Derniers messages non lus
            </Typography>
          </Grid>
        }
      />
      <Divider />
      <Box sx={{ height: 300 }}>
        { isLoading ? (
        <Box
          sx={{
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            flexDirection: "column"
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      
     ) : (
        messages?.length > 0 ? (
          messages.map((message, index) => (
            <ButtonBase
              onClick={() => handleClick(message.conversation_id)}
              sx={{ width: "100%" }}
            >
              <ListItem divider disablePadding sx={{ p: 1 }} key={index}>
                <ListItemText
                  primary={
                    <Grid container alignItems="center" sx={{ ml: 2, mt: 1 }}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: 999,
                          backgroundColor: "#FFFFFF",
                          color: process.env.REACT_APP_NEW_PRIMARY_COLOR,
                          whiteSpace: "nowrap",
                          fontSize: "12px",
                          "&:hover": {
                            backgroundColor: "#FFFFFF", // Garde la même couleur de fond
                            color: process.env.REACT_APP_NEW_PRIMARY_COLOR, // Garde la même couleur de texte
                          },
                        }}
                      >
                        {formatDateTime(message.sent_at)}
                      </Button>
                      <Typography
                        variant="subtitle2"
                        color="white"
                        sx={{ ml: 2 }}
                      >
                        {message.learner_details.fullname.toUpperCase()} :
                      </Typography>
                    </Grid>
                  }
                  secondary={
                    <Box sx={{ display: "flex", gap: 1, mt: 0.8 }}>
                      <Typography
                        // variant="body"
                        color="white"
                        paddingLeft={3}
                        fontSize={15}
                        sx={{ flex: 0.9, fontSize: 16 }}
                      >
                        {message.content.length < 57 ? message.content : truncateLongString(message.content)}
                      </Typography>
                      <CircularBadge number={message.number_unread_messages} />
                    </Box>
                  }
                />
              </ListItem>
            </ButtonBase>
          ))
        ) : (
            <Box
              sx={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Slide direction="right" in={showChecked}>
                <CheckIcon sx={{ color: (theme) =>
                        theme.palette.mode === "dark" ? "black" : "white", fontSize: 150 }} />
              </Slide>
              <Typography
                // variant="h6" 
                sx={{ 
                  // fontStyle: "italic",
                  fontWeight: 600, 
                  color: (theme) =>
                        theme.palette.mode === "dark" ? "black" : "white", 
              }}>
                Vous n'avez aucun message non lu
              </Typography>
            </Box>
        ))}
      </Box>
    </Card>
  );
};
