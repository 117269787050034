import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import { useMounted } from "../../../hooks/use-mounted";
import { useAuth } from "../../../hooks/use-auth";
import { auditsApi } from "../../../api/audit-api";
import { FormersApi } from "../../../api/formateur-api";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const FormerBasicDetails = (props) => {
  let { formerData, setFormerData } = props;
  const isMounted = useMounted();
  const { user } = useAuth();
  const [modifyText, setModifyText] = useState("");
  const [audits, setAudits] = useState([]);
  const [auditsSessions, setAuditsSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState({});
  const [trainerSessions, setTrainerSessions] = useState({});
  const [previousSelectedSessions, setPreviousSelectedSessions] = useState({});
  const [previousSelectedAudits, setPreviousSelectedAudits] = useState([]);
  const [modify, setModify] = useState({
    email: null,
    epp_ids: null,
  });

  async function handleModify(field, data = null) {
    setModify((modify) => ({
      ...modify,
      [field]: false,
    }));
    let changeSurvey = { ...formerData };
    if (data) {
      changeSurvey[field] = data;
    } else {
      changeSurvey[field] = modifyText;
    }

    if (field === "epp_ids") {
        if (Array.isArray(changeSurvey[field])) {
            changeSurvey[field] = changeSurvey[field].join(",");
        }

        const newSelectedAudits = changeSurvey[field] ? changeSurvey[field].split(",") : [];

        const auditsRemoved = previousSelectedAudits.filter(
            (audit) => !newSelectedAudits.includes(audit)
        );

        for (let audit of auditsRemoved) {
            await FormersApi.deleteAllSessionsOfTrainer(
                user.admin_id,
                formerData.id,
                audit
            );
        }

        setPreviousSelectedAudits(newSelectedAudits);
        await getAuditsSessions(newSelectedAudits);
    }

    if (field === "epp_ids" && Array.isArray(changeSurvey[field])) {
      changeSurvey[field] = changeSurvey[field].join(",");
    }
    setFormerData(changeSurvey);

    await FormersApi.modifyFormerData(
      user.admin_id,
      formerData.id,
      changeSurvey.email,
      changeSurvey.epp_ids
    );
    if (field === "epp_ids") {
        const epp_ids_array = changeSurvey.epp_ids ? changeSurvey.epp_ids.split(",") : [];
        await getAuditsSessions(epp_ids_array);
    }
  }

  const getAudits = useCallback(async () => {
    try {
      const data = await auditsApi.getAllEpp(user.admin_id);
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user.admin_id, isMounted]);

  const getAuditsSessions = useCallback(
    async (epp_ids) => {
      try {
        const epp_ids_str = epp_ids.join(",");
        const data = await auditsApi.getAuditsSessions(
          user.admin_id,
          epp_ids_str
        );
        if (isMounted()) {
          setAuditsSessions(data.result);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [user.admin_id, isMounted]
  );

  const getTrainerSessions = useCallback(async () => {
    try {
      const data = await FormersApi.getTrainerSessions(
        user.admin_id,
        formerData.id
      );
      if (isMounted()) {
        setTrainerSessions(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user.admin_id, isMounted, formerData]);

  useEffect(() => {
    getAudits();
    if (formerData) {
      let initialAudits = [];
      if (Array.isArray(formerData.epp_ids)) {
        initialAudits = formerData.epp_ids;
      } else if (typeof formerData.epp_ids === "string") {
        initialAudits = formerData.epp_ids.split(",");
      } else if (typeof formerData.epp_ids === "number") {
        initialAudits = [formerData.epp_ids.toString()];
      }
      setPreviousSelectedAudits(initialAudits);
      getAuditsSessions(initialAudits);
      getTrainerSessions();
    }
  }, [getAudits, getAuditsSessions, getTrainerSessions, formerData]);

  useEffect(() => {
    // Préparer selectedSessions en fonction des trainerSessions
    const selected = {};
    for (const auditId in auditsSessions) {
      if (trainerSessions && trainerSessions.length > 0) {
        selected[auditId] = auditsSessions[auditId].filter((session) =>
          trainerSessions.some(
            (trainerSession) =>
              trainerSession.syna_sessions_ids == session.id &&
              trainerSession.syna_formation_id == session.formations_id &&
              !trainerSession.is_deleted
          )
        );
      }
    }
    setSelectedSessions(selected);
  }, [auditsSessions, trainerSessions]);

  useEffect(() => {
    if (trainerSessions && trainerSessions.length > 0) {
      const initialSessions = {};
      for (const session of trainerSessions) {
        if (!initialSessions[session.audit]) {
          initialSessions[session.audit] = [];
        }
        initialSessions[session.audit].push(session);
      }
      setPreviousSelectedSessions(initialSessions);
    }
  }, [trainerSessions]);

  const handleSaveSessions = async (epp_id, newValue) => {
    try {
      if (!newValue || newValue.length === 0) {
        await FormersApi.deleteAllSessionsOfTrainer(
          user.admin_id,
          formerData.id,
          epp_id
        );
        return;
      }

      const previousSessions = previousSelectedSessions[epp_id] || [];
      const newSessions = newValue;

      const addedSessions = newSessions.filter(
        (session) =>
          !previousSessions.some((prevSession) => prevSession.id === session.id)
      );
      const removedSessions = previousSessions.filter(
        (prevSession) =>
          !newSessions.some((session) => session.id === prevSession.id)
      );

      for (let session of addedSessions) {
        const session_id = session.id;
        const formation_id = session.formations_id;
        const session_number = session.number_session;

        await FormersApi.setTrainerSessions(
          user.admin_id,
          epp_id,
          formerData.id,
          session_id,
          formation_id,
          session_number
        );
      }

      for (let session of removedSessions) {
        const session_id = session.id;
        await FormersApi.deleteSessionOfTrainer(
          user.admin_id,
          epp_id,
          session_id
        );
      }

      setPreviousSelectedSessions((prev) => ({
        ...prev,
        [epp_id]: newSessions,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect(() => {
  //   console.log(previousSelectedAudits);
  //   console.log(auditsSessions);
  // }, [previousSelectedAudits, auditsSessions]);

  return (
    <Card>
      <CardHeader title="Informations" />
      <Divider />
      <List disablePadding>
        <ListItem
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 0,
            }}
            disableTypography
            primary={
              <Typography sx={{ minWidth: 180 }} variant="subtitle2">
                Email
              </Typography>
            }
            secondary={
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000,
                }}
              >
                {modify.email ? (
                  <TextField
                    size="small"
                    focused
                    autoFocus
                    defaultValue={formerData.email}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleModify("email");
                      }
                    }}
                    onBlur={() => {
                      handleModify("email");
                    }}
                    onChange={(e) => setModifyText(e.target.value)}
                  />
                ) : (
                  <Typography
                    onDoubleClick={() => {
                      setModify((modify) => ({
                        ...modify,
                        email: true,
                      }));
                      setModifyText(formerData.email);
                    }}
                    color="textSecondary"
                    variant="body2"
                  >
                    {formerData.email}
                  </Typography>
                )}
              </Box>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 0,
            }}
            disableTypography
            primary={
              <Typography sx={{ minWidth: 180 }} variant="subtitle2">
                EPP associés
              </Typography>
            }
            secondary={
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000,
                }}
              >
                <Autocomplete
                  sx={{ minWidth: 300 }}
                  multiple
                  id="audits-autocomplete"
                  options={audits}
                  getOptionLabel={(audit) => audit.name}
                  value={
                    formerData && formerData.epp_ids && audits
                      ? audits.filter((audit) =>
                          formerData.epp_ids.includes(audit.id)
                        )
                      : []
                  }
                  onChange={(event, newValue) => {
                    // Convertir les noms des audits en epp_ids
                    const newEppIds = newValue
                      .map((audit) => audit.id)
                      .join(",");
                    handleModify("epp_ids", newEppIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Sélectionnez les epp"
                    />
                  )}
                />
              </Box>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 0,
            }}
            disableTypography
            primary={
              <Typography sx={{ minWidth: 180 }} variant="subtitle2">
                Sessions associés
              </Typography>
            }
            secondary={
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000,
                }}
              >
                <List>
                  {formerData &&
                    formerData.epp_ids &&
                    audits
                      .filter((audit) => formerData.epp_ids.includes(audit.id))
                      .map((audit) => (
                        <div key={audit.id}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {`Sessions : ${audit.name}`}
                                </Typography>
                              }
                              secondary={
                                auditsSessions &&
                                auditsSessions[audit.id] &&
                                auditsSessions[audit.id].length > 0 ? (
                                  <Autocomplete
                                    multiple
                                    id={`sessions-autocomplete-${audit.id}`}
                                    options={auditsSessions[audit.id]}
                                    getOptionLabel={(session) =>
                                      session.label
                                        ? session.label
                                        : `Session N°${
                                            session.number_session
                                          } (${format(
                                            new Date(session.start_date),
                                            "dd MMMM yyyy",
                                            { locale: fr }
                                          )} - ${format(
                                            new Date(session.end_date),
                                            "dd MMMM yyyy",
                                            { locale: fr }
                                          )})`
                                    }
                                    value={selectedSessions[audit.id] || []}
                                    onChange={(event, newValue) => {
                                      setSelectedSessions((prev) => ({
                                        ...prev,
                                        [audit.id]: newValue,
                                      }));
                                      handleSaveSessions(audit.id, newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label={`Sélectionnez les sessions pour ${audit.name}`}
                                      />
                                    )}
                                  />
                                ) : (
                                  <ListItem>
                                    <ListItemText primary="Pas de sessions disponibles pour le moment" />
                                  </ListItem>
                                )
                              }
                            />
                          </ListItem>
                          <Divider />
                        </div>
                      ))}
                </List>
              </Box>
            }
          />
        </ListItem>
      </List>
    </Card>
  );
};
