class ConversationApi {
    async getConversations(adminId) {
        const url = `${process.env.REACT_APP_BACK}/api/chat/conversations-list/`
        const values = {
            "admin_id": adminId
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        })
        const json = await resp.json()
        return Promise.resolve(json)
    };

    async getChatMessages(trainerId, conversationId) {
        const url = `${process.env.REACT_APP_BACK}/api/chat/messages-list/`
        const values = {
            "admin_id": trainerId,
            "role": "formateur",
            "conversation_id": conversationId
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        })
        const json = await resp.json()
        return Promise.resolve(json)
    }

    async sendMessage(authorId, content, recipientId) {
        const url = `${process.env.REACT_APP_BACK}/api/chat/admins/send-messages/`
        const values = { 
            "admin_id": authorId,
            "recipient_id": recipientId,
            "type_author": "formateur",
            "type_recipient": "apprenant",
            "content": content
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(values),

        })
        const json = await resp.json()
        return Promise.resolve(json)
    }

    async readMessages(adminId, conversationId) {
        const url = `${process.env.REACT_APP_BACK}/api/chat/read-message/`
        const values = { 
            "admin_id": adminId,
            "conversation_id": conversationId
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(values),

        })
        const json = await resp.json()
        return Promise.resolve(json)
    }

    async getLearnerOptions(adminId, auditId) {
        const url = `${process.env.REACT_APP_BACK}/api/chat/learners-list/`
        const values = { 
            "admin_id": adminId,
            "audit_id": auditId
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(values),

        })
        const json = await resp.json()
        return Promise.resolve(json)
    }


    async getLastMessages(adminId) {
        const url = `${process.env.REACT_APP_BACK}/api/chat/last-messages-list/`
        const values = { 
            "admin_id": adminId
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(values),

        })
        const json = await resp.json()
        return Promise.resolve(json)        
    }
}

export const conversationApi = new ConversationApi()