import { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Toolbar,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AccountPopover } from './account-popover';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../../hooks/use-auth';
import { NotificationsPopover } from './notifications-popover';
import { Chat  } from '@mui/icons-material';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3]
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none'
    })
}));

const AccountButton = () => {
  const {user} = useAuth()
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 0,
          mr: 2
        }}
        
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 5,
            display: 'flex',
            overflow: 'hidden',
            p: 0,
            height: 50,
            width: 100
            
          }}
          style={{ background: '#F1F1F1'}}
          // (theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : "#1c2531"
        >
          <Avatar
            sx={{
              height: 50,
              width: 50,
              mx: 0
            }}
            src={user.avatar}
          >
            <AccountCircleIcon fontSize="small" />
          </Avatar>
          <ListItemIcon>
            <SettingsIcon 
              fontSize="small" 
              sx={{
                height: 20,
                width: 30,
                mx: 1.5,
                color: process.env.REACT_APP_PRIMARY_COLOR
              }}
            />
          </ListItemIcon>
        </Box>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, ...other } = props;
  const {user} = useAuth();
  const navigate = useNavigate()

  return (
    <>
      <DashboardNavbarRoot
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
          }}
        >
          <Box sx={{ overflow: 'hidden', display: 'inline-block', height: 80 }}>
            <Box
              component="img"
              sx={{
                  mt: 0.7,
                  ml: 2,
                  width : '200px',
                  height: '50px',
              }}
              alt="Kalanke"
              src={process.env.REACT_APP_LOGO_NAVBAR}
            />
          </Box>
          {user.id_service === 0 ? 
            <Typography 
              variant="h5"
              // color="#D8C691"
              sx={{color: (theme) =>
                theme.palette.mode === "dark" ? "black" : "white", ml:3, mt:0.5}}
            >
              Espace Administrateur
            </Typography> 
          : 
            <Typography 
              variant="h5" 
              sx={{color: (theme) =>
                theme.palette.mode === "dark" ? "black" : "white", ml:3, mt:0.5}}
            >
              Espace Formateur
            </Typography>
          }
          <Box sx={{ flexGrow: 1 }} />
          <NotificationsPopover />
          <Box
            component={ButtonBase}
            sx={{
              mr: 2
            }}
          >
            <Chat
              sx={{ fontSize: 40 }}
              onClick={() => {
                navigate("/dashboard/discussion")
              }}
            />
          </Box>
          <AccountButton/>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
