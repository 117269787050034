class PatientsFolderApi {
    
    async getAllPatientsFolder(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_folder/getAllPatientsFolder/`
        const values = {
            "admin_id": admin_id,
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getPatientsFolderData(admin_id, patientsFolderId) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_folder/getData/`
        const values = {
            "admin_id": admin_id,
            "patients_folder_id": patientsFolderId
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async modifyPatientsFile(admin_id, patientsFile) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_file/modify/`
        const values = {
            "admin_id": admin_id,
            "patients_file_id": patientsFile.id,
            // "audits_id": patientsFile.audits_id,
            "name": patientsFile.patients_file.name,
            "professions": patientsFile.patients_file.professions,
            "epps": patientsFile.patients_file.epps,
            // "program_number": patientsFile.program_number,
            // "tlms_id": patientsFile.tlms_id
            "title": patientsFile.patients_folder_datas.title
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyPatientsFolder(admin_id, patientsFile, index) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_folder/modify/`
        // console.log(patientsFile)
        const values = {
            "admin_id": admin_id,
            "patients_file_id": patientsFile.id,
            "patients_folder_id": patientsFile.patients_folder_datas[index]['id'],
            // "audits_id": patientsFile.audits_id,
            // "program_number": patientsFile.program_number,
            // "tlms_id": patientsFile.tlms_id
            "title": patientsFile.patients_folder_datas[index]['title'],
            "description": patientsFile.patients_folder_datas[index]['description'],
            "required": patientsFile.patients_folder_datas[index]['required']
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deletePatientsFolder(admin_id, patients_folder_id) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_folder/delete/`
        const values = {
            "admin_id": admin_id,
            "patients_folder_id": patients_folder_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async createPatientsFolder(admin_id, patients_file_id) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_folder/create/`
        const values = {
            "admin_id": admin_id,
            "patients_file_id": patients_file_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async createPatientsFile(admin_id, name) {
        const url = `${process.env.REACT_APP_BACK}/api/patients_file/create/`
        const values = {
            "admin_id": admin_id,
            "name": name,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
}

export const patientsFolderApi = new PatientsFolderApi();