/* eslint-disable  prefer-destructuring */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@material-ui/core/styles';
import { Box, Collapse, TableCell, TableRow, tableCellClasses } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { Tooltip, Typography } from '@mui/material';
import ProgressIndicator from '../../progress-indicator';
import { useSettings } from '../../../hooks/use-settings';

function QuestionSatisfactionRow({ questionRow, i, rowLength, courseId }) {
  const [open] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const theme = useTheme();
  const { settings } = useSettings()
  
  // Définir les couleurs en fonction du thème
  const chartColors = {
    light: {
      label: process.env.REACT_APP_PRIMARY_COLOR, //theme.palette.primary.main,
      // Ajoutez d'autres couleurs si nécessaire
    },
    dark: {
      label: 'white', // theme.palette.primary.dark,
      // Ajoutez d'autres couleurs si nécessaire
    },
  };

  // Variable définissant les paramètres du graphique des données des questions
  const cOptions = {
    series: [
      {
        name: `Choix des apprenants`,
        data: questionRow.answers
        // .filter((sd) => sd.course_id === courseId)
        .map((sd) => (
          { 
            x: sd.answer,
            y: sd.numberOfSelection,
            goals: [
              {
                name: 'Nombre de choix',
                value: sd.numberOfSelection,
                strokeWidth: 2,
                strokeDashArray: 2,
              },  
              {
                name: 'Total des apprenants',
                value: questionRow.numberOfRespondants,
                strokeWidth: 2,
                strokeDashArray: 2,
              },
            ]
          }
        ))
      },
      
    ],
    options: {
      yaxis: {
        labels: {
          style: {
            fontSize: 14,
            colors: chartColors[settings.theme].label
          }
        },
      },
      xaxis: {
        max: (questionRow.numberOfRespondants),
        tickAmount: 1,
      },
      chart: {
        background: 'transparent',
        id: `basic-bar${Math.random()}`,
        toolbar: {
          show: true
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        toolbar: {
          tools: {
            download: false // Désactiver la mini-fenêtre de téléchargement
          }
        },
      },
      tooltip: {
        theme: settings.theme,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: [process.env.REACT_APP_SECONDARY_COLOR],
      dataLabels: {
        style: {
          fontSize: 14,
        },
        formatter: (val, { w, seriesIndex, dataPointIndex }) => {
          const goals = w.config.series[seriesIndex].data[dataPointIndex].goals;
          if (goals && goals.length) {
            return goals[0].value < goals[1].value / 16 ? `${goals[0].value}` :  `${goals[0].value} / ${goals[1].value}`;
          }
          return val;
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Choix des apprenants'],
        fontSize: '14px',
        labels: {
          colors: chartColors[settings.theme].label
        },
        position: 'top',
        offsetX: 0,
      },
    },
  };
  return (
    <>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: rowLength - 1 === i ? 'none' : ''
          }
        }}
      >
        <TableCell>
          <Box sx={{ display: 'flex', gap: 2}}>
            <Tooltip title="Question fermée">
              <HelpIcon
                fontSize="small"
                sx={{
                  '@media (max-width: 530px)': {
                    display: 'none',
                  }
                }}
              />
            </Tooltip>
            <Typography fontSize="18">{questionRow.title}</Typography>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            fontSize: 15,
            color: theme.palette.primary.main,
            paddingY: 2,
            '@media (max-width: 530px)': {
              padding: 0
            },
          }}
        >
          <ProgressIndicator done={parseFloat(questionRow.satisfactionPercentage)} 
            height="20px" 
            fontSize="16" 
            containerBcColor={open ? theme.palette.background.paper : theme.palette.background.default} 
          />
        </TableCell>
        <TableCell
          sx={{
            '@media (max-width: 530px)': {
              padding: 0
            }
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setDetailsOpen(!detailsOpen)}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: 'none'
          }
        }}
      >
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            '@media (max-width: 530px)': {
              padding: 0,
            }
          }}
          colSpan={6}
        >
          <Collapse 
            in={detailsOpen} 
            timeout="auto" 
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <ReactApexChart
                options={cOptions.options}
                series={cOptions.series}
                type="bar"
                height={200}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

QuestionSatisfactionRow.propTypes = {
  questionRow: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  rowLength: PropTypes.number.isRequired
};

export default QuestionSatisfactionRow;