/* eslint-disable react/jsx-max-props-per-line */
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

const ProgressIndicator = ({ done, height, containerBcColor, fontSize }) => {
  const theme = useTheme();
  const interpretColorLevel = () => {
    const level = typeof done === 'string' ? parseFloat(done) : done;
    if (level <= 25) {
      return theme.palette.error.main;
    }
    if (level > 25 && level <= 50) {
      return theme.palette.warning.main;
    }
    return theme.palette.success.main;
  };

  const progressStyles = {
    backgroundColor: '#CCCCCC',
    borderRadius: '20px',
    margin: '0 0 0 auto',
    height,
    width: '250px',
  };
  const progressDoneStyles = {
    borderRadius: '20px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0',
    height: '100%',
    width: '0',
    transition: '2s ease',
    backgroundColor: interpretColorLevel()
  };
  const circleProgressStyles = {
    backgroundColor: containerBcColor,
    borderRadius: '100%',
    margin: '0 0 0 auto',
    height: '40px',
    width: '40px',
  };
  const circleProgressDoneStyles = {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    backgroundColor: interpretColorLevel()
  };
  const [style, setStyle] = useState({});
  useEffect(() => {
    const timeout = setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`
      };
      setStyle(newStyle);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [done]);

  return (
    <>
      <Box
        sx={{
          '@media (max-width: 530px)': {
            display: 'none'
          }
        }}
        className="progress"
        style={progressStyles}
      >
        <Box className="progress-done" style={{ ...progressDoneStyles, ...style }}>
          <Typography variant="p" style={{ fontSize, fontWeight: 600 }}>{done}</Typography>
          %
        </Box>
      </Box>
      <Box
        sx={{
          display: 'none',
          '@media (max-width: 530px)': {
            display: 'block'
          }
        }}
        className="progress"
        style={circleProgressStyles}
      >
        <Box className="progress-done" style={{ ...circleProgressDoneStyles }}>
          <Typography variant="p" style={{ fontSize, fontWeight: 600 }}>{done}</Typography>
          %
        </Box>
      </Box>
    </>
  );
};

ProgressIndicator.propTypes = {
  done: PropTypes.number.isRequired,
  height: PropTypes.string.isRequired,
  containerBcColor: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired
};

export default ProgressIndicator;
