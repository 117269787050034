import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Drawer, Typography, Avatar, useMediaQuery } from '@mui/material';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { useAuth } from '../../hooks/use-auth';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import { AdfScanner, FlakyOutlined } from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import ListIcon from '@mui/icons-material/List';
import HomeIcon from '@mui/icons-material/Home';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import GroupIcon from '@mui/icons-material/Group';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CogIcon from '../../icons/Cog';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ChatIcon from '@mui/icons-material/Chat';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

export const DashboardSidebar = (props) => {
  const { onClose, open, anchorEl, ...other } = props;
  const { user } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  const [openSide, setOpenSide] = useState(false);
  const [sections, setSections] = useState([]);
  const handleOpen = () => setOpenSide(true);
  const handleClose = () => setOpenSide(false);

  // const sections = (useMemo(() => getSections(t), [t]));

  useEffect(() => {
  if(user.id_service === 0){
    setSections([
      {
        title: 'Analyses',
        items: [
          {
            title: ('Tableau de bord'),
            path: '/dashboard',
            icon: <HomeIcon fontSize="small" />
          },
          {
            title: ('Agenda'),
            path: '/dashboard/agenda',
            icon: <DateRangeIcon fontSize="small" />
          },
          {
            title: ('Scores'),
            path: '/dashboard/scores',
            icon: <StarOutlineOutlinedIcon fontSize="small" />
          },
        ]
      },
      {
        title: 'Commercial',
        items: [
          {
            title: ('Apprenants'),
            path: '/dashboard/customers',
            icon: <GroupIcon fontSize="small" />,
          },
          {
            title: ('Créer un apprenant'),
            path: '/dashboard/create',
            icon: <ControlPointIcon fontSize="small" />,
          },
        ]
      },
      {
        title: 'Évaluations',
        items: [
          {
            title: ('Formations'),
            path: '/dashboard/formations',
            icon: <ArticleIcon fontSize="small" />,
          },
          {
            title: ('Audits'),
            path: '/dashboard/audits',
            icon: <VerifiedOutlinedIcon fontSize="small" />,
          },
          // {
          //   title: ('TCS'),
          //   path: '/dashboard/tcs',
          //   icon: <FlakyOutlined fontSize="small" />,
          // },
          {
            title: ('Dossiers patients'),
            path: '/dashboard/patients_folder',
            icon: <TopicOutlinedIcon fontSize="small" />,
          },
        ]
      },
      {
        title: 'Qualité',
        items: [
          {
            title: ('Satisfaction'),
            path: '/dashboard/survey',
            icon: <SentimentSatisfiedAltIcon fontSize="small" />
          }
        ]
      },
      {
        title: 'Sessions',
        items: [
          {
            title: ('Liste des sessions'),
            path: '/dashboard/session',
            icon: <ListIcon fontSize="small" />
          },
          {
            title: ('Liste des formateurs'),
            path: '/dashboard/formateur',
            icon: <PeopleOutlineIcon fontSize="small" />
          }
        ]
      },
      {
        title: 'Autres',
        items: [
          {
            title: ('Créer un admin'),
            path: '/dashboard/createAdmin',
            icon: <SupervisorAccountIcon fontSize="small" />,
          },
          {
            title: ('Activités'),
            path: '/dashboard/activities',
            icon: <TrendingUpIcon fontSize='small'/>,
          },
          {
            title: ('Erreurs'),
            path: '/dashboard/errors',
            icon: <WarningRoundedIcon fontSize="small" />,
          },
          {
            title: ('Forum'),
            path: '/dashboard/forum',
            icon: <ForumIcon fontSize="small" />
          },
          {
            title: ('Facturation'),
            path: '/dashboard/facturation',
            icon: <AdfScanner fontSize="small" />,
          },
          {
            title: ('Paramètres'),
            path: '/dashboard/params',
            icon: <CogIcon fontSize="small" />,
          },
        ]
      }
  ]);
  } else if (user.id_service === 1){
    setSections([
      {
        title: 'Analyses',
        items: [
          {
            title: ('Tableau de bord'),
            path: '/dashboard',
            icon: <HomeIcon fontSize="small" />
          },
          {
            title: ('Agenda'),
            path: '/dashboard/agenda',
            icon: <DateRangeIcon fontSize="small" />
          },
        ]
      },
      {
        title: 'Évaluations',
        items: [
          {
            title: ('Audits'),
            path: '/dashboard/audits',
            icon: <VerifiedOutlinedIcon fontSize="small" />,
          },
          // {
          //   title: ('TCS'),
          //   path: '/dashboard/tcs',
          //   icon: <FlakyOutlined fontSize="small" />,
          // },
          {
            title: ('Apprenants'),
            path: '/dashboard/customers',
            icon: <GroupIcon fontSize="small" />,
          },
          // {
          //   title: ('Dossiers patients'),
          //   path: '/dashboard/patients_folder',
          //   icon: <TopicOutlinedIcon fontSize="small" />,
          // },
        ]
      },
      {
        title: 'Sessions',
        items: [
          {
            title: ('Liste des sessions'),
            path: '/dashboard/session',
            icon: <ListIcon fontSize="small" />
          }
        ]
      },
      {
        title: 'Autres',
        items: [
          // {
          //   title: ('Forum'),
          //   path: '/dashboard/forum',
          //   icon: <ForumIcon fontSize="small" />
          // },
          {
            title: ('Forum'),
            path: '/dashboard/forum',
            icon: <ForumIcon fontSize="small" />
          },
          // {
          //   title: ('Paramètres'),
          //   path: '/dashboard/params',
          //   icon: <CogIcon fontSize="small" />,
          // },
        ]
      }
  ]);
  }
  },[user])

  const content = (
    <Box
      {... other}
      anchorEl={anchorEl}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height : '160%',
        paddingTop: 9,
        backgroundColor: (theme) => theme.palette.mode === 'dark' ? process.env.REACT_APP_PRIMARY_COLOR : "#FFFFFF"
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 5,
            display: 'flex',
            overflow: 'hidden',
            p: 2,
            background: (theme) => theme.palette.mode === 'dark' ? "#1E2022" : process.env.REACT_APP_PRIMARY_COLOR
          }}
        >
          <Avatar
            src={user.avatar}
            sx={{
              height: 50,
              width: 50
            }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography
              variant="h6"
              sx = {{color: "#FFFFFF"}}
            >
              {user.firstname} {user.lastname}
            </Typography>
            {user.id_service === 0 ? 
              <Typography
                color={process.env.REACT_APP_SECONDARY_COLOR}
                variant="body2"
              >
                Administrateur
              </Typography> 
            : 
              <Typography
                sx={{ color: (theme) =>
                theme.palette.mode === "dark" ? "white" : process.env.REACT_APP_SECONDARY_COLOR }}
                variant="body2"
              >
                Formateur
              </Typography>
            }
          </Box>
        </Box>
      </Box>
      <Divider/>
      <Box
        sx={{ flexGrow: 1 }}
      >
        {sections.map((section) => (
          <DashboardSidebarSection
            key={section.title}
            path={location.pathname}
            title={section.title}
            sx={{
              mt: 1,
              '& + &': {
                mt: 0,
              }
            }}
            {...section}
          />
        ))}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'white',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 280,
            
          }
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open sidebar"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer 
        anchor="left"
        open={openSide} 
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 280,
          }
        }}
      >
          {content}
      </Drawer>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
