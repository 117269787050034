import { useState } from "react";
import { useAuth } from "../../../hooks/use-auth";

import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import DiscussionSection from "./discussion-section";
import NewConversation from "./new-conversation";


const Discussion = () => {
  const [newConversationByAdmin, setNewConversationByAdmin] = useState(null)

  const { user } = useAuth();
  const [auditId, setAuditId] = useState(user && user.epps.length > 0 ? user.epps[0].id : null)

  return (
    <>
      <Box
        component="main"
        sx={{
          pt: 11,
          pb: 2
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#F5F6FC" : "#1c2531",
                  }}
                  variant="h4"
                >
                  Discussion 
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: -1,
                p: 3,
              }}
            >
                <NewConversation 
                  newConversationByAdmin={newConversationByAdmin} 
                  setNewConversationByAdmin={setNewConversationByAdmin} 
                  auditId={auditId}
                  setAuditId={setAuditId}
                />
            </Box>
            <DiscussionSection                   
              newConversationByAdmin={newConversationByAdmin} 
              setNewConversationByAdmin={setNewConversationByAdmin} 
              auditId={auditId}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Discussion;
