import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { coursesApi } from "../../../api/course-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useCallback, useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { feedBacksApi } from "../../../api/feedback-api";
import { ResultsFirstTurn } from "./results-first-turn";
import { ResultsSecondTurn } from "./results-second-turn";
import { RecapFirstTurn } from "./recap-first-turn";
import { RecapSecondTurn } from "./recap-second-turn";
import { GlobalStatsFirstTurn } from "./global-stats-first-turn";
import { GlobalStatsSecondTurn } from "./global-stats-second-turn";
import { ImprovementsObjectives } from "./improvements-objectives";
import { SendFeedbackFirstTurn } from "./send-Feedback-first-turn";
import { SendFeedbackSecondTurn } from "./send-feedback-second-turn";
import { SendFeedbackImprovements } from "./send-feedback-improvements";
import CloseIcon from "@mui/icons-material/Close";

const tabs = [
  { label: "Tour 1", value: "Tour 1" },
  { label: `Objectifs d'améliorations`, value: `Objectifs d'améliorations` },
  { label: "Tour 2", value: "Tour 2" },
];

export const CustomerFeedbackAudit = (props) => {
  const {
    open,
    handleClose,
    audit_id,
    customer_id,
    start_session,
    end_session,
    courseId,
    auditName,
    customerDetails,
    feedbackFirstTurnSent,
    feedbackSecondTurnSent,
    feedbackImprovementSent,
    setFeedbackFirstTurnSent,
    setFeedbackSecondTurnSent,
    setFeedbackImprovementSent,
    setUpdateFeedbackState
  } = props;
  const isMounted = useMounted();
  const [answer, setAnswer] = useState([]);
  const [feedbackId, setFeedbackId] = useState(null);
  const [evaluationFormateur, setEvaluationFormateur] = useState(null);
  const [feedbackTextFirstTurn, setFeedbackTextFirstTurn] = useState("");
  const [feedbackTextSecondTurn, setFeedbackTextSecondTurn] = useState("");
  const [feedbackTextAmelio, setFeedbackTextAmelio] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSecondTurn, setIsEditingSecondTurn] = useState(false);
  const [isEditingAmelio, setIsEditingAmelio] = useState(false);
  const [isTurn1Filled, setIsTurn1Filled] = useState(false);
  const [isTurn2Filled, setIsTurn2Filled] = useState(false);
  const [dataFirstTurn, setDataFirstTurn] = useState([]);
  const [dataSecondTurn, setDataSecondTurn] = useState([]);
  const [dataImprovements, setDataImprovements] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [currentTab, setCurrentTab] = useState(`Tour 1`);
  const { user } = useAuth();

  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [selectedCriteriaSecondTurn, setSelectedCriteriaSecondTurn] =
    useState(null);

  const [globalAnalyseFirstTurn, setGlobalAnalyseFirstTurn] = useState([]);
  const [globalAnalyseSecondTurn, setGlobalAnalyseSecondTurn] = useState([]);
  const [sessionAnalyseFirstTurn, setSessionAnalyseFirstTurn] = useState([]);
  const [sessionAnalyseSecondTurn, setSessionAnalyseSecondTurn] = useState([]);
  const [criteriasNonComplianceFirstTurn, setCriteriasNonComplianceFirstTurn] = useState([]);
  const [criteriasNonComplianceSecondTurn, setCriteriasNonComplianceSecondTurn] = useState([]);
  const [generatedFeedbacks, setGeneratedFeedbacks] = useState([]);
  const [gapFirstTurn, setGapFirstTurn] = useState([]);
  const [gapSecondTurn, setGapSecondTurn] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [correctionFirstTurn, setCorrectionFirstTurn] = useState(null);
  const [correctionSecondTurn, setCorrectionSecondTurn] = useState(null);
  const [correctionAmelio, setCorrectionAmelio] = useState(null);

  useEffect(() => {
    if (
      dataFirstTurn &&
      dataFirstTurn.criterias &&
      dataFirstTurn.criterias.length > 0
    ) {
      setSelectedCriteria(dataFirstTurn.criterias[0]);
    }
  }, [dataFirstTurn]);

  useEffect(() => {
    if (
      dataSecondTurn &&
      dataSecondTurn.criterias &&
      dataSecondTurn.criterias.length > 0
    ) {
      setSelectedCriteriaSecondTurn(dataSecondTurn.criterias[0]);
    }
  }, [dataSecondTurn]);

  const handleTabsChange = (value) => {
    setCurrentTab(value);
  };
  
  useEffect(async () => {
    try {
      const data = await feedBacksApi.getGeneratedFeedbacks(user.admin_id, courseId);
      if (isMounted()) {
        setGeneratedFeedbacks(data)
        data.message.map((i)=>{
          setFeedbackId(i.id);
          setEvaluationFormateur(i.evaluation_formateur);
          if (i.turn === 1 && i.type_generated === 1){
            setFeedbackTextFirstTurn(i.text_generated)
          }
          if (i.turn === 2 && i.type_generated === 1){
            setFeedbackTextSecondTurn(i.text_generated)
          }
          if (i.turn === 1 && i.type_generated === 2){
            setFeedbackTextAmelio(i.text_generated)
          }
          if (i.turn === 1 && i.type_generated === 1 && (i.isvalid === true && i.correction !== null)){
            setFeedbackFirstTurnSent(true);
            setCorrectionFirstTurn(i.correction)
          }
          if (i.turn === 2 && i.type_generated === 1 && (i.isvalid === true && i.correction !== null)){
            setFeedbackSecondTurnSent(true);
            setCorrectionSecondTurn(i.correction)
          }
          if (i.turn === 1 && i.type_generated === 2 && (i.isvalid === true && i.correction !== null)){
            setFeedbackImprovementSent(true);
            setCorrectionAmelio(i.correction)
          }
        })
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(async () => {
    try {
      const data = await feedBacksApi.determinateFeedback(
        courseId,
        user.admin_id
      );
      const feedback_turn1_isfilled = data.is_turn1_filled;
      const feedback_turn2_isfilled = data.is_turn2_filled;
      if (isMounted()) {
        setIsTurn1Filled(feedback_turn1_isfilled);
        setIsTurn2Filled(feedback_turn2_isfilled);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(async () => {
    try {
      const data = await coursesApi.getGlobalAnalyse(
        "backoffice",
        user.admin_id,
        courseId,
        1
      );
      if (isMounted()) {
        setGlobalAnalyseFirstTurn(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(async () => {
    try {
      const data = await coursesApi.getGlobalAnalyse(
        "backoffice",
        user.admin_id,
        courseId,
        2
      );
      if (isMounted()) {
        setGlobalAnalyseSecondTurn(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(async () => {
    try {
      const data = await coursesApi.getSessionAnalyse(
        user.admin_id,
        courseId,
        1
      );
      if (isMounted()) {
        setSessionAnalyseFirstTurn(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(async () => {
    try {
      const data = await coursesApi.getSessionAnalyse(
        user.admin_id,
        courseId,
        2
      );
      if (isMounted()) {
        setSessionAnalyseSecondTurn(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getAnswer = useCallback(async () => {
    try {
      const data = await coursesApi.getAllAnswer(
        user.admin_id,
        audit_id,
        customer_id,
        start_session,
        end_session
      );
      if (isMounted()) {
        setAnswer(data.data);
        setDataImprovements(data.data[0].improvements)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getFirstTurnStats = useCallback(async () => {
    try {
      const result = await feedBacksApi.getPercentRecap(
        courseId,
        1,
        user.admin_id
      );
      if (isMounted()) {
        setDataFirstTurn(result);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getSecondTurnStats = useCallback(async () => {
    try {
      const result = await feedBacksApi.getPercentRecap(
        courseId,
        2,
        user.admin_id
      );
      if (isMounted()) {
        setDataSecondTurn(result);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getCriteriasNonComplianceFirstTurn = useCallback(async () => {
    try {
      const data = await feedBacksApi.getCriteriasNonCompliance(
        courseId,
        1,
      );
      if (isMounted()) {
        setCriteriasNonComplianceFirstTurn(data.message)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getCriteriasNonComplianceSecondTurn = useCallback(async () => {
    try {
      const data = await feedBacksApi.getCriteriasNonCompliance(
        courseId,
        2,
      );
      if (isMounted()) {
        setCriteriasNonComplianceSecondTurn(data.message)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getAnswer();
    getFirstTurnStats();
    getSecondTurnStats();
    getCriteriasNonComplianceFirstTurn();
    getCriteriasNonComplianceSecondTurn();
  }, [getAnswer, getFirstTurnStats, getSecondTurnStats, getCriteriasNonComplianceFirstTurn, getCriteriasNonComplianceSecondTurn]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullScreen
        sx={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <AppBar sx={{ position: "relative", backgroundColor: process.env.REACT_APP_NEW_PRIMARY_COLOR }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ m: "auto", flex: 1, whiteSpace: "nowrap" }}
              variant="h6"
              component="div"
            >
              Liste des réponses
            </Typography>
            <Grid container justifyContent="center">
              {" "}
              {/* Pour centrer les onglets */}
              <Grid item xs={12} sm={8}>
                {" "}
                {tabs.map((tab) => (
                  <Button
                    key={tab.value}
                    variant={
                      currentTab === tab.value ? "contained" : "outlined"
                    }
                    onClick={() => handleTabsChange(tab.value)}
                    sx={{
                      borderRadius: 999, // Valeur suffisamment grande pour rendre les boutons arrondis
                      marginRight: 1,
                      textTransform: "none",
                      width: "30%",
                      color: currentTab === tab.value ? "black" : "white", // Couleur du texte
                      backgroundColor:
                        currentTab === tab.value ? "white" : "transparent", // Couleur de fond du bouton appuyé
                      boxShadow:
                        currentTab !== tab.value ? "0 0 0 1px white" : "none", // Ombre blanche sur le contour pour les boutons non sélectionnés
                    }}
                  >
                    {tab.label}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ backgroundColor: process.env.REACT_APP_NEW_SECONDARY_COLOR }}>
          <DialogContentText>
            {answer && answer.length > 0
              ? answer.map((data, index) => (
                  <>
                    {/* Affichage des résultats pour le Tour 1 */}
                    {data.turn === 1 && currentTab === "Tour 1" ? (
                      <ResultsFirstTurn
                        turn={data.turn}
                        resultData={data.result}
                        setDialogOpen={setDialogOpen}
                        dialogOpen={dialogOpen}
                        auditName={auditName}
                        dataFirstTurn={dataFirstTurn}
                        courseId={courseId}
                        gapFirstTurn={gapFirstTurn}
                        setGapFirstTurn={setGapFirstTurn}
                      />
                    ) : (
                      ""
                    )}

                    {/* Affichage des résultats pour le Tour 2 */}
                    {data.turn === 2 && currentTab === "Tour 2" ? (
                      <ResultsSecondTurn
                        turn={data.turn}
                        resultData={data.result}
                        setDialogOpen={setDialogOpen}
                        dialogOpen={dialogOpen}
                        auditName={auditName}
                        dataSecondTurn={dataSecondTurn}
                        courseId={courseId}
                        gapSecondTurn={gapSecondTurn}
                        setGapSecondTurn={setGapSecondTurn}
                      />
                    ) : (
                      ""
                    )}

                    {/* Récapitulatif */}
                    {data.turn === 1 && currentTab === "Tour 1" ? (
                      <RecapFirstTurn
                        dataFirstTurn={dataFirstTurn}
                        sessionAnalyseFirstTurn={sessionAnalyseFirstTurn}
                        globalAnalyseFirstTurn={globalAnalyseFirstTurn}
                        turn={data.turn}
                        resultData={data.result[0]}
                        selectedCriteria={selectedCriteria}
                        setSelectedCriteria={setSelectedCriteria}
                      />
                    ) : (
                      ""
                    )}

                    {/* Récapitulatif */}
                    {data.turn === 2 && currentTab === "Tour 2" ? (
                      <RecapSecondTurn
                        dataSecondTurn={dataSecondTurn}
                        sessionAnalyseSecondTurn={sessionAnalyseSecondTurn}
                        globalAnalyseSecondTurn={globalAnalyseSecondTurn}
                        turn={data.turn}
                        resultData={data.result[0]}
                        selectedCriteriaSecondTurn={selectedCriteriaSecondTurn}
                        setSelectedCriteriaSecondTurn={
                          setSelectedCriteriaSecondTurn
                        }
                      />
                    ) : (
                      ""
                    )}

                    {/* Pourcentage Global Tour 1 */}
                    {data.result[0] &&
                    data.turn === 1 &&
                    currentTab === "Tour 1" &&
                    dataFirstTurn.criterias ? (
                      <GlobalStatsFirstTurn
                        dataFirstTurn={dataFirstTurn}
                        criteriasNonComplianceFirstTurn={criteriasNonComplianceFirstTurn}
                      />
                    ) : (
                      ""
                    )}

                    {/* Feedback */}
                    {data.result[0] &&
                      currentTab === "Tour 1" &&
                      data.turn === 1 &&
                      isTurn1Filled && (
                        <SendFeedbackFirstTurn
                          feedbackId={feedbackId}
                          evaluationFormateur={evaluationFormateur}
                          isTurn1Filled={isTurn1Filled}
                          isEditing={isEditing}
                          setIsEditing={setIsEditing}
                          feedbackFirstTurnSent={feedbackFirstTurnSent}
                          setFeedbackFirstTurnSent={setFeedbackFirstTurnSent}
                          feedbackTextFirstTurn={feedbackTextFirstTurn}
                          setFeedbackTextFirstTurn={setFeedbackTextFirstTurn}
                          courseId={courseId}
                          correctionFirstTurn={correctionFirstTurn}
                          setUpdateFeedbackState={setUpdateFeedbackState}
                        />
                      )}

                    {/* Pourcentage Global Tour 2 */}
                    {data.result[0] &&
                    data.turn === 2 &&
                    currentTab === "Tour 2" &&
                    dataSecondTurn.criterias ? (
                      <GlobalStatsSecondTurn
                        dataSecondTurn={dataSecondTurn}
                        criteriasNonComplianceSecondTurn={criteriasNonComplianceSecondTurn}
                      />
                    ) : (
                      ""
                    )}

                    {/* Bouton FeedBack Tour 2  */}
                    {data.result[0] &&
                      data.turn === 2 &&
                      currentTab === "Tour 2" &&
                      isTurn2Filled && (
                        <SendFeedbackSecondTurn
                          feedbackId={feedbackId}
                          evaluationFormateur={evaluationFormateur}
                          isTurn2Filled={isTurn2Filled}
                          isEditingSecondTurn={isEditingSecondTurn}
                          setIsEditingSecondTurn={setIsEditingSecondTurn}
                          feedbackSecondTurnSent={feedbackSecondTurnSent}
                          setFeedbackSecondTurnSent={setFeedbackSecondTurnSent}
                          setFeedbackTextSecondTurn={setFeedbackTextSecondTurn}
                          feedbackTextSecondTurn={feedbackTextSecondTurn}
                          courseId={courseId}
                          correctionSecondTurn={correctionSecondTurn}
                          setUpdateFeedbackState={setUpdateFeedbackState}
                        />
                      )}

                    {/* Objectifs d'améliorations */}
                    {data.turn === 1 &&
                      currentTab === `Objectifs d'améliorations` && (
                        <>
                          {/* Contenu des améliorations */}
                          <ImprovementsObjectives 
                            data={dataImprovements} 
                            admin_id={user.admin_id}
                            setData={setDataImprovements}
                          />

                          {/* Bouton pour envoyer le feedback des objectifs d'amélioartions */}
                          <SendFeedbackImprovements
                            feedbackId={feedbackId}
                            evaluationFormateur={evaluationFormateur}
                            isEditingAmelio={isEditingAmelio}
                            setIsEditingAmelio={setIsEditingAmelio}
                            feedbackImprovementSent={feedbackImprovementSent}
                            setFeedbackImprovementSent={setFeedbackImprovementSent}
                            setFeedbackTextAmelio={setFeedbackTextAmelio}
                            feedbackTextAmelio={feedbackTextAmelio}
                            courseId={courseId}
                            isTurn1Filled={isTurn1Filled}
                            correctionAmelio={correctionAmelio}
                            setUpdateFeedbackState={setUpdateFeedbackState}
                          />
                        </>
                      )}
                  </>
                ))
              : " "}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
