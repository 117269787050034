import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, InputLabel, TextField, Typography } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/styles';

// Objet de style servant à customiser les champs d'identifiants
const useTextFieldStyles = makeStyles({
    root: {
        '& label': {
          color: 'white', // Couleur lorsque le label est en focus
          paddingLeft: '1rem',
          fontFamily: '"Montserrat", sans-serif'
        },
        '& label.Mui-focused': {
          color: 'white', // Couleur lorsque le label est en focus
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: 'white', // Couleur de la bordure après avoir rempli
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'white', // Couleur de la bordure après avoir rempli
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottomColor: 'white', // Couleur du bord inférieur au survol
        },
        '& .MuiFormHelperText-root.Mui-error': {
          color: '#6b0c01', // Changer la couleur de l'attribut error en utilisant la couleur d'erreur du thème
          fontWeight: 'bold'
        },
        '& .MuiInputLabel-root.Mui-error': {
          color: 'white', // Changer la couleur du label en cas d'erreur en utilisant la couleur d'erreur du thème
        },
        '& input:-webkit-autofill': {
          'transition': 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
          'background-color': 'initial !important', // Conservez la couleur de fond initiale
          'color': 'initial !important' 
        },
    },
  });

// Formulaire de connexion avec la bibliothèque Formik (décommenter plus tard le bouton "Mot de passe oublié?")
export const JWTLogin = (props) => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth();
  const classes = useTextFieldStyles();
  const { handleForgottenPassword, setLoginError } = props

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('L\'adresse mail doit être valide')
        .max(255)
        .required('Adresse mail requise'),
      password: Yup
        .string()
        .max(255)
        .required('Mot de passe requis')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const res = await login(values.email, values.password);

        if (isMounted() && res == true) {
          setLoginError(null)
          navigate('/dashboard', { replace: true});
        } else {
          setLoginError("Email ou mot de passe incorrect(s)")
        }
      } catch (err) {
        console.error(err);
        if (err instanceof TypeError && err.message === 'Failed to fetch') setLoginError("Connexion impossible : problème au niveau du serveur")
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      {...props}>
      <InputLabel sx={{ mt: 5, px: 2, mb: -0.5, color: 'white', fontWeight: 'medium' }}>Email</InputLabel>
      <TextField
        variant="standard"
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        // margin="normal"
        name="email"
        // onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
        className={classes.root}
        InputProps={{
          style: {
            color: 'white', // Couleur de l'input
            fontSize: 16
          }
        }}
        sx={{ px: 2 }}
      />
      <InputLabel sx={{ mt: 3, px: 2, mb: -0.5, color: 'white', fontWeight: 'medium' }}>Mot de passe</InputLabel>
      <TextField
        variant="standard"
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        // margin="normal"
        name="password"
        // onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
        className={classes.root}
        InputProps={{
          style: {
            color: 'white', // Couleur de l'input
          },
        }}
        sx={{ px: 2 }}
      />
      <Box sx={{ mt: 5, p: 2 }}>
        {/* <Box sx={{ display: 'flex', justifyContent:'flex-end' }}>
          <Typography
            sx={{
              color: 'white',
              fontFamily: '"Montserrat", sans-serif',
              mb: 1,
              cursor: 'pointer'
            }}
            onClick={handleForgottenPassword}
          >
            Mot de passe oublié ?
          </Typography>
        </Box> */}
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          // variant="contained"
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            color: '#3B2755',
            background: '#FFFFFF',
            '&:hover': {
              color: 'white',
              backgroundColor: "#3B2755"
                // '#64b15b'
            },
            fontSize: 18,
          }}
        >
          CONNEXION
        </Button>
      </Box>
    </form>
  );
};
